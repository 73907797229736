import React from 'react';
import { connect } from 'react-redux';
import { useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogButton, DialogActions } from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';

import './AddMemberDialog.scss';
import { addDomainToProject } from '../../store/project-actions';
import { showNotification } from '../../store/ui-actions';

const AddDomainDialog = ({ showNotification, projectId, addDomainToProject, isOpen, onClose }) => {

  const [domain, setDomain] = useState('');

  const addDomain = () => {
    showNotification({ message: `Adding domain ${domain}` });
    addDomainToProject(projectId, domain)
      .then(() => {
        showNotification({ message: `${domain} added` });
      }, () => {
        showNotification({ message: `Failed to add ${domain}` });
      });
  }
  const onDialogClose = (e) => {
    if (e.detail.action === 'addDomain') {
      addDomain()
    }
    
    onClose(e);
  }

  const onKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      addDomain()
      onClose()
    }
  }

  return (
    <Dialog open={isOpen} onClose={onDialogClose}>
      <DialogTitle>Approve for domain</DialogTitle>
      <DialogContent className='add-member-dialog-content' >
        <div className='add-member-people-section'>
          <Typography use='subtitle'>Domain</Typography>
          <TextField
            className='add-member-dialog-email-field'
            helpText='Enter domain for example inova.si'
            type='text'
            value={domain}
            onKeyPress={onKeyPress}
            onChange={(e) => setDomain(e.currentTarget.value)} />
        </div>
      </DialogContent>

      <DialogActions>
        <DialogButton action='close'>Close</DialogButton>
        <DialogButton action='addDomain' >Add Domain</DialogButton>
      </DialogActions>

    </Dialog>
  );
}

const mapDispatchToProps = { showNotification, addDomainToProject }

export default connect(null, mapDispatchToProps)(AddDomainDialog);
