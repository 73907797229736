import { TYPES } from './project-actions';

import * as meta from './meta';
import { basename } from '../common/utils';

const DEF_STATE = {

}

const buildPath = (action) => `${action.payload.id}`

const contentReducer = (state = DEF_STATE, action) => {
  switch (action.type) {
    case TYPES.LOAD_PROJECT_BUILDS_REQUEST: {
      const [old, _m] = meta.extract(state, action.payload.id);
      return {
        ...state,
        [action.payload.id]: {
          ...old,
          ...meta.requestStart(_m)
        }
      }
    }
    case TYPES.LOAD_PROJECT_BUILDS_REQUEST_SUCCESS: {
      let ret = {
        ...state,
        [buildPath(action)]: {
          ...meta.requestSuccess(),
          ...action.payload.builds
        }
      }
      return ret;
    }
    case TYPES.LOAD_PROJECT_BUILDS_REQUEST_FAILURE:
      return {
        ...state,
        [buildPath(action)]: {
          _meta: {
            loading: false,
            error: action.error || true,
            ready: false
          },
          ...action.payload
        }
      }
    case TYPES.DELETE_PROJECT_BUILD_REQUEST_SUCCESS: {
      const [ oldBuilds ] = meta.extract(state, action.payload.id, 'builds');
      if (!oldBuilds) {
        return state;
      }
      const updated = oldBuilds.filter((b) => !(b.name === action.payload.name && b.version === action.payload.version));
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          builds: updated
        }
      }
    }
    case TYPES.SAVE_FILE_CONTENT_REQUEST_SUCCESS: {
      const { projectId } = action.payload;
      const [ old, _m ] = meta.extract(state, projectId);
      if (old) {
        return {
          ...state,
          [ projectId ]: {
            ...old,
            _meta: {
              ..._m,
              refresh: true
            }
          }
        }
      }
      return state;
    }
    case TYPES.CREATE_PROJECT_FILE_REQUEST: {
      if (!action.payload.version) {
        return state;
      }
      const [ builds ] = meta.extract(state, action.payload.id, 'builds');
      if (!builds) {
        return state;
      }
      const mapped = builds.map((b) => {
        if (b.name !== action.payload.name || b.version !== action.payload.version) {
          return b;
        }
        b.files = b.files.slice();
        b.files.push( {
          path: action.payload.path,
          basename: basename(action.payload.path),
          contentType: action.payload.contentType,
          newFile: true
        });
        return b;
      })
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          builds: mapped
        }
      }
    }
    case TYPES.DELETE_PROJECT_FILE_REQUEST: {
      if (!action.payload.version) {
        return state;
      }
      const [ builds ] = meta.extract(state, action.payload.id, 'builds');
      if (!builds) {
        return state;
      }
      const mapped = builds.map((b) => {
        if (b.name !== action.payload.name || b.version !== action.payload.version) {
          return b;
        }
        b.files = b.files.filter((f) => f.path !== action.payload.path);
        return b;
      });

      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          builds: mapped
        }
      }
    }
    case TYPES.PROJECT_REFRESH_REQUEST: {
      const [, _m] = meta.extract(state, action.payload.id);
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...meta.requestRefresh(_m)
        }
      }
    }
    case TYPES.PROJECT_BUILD_NAME_UPDATED: {
      const { projectId, oldBuildName, newBuildName } = action.payload
      
      return {
        ...state,
        [projectId]: {
          ...state[projectId],
          builds: state[projectId].builds.map(it => it.name === oldBuildName ? { ...it, name: newBuildName } : it)
        }
      }
    }
      default:
        return state;
  }
};


export default contentReducer;