import { swaggerTools, muver } from 'spotlight-tools'

export const pathToInstallPage = (projectId, build, file) => {
  return `/${ projectId }/install/${ build.name }/${ build.version }/${ file.basename }`;
}

export const pathToInstallPageLatest = (projectId, build, file) => {
  const ver = `^${ muver.major(build.version) }.0.0`;
  return `/${ projectId }/install/${ build.name }/${ encodeURIComponent(ver) }/${ file.basename }`;
}

export const basename = (filename) => {
  const sep = filename.lastIndexOf('/');
  return sep >= 0 ? filename.substring(sep + 1) : filename
}

export const dirname = (path) => {
  const sep = path.lastIndexOf('/');
  return sep > 0 ? path.substring(0, sep) : '/';
}

export const getProjectIdAndNameFromFilePath = (path) => {
  const m = path.match(/projects\/([^/]+)\/files\/(.*)/);
  if (!m) {
    throw new Error('no a valid path')
  }
  return {
    projectId: m[1],
    name: m[2]
  }
}


export const getProjectRelativePath = (path) => {
  return getProjectIdAndNameFromFilePath(path).name;
}

export const joinPath = (p1, p2, ...paths) => {
  if (p2 === undefined) {
    return p1;
  }
  const b = p1.endsWith('/') ? p1 : p1 + '/'
  const c = p2.startsWith('/') ? p2.substring(1) : p2;

  return joinPath(b + c, ...paths);
}

export const basenameWithoutExtension = (filename) => {
  const base = basename(filename);
  const dot = base.lastIndexOf('.');
  return dot >= 0 ? base.substring(0, dot) : base;
}

export const getMimeType = (filename, fileContent) => {
  const base = basename(filename);
  const dot = base.lastIndexOf('.');
  if (dot < 0) {
    return 'application/octet-stream';
  }
  const ext = base.substring(dot + 1).toLowerCase();
  switch (ext) {
    case 'md':
    case 'markdown':
      return 'text/markdown'
    case 'html':
      return 'text/html';
    case 'yaml':
      if (fileContent) {
        return swaggerTools.yaml.getContentType({ content: fileContent })
      } else {
        return 'application/octet-stream';
      }
    case 'json':
      if (fileContent) {
        return swaggerTools.json.getContentType({ content: fileContent })
      } else {
        return 'application/octet-stream';
      }
    default:
      return 'application/octet-stream';
  }
}

export const copySearchParams = (path, search, blacklist) => {
  if (!search) {
    return path;
  }
  const query = path.lastIndexOf('?')
  const newParams = new URLSearchParams(search);
  if (blacklist) {
    blacklist.forEach((b) => newParams.delete(b));
  }

  if (query >= 0) {
    const oldSearchPath = path.substring(query + 1);
    const params = new URLSearchParams(oldSearchPath);
    params.forEach((value, key) => {
      if (!blacklist || blacklist.indexOf(key) < 0) {
        newParams.append(key, value)
      }
    });
    return `${ path.substring(0, query) }?${ newParams }`;
  }

  return `${ path }?${ newParams }`;
}

export const pathJoin = (...args) => {
  return args.reduce((fullPath, part) => {
    const shouldPrefix = !fullPath.endsWith('/');
    if (shouldPrefix && part.startsWith('/')) {
      return fullPath + part;
    } else if (shouldPrefix) {
      return fullPath + '/' + part;
    } else if (part.startsWith('/')) {
      return fullPath + part.substring(1);
    } else {
      return fullPath + part;
    }
  }, '');

}

export const getLatestBuild = (builds, name, currentVersion) => {
  const sorted = builds.filter((build) => build.name === name).sort((a, b) => muver.rcompare(a.version, b.version));
  const last = sorted[0];
  if (currentVersion && last && last.version === currentVersion) {
    return null;
  }
  return last;
}

export const resolveBuild = (builds, name, versionName) => {
  return builds.find((b) => b.name === name && b.version === versionName);
}

export const isDeviceIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
}

export const isDeviceAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return /android/i.test(userAgent);
}

export const isDeviceMacOS = () => {
  return navigator.appVersion && navigator.appVersion.indexOf("Mac") >= 0;

}

export const isFileMarkdown = (name) => {
  const lcName = name.toLowerCase();
  return lcName.endsWith('.md') || lcName.endsWith('.markdown');
}

export const canInstallOnThisDevice = (filename) => {
  const target = getTargetDeviceForFile(filename);

  switch (target) {
    case 'android':
      return isDeviceAndroid();
    case 'ios':
      return isDeviceIOS();
    default:
      return false;
  }
}

export const getTargetDeviceForFile = (filename) => {
  const lc = filename.toLowerCase();
  if (lc.endsWith('.apk')) {
    return 'android'
  } else if (lc.endsWith('.ipa')) {
    return 'ios';
  } else {
    return 'unknown';
  }
}

export const getFriendlyFileSize = (fileSizeInBytes) => {
  if (!fileSizeInBytes) {
    return '';
  }

  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes >= 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export const filesizeToBytes = (fileSize) => {
  if (fileSize == null) return 0;

  const size = +fileSize.slice(0, -2);
  const unit = fileSize.slice(-2).toLowerCase();

  if (isNaN(size))
    throw new Error(`cannot convert '${ fileSize }' to bytes: failed to parse number'`);

  let power = 0;
  switch (unit) {
    case "kb":
      power = 1;
      break;
    case "mb":
      power = 2;
      break;
    case "gb":
      power = 3;
      break;
    default:
      throw new Error(`cannot convert '${ fileSize }' to bytes: unknown unit '${ unit }'`);
  }

  return size * Math.pow(1024, power);
}

export const toggleQueryParam = (history, paramName, value) => {
  const params = new URLSearchParams(window.location.search);
  params.delete(paramName);
  if (value) {
    params.set(paramName, value);
  }
  const query = params.toString();
  const link = query.length > 0
    ? `${ window.location.pathname }?${ query }`
    : window.location.pathname;

  history.replace(link);
}

export const CSVToArray = (strData, strDelimiter) => {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = (strDelimiter || ",");

  const objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
  );

  const arrData = [[]];
  let arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  // eslint-disable-next-line
  while (arrMatches = objPattern.exec(strData)) {
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      strMatchedDelimiter !== strDelimiter
    ) {

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);

    }
    let strMatchedValue;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(
        new RegExp("\"\"", "g"),
        "\""
      );
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  return (arrData);
};


export const parseCSV = (csv) => {
  let lines = CSVToArray(csv);
  // remove header
  const keys = lines.shift();

  // remove last line if empty
  if (lines[lines.length - 1]?.every(v => v == null || v === "")) {
    lines.pop();
  }

  lines = lines.map(l => {
    let ret = {};
    for (let [index, key] of keys.entries()) {
      ret[key] = l[index];
    }

    return ret;
  });

  if (!keys) return null;

  return lines;
}

export const rightWeightedBinarySearch = (key, elems, accessor) => {
  let bottom = 0;
  let top = elems.length - 1;
  let mid = null;
  let index = top;

  while (bottom <= top) {
    mid = Math.floor((top + bottom) / 2);
    if (accessor(elems, mid) === key) {
      index = mid;
      break;
    } else {
      if (accessor(elems, mid) < key) {
        bottom = mid + 1;
        if (bottom > index) index = mid;
      } else {
        top = mid - 1;
        index = mid;
      }
    }
  }

  return index;
};

export const isSameDate = (a, b) => {
  return new Date(a).toDateString() === new Date(b).toDateString();
};

export const formatMinutesToDaysHoursMinutesFormat = (min) => {
  let remainingMin = min;
  const hourInMinutes = 60;
  const workingHoursInDay = 8;
  const workingDayHoursInMinutes = workingHoursInDay * hourInMinutes;

  const days = Math.floor(remainingMin / workingDayHoursInMinutes);

  remainingMin -= days * workingDayHoursInMinutes;

  const hours = Math.floor(remainingMin / hourInMinutes) % workingHoursInDay;

  remainingMin -= hours * hourInMinutes;

  return `${days}d ${hours}h ${Math.floor(remainingMin)}min`;
}

export const formatMinutesToBusinessDays = (min) =>
  Math.floor(min / 60 / 8);

export const formatBusinessDaysToMinutes = (days) =>
  days * 8 * 60;

const getLastDayOfMonth = (year, month) =>
  new Date(year, month + 1, 0);

const getQuarterEndDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth();
  const quarter = Math.floor(month / 3) + 1;

  return getLastDayOfMonth(date.getFullYear(), quarter * 3 - 1);
}

export const generateQuartersDataBetweenDates = (dateFrom, dateTo) => {
  const quarterStartForDateFrom = getQuarterEndDate(dateFrom);
  const quarterEndForDateTo = getQuarterEndDate(dateTo);
  const quarterDates = [];

  while (quarterStartForDateFrom.getTime() <= quarterEndForDateTo.getTime()) {
    quarterDates.push(new Date(quarterStartForDateFrom.getTime()));
    quarterStartForDateFrom.setMonth(quarterStartForDateFrom.getMonth() + 3);
  }

  quarterDates.push(quarterEndForDateTo);

  return quarterDates;
}

export const getMovingAverage = (numbers = []) => {
  const result = [];
  let sum = 0;
  let count = 0;

  for(let i = 0; i < numbers.length; i++) {
     const num = numbers[i];
     sum += num;
     count++;
     const curr = sum / count;
     result[i] = curr;
  };

  return result;
};

export const getMedianValue = (numbers = []) => {
  const sortedArray = numbers.sort((a, b) => a - b);

  if (sortedArray.length % 2 === 0) {
    const middleIndex = sortedArray.length / 2;

    return (sortedArray[middleIndex - 1] + sortedArray[middleIndex]) / 2;
  }

  return sortedArray[(sortedArray.length + 1) / 2 - 1];
};
