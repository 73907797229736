import React, { useState } from "react";
import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableBody, DataTableCell } from '@rmwc/data-table';
import { IconButton } from '@rmwc/icon-button';
import { Card } from '@rmwc/card'
import { LinearProgress } from '@rmwc/linear-progress';
import { SimpleDialog } from '@rmwc/dialog';
import { Button } from '@rmwc/button';

import * as moment from 'moment';
import { describePermissions } from 'components/common/permissions';

const KeysTabTable = ({ keys, handleDeleteKey, handleCreateKey, copyKey, getCopyUrl, error, loading }) => {
    const [deleteKeyFlow, setDeleteKeyFlow] = useState({ prompt: false, key: {} });

    const initiateDeleteKey = (key) => setDeleteKeyFlow({ prompt: true, key });

    const idFormatter = ({ id: text }) => text.substring(0, text.length - 4).split('').map(() => "*").join('') + text.substring(text.length - 4);

    const items = keys && keys.map(key => <DataTableRow key={key.id}>
        <DataTableCell data-key={key.id}>{idFormatter(key)}</DataTableCell>
        <DataTableCell>{moment(key.createdAt).format('LL')}</DataTableCell>
        <DataTableCell>{moment(key.lastUsedAt).format('LL')}</DataTableCell>
        <DataTableCell>
            <ul className='permissions-list'>
                {describePermissions(key || {}).map((perm) => <li key={perm}>{perm}</li>)}
            </ul>
        </DataTableCell>
        <DataTableCell className='hide-cs' alignEnd>
            <IconButton data-share={getCopyUrl ? getCopyUrl(key) : 'nan'} data-id='key-content-copy' icon='content_copy' onClick={() => copyKey(key)} />
            <IconButton icon='clear' onClick={() => initiateDeleteKey(key)} />
        </DataTableCell>
    </DataTableRow>);

    return <Card className='settings-card' style={{ padding: '2rem', marginTop: '1rem' }}>
        <SimpleDialog
            title="Confirm delete"
            body={`Are you sure you want to delete key ${deleteKeyFlow.key.id ? idFormatter(deleteKeyFlow.key) : ''}?`}
            open={deleteKeyFlow.prompt}
            onClose={evt => {
                const { key } = deleteKeyFlow;
                setDeleteKeyFlow({ prompt: false, key: {} })

                if (evt.detail.action === 'accept') {
                    handleDeleteKey(key.id);
                }
            }}
        />

        {error && <div>{error}</div>}
        <div>
            <div>
                {!error && handleCreateKey && <Button data-id='create-new-key' disabled={loading} className='create-new-key-button' onClick={() => handleCreateKey()}> Create New Key</Button>}
            </div>
            <DataTable className='settings-keys-table'>
                <DataTableContent>
                    <DataTableHead>
                        <DataTableRow>
                            <DataTableHeadCell className='key-head-cell'>Key</DataTableHeadCell>
                            <DataTableHeadCell>Created on</DataTableHeadCell>
                            <DataTableHeadCell>Last used on</DataTableHeadCell>
                            <DataTableHeadCell>Permissions</DataTableHeadCell>
                            <DataTableHeadCell className='hide-cs' />
                        </DataTableRow>

                    </DataTableHead>

                    <DataTableBody>
                        {items}
                    </DataTableBody>

                </DataTableContent>
            </DataTable>
            {loading && <LinearProgress />}
        </div>
    </Card>
}

export default KeysTabTable