import { TYPES } from './project-actions';

import {requestStart, requestSuccess, requestFailure, extract} from './meta'

const DEF_STATE = {

}

const contentReducer = (state = DEF_STATE, action) => {
  switch (action.type) {
   
    case TYPES.LOAD_FILE_CONTENT_REQUEST: {
      const [, _m] = extract(state, action.payload.path);
      return {
        ...state,
        [action.payload.path]: {
          ...state[action.payload.path],
          ...requestStart(_m)
        }
      }
    }
    case TYPES.LOAD_FILE_CONTENT_REQUEST_SUCCESS:
      return {
        ...state,
        [action.payload.path]: {
          ...requestSuccess(),
          ...action.payload
        }
      }
    case TYPES.LOAD_FILE_CONTENT_REQUEST_FAILURE:
      return {
        ...state,
        [action.payload.path]: {
          ...requestFailure(action.error),
        }
      }
    case TYPES.SAVE_FILE_CONTENT_REQUEST:
      {
        // the content is most likely already loaded
        const [, _m] = extract(state, action.payload.path);
        return {
          ...state,
          [action.payload.path]: {
            ...state[action.payload.path],
            path: action.payload.path,
            ...requestStart({ ..._m, saving: true})
          }
        }
      }
    case TYPES.SAVE_FILE_CONTENT_REQUEST_SUCCESS:
      return {
        ...state,
        [action.payload.path]: {
          ...state[action.payload.path],
          ...action.payload,
          ...requestSuccess({saving: false})
        }
      }
    case TYPES.SAVE_FILE_CONTENT_REQUEST_FAILURE:
      {
        const [, _m] = extract(state, action.payload.path);
        return {
          ...state,
          [action.payload.path]: {
            ...state[action.payload.path],
            path: action.payload.path,
            ...requestFailure(action.error, { ..._m, saving: false } )
          }
        }
      }
    case TYPES.CREATE_PROJECT_FILE_REQUEST:
    {
      return {
        ...state,
        [action.payload.path]: {
          path: action.payload.path,
          content: action.payload.content,
          ...requestSuccess({edited: true, newFile: true})
        }
      }
    }
    case TYPES.DELETE_PROJECT_FILE_REQUEST: {
      const clone = { ...state };
      delete clone[action.payload.payload];
      return clone;
    }
    default:
      return state;
  }
};


export default contentReducer;