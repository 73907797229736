import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';

import LinkWithToken from '../common/LinkWithToken';

import './content.scss';


const NotFound = ({ match: { params } }) => {
  const homeLink = params.projectId ? `/${params.projectId}/` : '/';

  return <div className='page-not-found'>
    <ul>
      <li><Typography use='headline2'>Ops.</Typography></li>
      <li><Typography use='headline4'>We cannot find the page you are looking for.</Typography></li>
      <li><Typography use='headline6'>It might got deleted or got lost in the vineyard.</Typography></li>
    </ul>
    <Button tag={LinkWithToken} to={homeLink} raised>Return home</Button>
  </div>
}

export default withRouter(NotFound);