import React, { useState, useCallback, useEffect} from 'react';
import { Link } from "react-router-dom";
import * as moment from 'moment';

import { Icon } from "@rmwc/icon";
import { MenuSurfaceAnchor } from '@rmwc/menu';

import BreadCrumbs from "components/Project/Diagnostics/Breadcrumbs/Breadcrumbs";
import DeviceSessionMenu from "components/Project/Diagnostics/Breadcrumbs/DeviceSessionMenu";

const LogDetailsBreadCrumbs = (props) => {
  const { projectId, deviceId, session, isLatest } = props;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [sessionObj, setSessionObj] = useState({});

  useEffect(() => {
    setSessionObj(session);
  }, [session]);

  const handleLoadDeviceSessions = useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [setIsDialogOpen, isDialogOpen]);

  let formattedDate;
  {
    const datePart = moment(sessionObj.reportDate).format("MMMM D[,] HH:mm");
    formattedDate = isLatest ? `latest(${ datePart })` : datePart;
  };

  const deviceSessionsList = <MenuSurfaceAnchor>
    <button onClick={ handleLoadDeviceSessions }>
      { formattedDate }
      <Icon icon={ `arrow_drop_${ isDialogOpen ? "up" : "down" }` }/>
    </button>

    <DeviceSessionMenu
      projectId={ projectId } deviceId={ deviceId } sessionId={ sessionObj.sessionId }
      onDialogClose={ () => setIsDialogOpen(false) }
      isOpen={ isDialogOpen }
    />
  </MenuSurfaceAnchor>;

  const breadcrumbsParts = [
    <Link to={ `/${ projectId }/diagnostics/` }>Diagnostics</Link>,
    <Link to={ `/${ projectId }/diagnostics/${ deviceId }` }>{ sessionObj.deviceName }</Link>,
    deviceSessionsList
  ]

  return (
    <BreadCrumbs
      typographyProps={ { use: "subtitle1" } }
      parts={ breadcrumbsParts }/>
  );
};

export default LogDetailsBreadCrumbs;
