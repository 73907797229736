import * as React from "react";
import { Typography } from '@rmwc/typography';
import { useCallback } from "react";
import { Icon } from '@rmwc/icon';
import css from "./Breadcrumbs.module.scss";


const BreadCrumbs = (props) => {
  const { parts, typographyProps = {} } = props;

  const partFormat = useCallback((part, parts, index) => {
    let suffix = "";

    if (index !== parts.length - 1) {
      suffix = <> <Icon icon='chevron_right' className={ css.separator }/> </>;
    }

    return <React.Fragment key={ index }>{ part }{ suffix }</React.Fragment>;
  }, []);

  return (
    <Typography className={ css.typography } { ...typographyProps }>
      { parts.map((part, index) => partFormat(part, parts, index)) }
    </Typography>
  );
};


export default BreadCrumbs;
