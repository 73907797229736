import { TYPES } from './project-actions'

export default (state = [], action) => {
  switch (action.type) {
    case 'INIT_FROM_CACHE':
      return [];
    case TYPES.UPLOAD_FILE_REQUEST: {
      const { projectId, path, isBuild }  = action.payload;
      const newArr = state.filter((up) => !(up.projectId === projectId && up.path === path));
      newArr.push({ projectId, path, progress: 0, isBuild });
      return newArr;
    }
    case TYPES.UPLOAD_FILE_REQUEST_PROGRESS: {
      const { projectId, path, progress }  = action.payload;
      return state.map((up) => {
        if (up.projectId === projectId && up.path === path) {
          return {
            ...up,
            progress
          }
        }
        return up;
      } )
    }
    case TYPES.UPLOAD_FILE_REQUEST_SUCCESS: {
      const { projectId, path } = action.payload;
      return state.filter((up) => !(up.projectId === projectId && up.path === path));
    }
    case TYPES.UPLOAD_FILE_REQUEST_FAILURE: {
      const { projectId, path }  = action.payload;
      return state.map((up) => {
        if (up.projectId === projectId && up.path === path) {
          return {
            ...up,
            error: action.error
          }
        }
        return up;
      } )
    }

    default:
      return state;
  }
}