import { TYPES } from './project-actions';


const DEF_STATE = {
  _meta: {
    ready: false,
    loading: false,
    error: false
  },
}

const currentProjectReducer = (state = DEF_STATE, action) => {
  if (action.type === TYPES.SET_CURRENT_PROJECT) {
    const proj = action.payload;
    const id = (action.payload && action.payload.id) || null;
    if (proj && proj._meta) {
      return { ...proj }
    }
    return {
      ...DEF_STATE,
      id
    }
  }
  if (action.current) {
    switch (action.type) {
      case TYPES.LOAD_PROJECT_REQUEST:
        return {
          _meta: {
            ready: false,
            loading: true
          },
          ...action.payload
        }
      case TYPES.LOAD_PROJECT_SUCCESS:
        return {
          _meta: {
            ready: true,
            loading: false
          },
          ...action.payload
        }
      default:
        return state;
    }
  }
  return state;
}

export default currentProjectReducer;