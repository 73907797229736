import { useMediaQueries } from "@react-hook/media-query";

const sizeTablet = 600;
const sizeDesktop = 840;

/**
 * @returns {'mobile' | 'tablet'| 'desktop'}
 */
export const useScreenSizeBreakpoint = () => {
  const matches = useMediaQueries({
    mobile: `(max-width: ${ sizeTablet - 1 }px)`,
    tablet: `(max-width: ${ sizeDesktop - 1 }px) and (min-width: ${ sizeTablet }px)`,
    desktop: `(min-width: ${ sizeDesktop }px)`,
  }).matches;

  for (const [breakpointName, matched] of Object.entries(matches)) {
    if (matched) return breakpointName;
  }

  return 'mobile';
};
