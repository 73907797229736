import React, { useEffect } from "react";
import ProjectCard from '../Project/ProjectCard';
import { connect } from 'react-redux';
import { meta } from '../../store/meta';

import { getUserProjects } from '../../store/project-actions';

import { LinearProgress } from '@rmwc/linear-progress';

import Error from '../common/Error'
import Welcome from "../Welcome";
import NewProjectDialog from '../Project/NewProjectDialog';


import { Card, CardPrimaryAction } from "@rmwc/card";

import { Icon } from '@rmwc/icon';

import { Typography } from '@rmwc/typography';

import { useState } from "react";

const NewProjectCard = () => {
  const [open, setOpen] = useState(false);
  return (
    <Card data-id='add-project-button' className='project-card'>
      <NewProjectDialog isOpen={open} onClose={() => setOpen(false)}/>
      <CardPrimaryAction className='project-card__primary-action' onClick={() => (setOpen(true))}>
        <div className='project-card__primary new-project-card'>
          <Icon icon={{ icon: 'add', size: 'xlarge' }} />
          <Typography use='headline6' className='project-card__title'>
            Add project
            </Typography>
        </div>
      </CardPrimaryAction>
    </Card>);
}

const Home = ({ authReady, uid, sharedToken, projects, projectMeta, getUserProjects, state }) => {

  useEffect(() => {
    if (uid) {
      getUserProjects(uid);
    }
  }, [ uid, getUserProjects ]);

  if (authReady && !uid) {
    return <Welcome />
  }
  if (!projectMeta.ready && projectMeta.loading) {
    return <LinearProgress />
  }
  if (projectMeta.error) {
    return <Error error={projectMeta.error} />
  }

  if (sharedToken) {
    return <Welcome />
  }

  return <div>
    <div className="projects-list">
      {projects.map((project) => (
        <ProjectCard key={project.id} projectId={project.id} projectName={project.name} projectDescription={project.description} />
      ))
      }
      {authReady && projectMeta.ready && <NewProjectCard />}

      <div className="project-list-row-fill">

      </div>
    </div>
  </div>
}

const mapStateToProps = (state) => {
  const projects = Object.keys(state.projects).map((key) => state.projects[key])
    .filter(it => (!it._meta || it._meta.errorCode !== 404) && (it.name || it.id))
    .sort((a, b) => ((a.name || a.id).localeCompare(b.name || b.id, undefined, { sensitivity: 'base' })))

  const projectMeta = meta(state.myProjects);

  const authReady = meta(state.auth).ready;
  const uid = state.auth.uid;
  const sharedToken = state.auth.sharedToken;

  return { authReady, uid, sharedToken, projects, projectMeta, state };
}

const mapDispatchToProps = { getUserProjects };

export default connect(mapStateToProps, mapDispatchToProps)(Home);