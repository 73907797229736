import React, { useEffect } from "react";
import { connect } from "react-redux"
import { Route, withRouter, Redirect } from 'react-router-dom';
import { getProjectDetails, setCurrentProject } from "../../store/project-actions";
import Welcome from '../Welcome';

import Error from '../common/Error';
import SmartRedirect from '../common/SmartRedirect';

import {LinearProgress} from '@rmwc/linear-progress';

import ScrollToTop from '../common/ScrollToTop'

import { meta } from "../../store/meta";
import { setLimitedAccessToken, setSharedAccessToken, canAccessProjectWithToken } from '../../api/auth';
import { useQueryStringParams } from "common/hooks/useQueryStringParams";


const ProjectRoute = ({user, project, authReady, welcome, projectId, setCurrentProject, getProjectDetails, component: Component, ...rest }) => {
  const { shared: sharedToken, lat: laToken } = useQueryStringParams()
  if (laToken) {
    setLimitedAccessToken(projectId, laToken);
  }

  if (sharedToken) {
    setSharedAccessToken(projectId, sharedToken);
  }

  const accessWithToken = canAccessProjectWithToken(projectId);

  useEffect(() => { 
    getProjectDetails(projectId) 
  }, [ projectId, getProjectDetails ]);

  useEffect(() => {
    setCurrentProject(project);
    return () => setCurrentProject(null);
  }, [ project, setCurrentProject ]);

  if (!project && !sharedToken && !laToken) {
    return <Redirect to='/' />
  }
  
  return <Route {...rest} render={(props) => {
    if (!authReady || !projectId) {
      return <div id='fullscreen-project-loader-container'>
        <LinearProgress className='fullscreen-project-loader'/>
      </div>
    }


    if (!user.authenticated) {
      if (welcome) {
        return <Welcome/>
      } else if (!accessWithToken) {
        return <SmartRedirect to={`/login?url=${window.location.pathname + window.location.search}`}/>
      }
    }

    const info = meta(project);
    //Redirect to home on project load errors
    if (info.error && !info.loading) {
      return <Error error={info.error}/>
    }

    // Show loader until we load the project successfully
    if (!info.ready) {
      return <div id='fullscreen-project-loader-container'>
        <LinearProgress className='fullscreen-project-loader' />
      </div>
    }

    return <div>
      <ScrollToTop/>
      <Component projectId={projectId} {...props}/>
    </div>
  }}/>
};

const mapStateToProps = (state, ownProps) => {
  const {_meta, ...user } = state.auth;
  const authReady = _meta.ready;

  const projectId = ownProps.match.params.projectId || ownProps.computedMatch.params.projectId;
  const project = state.projects[projectId];

  return {
    authReady,
    projectId,
    project,
    user
  };
}

const mapDispatchToProps = { getProjectDetails, setCurrentProject };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectRoute))