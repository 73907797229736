import React from 'react';
import {connect, useDispatch} from 'react-redux';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import * as firebaseui from 'firebaseui';
import {Typography} from '@rmwc/typography';
import SmartRedirect from '../common/SmartRedirect';
import { clearState } from 'store/auth-actions'
import { firebase, auth } from 'components/common/Firebase';

import './login.scss';

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  }
};


const Login = ( { authenticated, sharedToken }) => {
  const config = { ...uiConfig };
  const search = window.location.search;
  const dispatch = useDispatch()
  if (search.indexOf('url') >= 0) {
    const params = new URLSearchParams(search.substring(1));
    config.signInSuccessUrl = params.get('url') || '/';
  }

  if (authenticated && !sharedToken) {
    return (<SmartRedirect to={config.signInSuccessUrl}/>);
  } else {
    dispatch(clearState());
  }

  return (
    <div className='login-panel main-panel '>
      <Typography use='headline4' className='.login-welcome-title'>Welcome.</Typography>
      <StyledFirebaseAuth className='firebase-login-container' uiConfig={config} firebaseAuth={auth()} sign/>
      <Typography use='headline6'>No account? Just sign in with email to create one.</Typography>
      <Typography use='caption'>To make Spotlight work, we log user data for internal processing only. Click “Sign In” above to accept the Terms of Service & Privacy Policy.</Typography>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.auth
  }
}

export default connect(mapStateToProps) (Login);