import React, { useState, useEffect } from "react";

import LinkWithToken from '../common/LinkWithToken'


import QRCode from 'qrcode.react';
import { connect } from 'react-redux';

import { Typography } from '@rmwc/typography';
import { LinearProgress } from '@rmwc/linear-progress';

import Error from '../common/Error';
import { getErrorMessage } from '../../common/errors';

import * as meta from '../../store/meta';

import { loadProjectBuilds } from '../../store/project-actions';
import { showNotification } from '../../store/ui-actions';

import { getBuildInstallLink, createInstallLimitedAccessToken } from '../../api/projects-api';

import { getLatestBuild, isDeviceMacOS, copySearchParams, resolveBuild, getTargetDeviceForFile, canInstallOnThisDevice } from '../../common/utils';

import './install.scss'
import { useQueryStringParams } from "common/hooks/useQueryStringParams";

// Location is set through setter so it can be stubbed in automation tests.
window.setLocation = (loc) => {
  window.location = loc
}

const AppleScanQrCode = ({ link }) => {
  return <>
    <Typography use='headline6' tag='p'>Scan QR code on your Apple device</Typography>
    <div className='qr-code-container'>
      <QRCode className='qr-code-view' value={link} />
      <ol className='qr-code-help'>
        <li>Open the Camera app by either swiping to the left from the lock screen or tapping on the icon from your home screen.</li>
        <li>Hold your device steady for 2-3 seconds towards the QR Code.</li>
        <li>Click on the notification to open this page. </li>
      </ol>
    </div>
  </>
}

const AirDropShare = ({ link }) => {
  return <>
    <Typography use='headline6' tag='p'>Share this page with Airdrop</Typography>
    <Typography use='body1' tag='p'>
      Just open the 'File' menu in your browser, choose 'Share', then 'AirDrop'.
    </Typography>
  </>
}

const CopyLink = ({ link, showNotification}) => {
  const copyLink = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(link)
      .then(() => showNotification({ message: 'Link copied to the clipboard' }))
      .catch((err) => showNotification({ message: getErrorMessage(err) }));
  }

  return <>
    <Typography use='headline6' tag='p'>Copy link</Typography>
    <Typography use='body1' tag='p'>
      {navigator.clipboard && <>
        Click <a href={link} onClick={copyLink}>here</a> to copy the link to your clipboard.
        You can then paste it into an email or a message and send it to your mobile device to continue the install.
        </>}
      {!navigator.clipboard && <>
        Copy <i>{link}</i> to your clipboard.
        You can then paste it into an email or a message and send it to your mobile device to continue the install.
      </>}
    </Typography>
  </>

}
const AndroidTv = ({ }) => {
  return <>
    <Typography use='headline6' tag='p'>Android TV</Typography>
    <Typography use='body1' tag='p'>
        To install this to Android/Google TV device:

        <ol>
          <li>Install <a href="https://play.google.com/store/apps/details?id=com.yablio.sendfilestotv">Send files to TV</a> app on both the TV and your Android phone.</li>
          <li>Download apk files to your device as usual (see above).</li>
          <li>Open Send files to TV app on your TV</li>
          <li>Share the APK files to the Send files to TV app on your phone</li>
          <li>Select target TV device and initiate transfer</li>
          <li>After transfer is complete, select the loaded file on TV using the remote</li>
          <li>Press Open to install</li>
        </ol>
    </Typography>
  </>
}

const NavigatorShare = ( { link, appName } ) => {
  const canShare = !!navigator.share;
  const shareLink = (e) => {
    e.preventDefault();
    const content = {
      title: `Install ${appName}`,
      text: appName,
      url: link
    }
    navigator.share(content)
      .catch((err) => showNotification({ message: getErrorMessage(err) }))
  }

  if (!canShare) {
    return false;
  }

  return <>
    <Typography use='headline6' tag='p'>Share with your browser</Typography>
    <Typography use='body1'>
      Click <a href='#share' onClick={shareLink}>here </a> to share the link through your browser.
    </Typography>
  </>
}

const AndroidScanQrCode = ({ link }) => {
  return <>
  <Typography use='headline6' tag='p'>Scan QR code on your Android device</Typography>
  <div className='qr-code-container'>
    <QRCode className='qr-code-view' value={link} />
    <ul className='qr-code-help'>
      <li className='qr-code-help-strong'>
          Open the camera and focus it to QR-code. By holding Home button the link in this QR-code should become comes visible.
      </li>
      <li className='qr-code-help-strong'>
        Built-in QR reader on Android (Android 9, Pie)...
        <a href='https://medium.com/turunen/built-in-qr-reader-on-android-696e0f38113b'>read more</a>
      </li>
      <li className='qr-code-help-strong'>
        Scan QR codes by Google Lens (Android 8, Oreo)...
        <a href='https://medium.com/turunen/scan-qr-codes-by-google-lens-907d6f020c7'>read more.</a>
      </li>
    </ul>
  </div>
</>
}

const IosInstallScreen = ({ projectId, countdown, installing, version, name }) => {
  const appName = name ? `${name} ${version}` : version;
  const source = window.location.hostname;
  return <div>
    {countdown > 1 && !installing && <p>The install will start in <strong> {countdown} seconds</strong>. Follow the steps to complete it. </p>}
    {installing && <p>The install has been started. Follow the steps to complete it. </p>}

    <ul className='ios-install-setup'>

      <li className='ios-install-setup-step'> <strong>Step 1 </strong> </li>
      <li>Select Open when prompted to open this page in "iTunes"</li>

      <li className='ios-install-setup-step'> <strong>Step 2 </strong> </li>
      <li>Select <i>Install</i> when prompted to install from {source}</li>

      <li className='ios-install-setup-step'> <strong>Step 3 </strong> </li>
      <li><i>{appName} </i> will be automatically downloaded and installed in the background</li>
      <li className='ios-install-setup-step'> <strong>Step 4 </strong> </li>
      <li>Look for <i> {appName} </i> on your home screen </li>
    </ul>
    <Typography use='headline6'>Troubleshooting</Typography>

    <Typography use='subtitle2' tag='p'>Nothing seems to happen</Typography>
    <p>
      If nothing seems to happen check the home screen. The installation is probably in progress and you
      should see the app icon with a progress indicator on the home screen.
      </p>
    <Typography use='subtitle2' tag='p'>I didn't get a popup</Typography>
    <p>If you didn't get the pop-up "{source} would like to install…", it's likely you have the same app installed from the app store.
      Delete the app installed from the app store and retry the installation.
      </p>

    <Typography use='subtitle2' tag='p'>Unable to download app</Typography>
    <p> If you get a <i>unable to download</i> popup and your internet connection is working fine, please contact the <LinkWithToken to={`/projects/${projectId}/team`}>team</LinkWithToken>.
        It could be that the app is not signed for your device or your device might not be supported.
      </p>

    <Typography use='subtitle2' tag='p'>Untrusted Enterprise Developer</Typography>
    <p>
      Go to Settings &gt; General &gt; Device Management &gt; tap on the developer's profile, and tap on Trust.
       </p>
    <Typography use='subtitle2' tag='p'>Otherwise</Typography>
    <p>
      Reach out to the team <LinkWithToken to={`/projects/${projectId}/team`}>team</LinkWithToken>. They will be happy to help out.
    </p>
  </div>
}



const AndroidInstallScreen = ({ projectId, countdown, installing, version, name }) => {
  const appName = name ? `${name} ${version}` : version;
  return <div>
    {countdown > 1 && !installing && <p>The install will start in <strong> {countdown} seconds</strong>. Follow the steps to complete it. </p>}
    {installing && <p>The install has been started. Follow the steps to complete it. </p>}

    <ul className='ios-install-setup'>

      <li className='ios-install-setup-step'> <strong>Step 1 </strong> </li>
      <li>Wait for the download to complete.</li>

      <li className='ios-install-setup-step'> <strong>Step 2 </strong> </li>
      <li>Open the downloaded file. Look for it in the status bar or in the <i>Downloads</i> folder.  </li>

      <li className='ios-install-setup-step'> <strong>Step 3 </strong> </li>
      <li>Tap <strong>Install</strong> {appName} when prompted.</li>
      <li className='ios-install-setup-step'> <strong>Step 4 </strong> </li>
      <li>Tap <strong>Open</strong> to open the app.</li>
    </ul>
    <Typography use='headline5'>Troubleshooting</Typography>

    <Typography use='headline6' tag='p'>Allow unknown sources</Typography>
    <Typography use='body1' tag='p'>
    By default on Android devices, installation is not allowed from "unknown source", in other words from sources/sites other than the Play Store.

    To install this app you must allow this in your phone settings. See <a href='https://www.wikihow.tech/Install-APK-Files-on-Android'>this wikiHow article</a> for more help.
    </Typography>

    <Typography use='headline6' tag='p'>Otherwise</Typography>
    <Typography use='body1' tag='p'>
      Reach out to the team <LinkWithToken to={`/projects/${projectId}/team`}>team</LinkWithToken>. They will be happy to help out.
    </Typography>
  </div>
}

const IncompatibleDevice = ({ file, link, appName, showNotification }) => {
  const targetDevice = getTargetDeviceForFile(file);
  const isApk = targetDevice === 'android';
  const isIPA = targetDevice === 'ios';

  return <div>
    <CopyLink link={link} showNotification={showNotification}/>
    {isApk && <AndroidScanQrCode link={link}/> }
    {isIPA && <AppleScanQrCode link={link} /> }
    <NavigatorShare appName={appName} link={link}/>
    {isIPA && isDeviceMacOS() && <AirDropShare/> }
  </div>
}


const InstallIMobileApp = ({ newerVersion, project, version, showNotification, loadProjectBuilds, getProjectDetails, match: { params: { projectId, name, file } } }) => {

  const [ redirectLink, setRedirectLink ] = useState(false);
  const [ error, setError ] = useState();
  const [ countdown, setCountdown ] = useState(5);
  const [ autoInstall, setAutoInstall ] = useState(true);
  const [ installing, setInstalling ] = useState(false);
  const [ shareLink, setShareLink ] = useState();
  const appName = name ? `${name} ${version}` : version;

  const target = getTargetDeviceForFile(file);
  const isIPA = target === 'ios';
  const { lat: laToken, shared: sharedToken } = useQueryStringParams()

  const canInstall = canInstallOnThisDevice(file);

  useEffect(() => {
    loadProjectBuilds(projectId);

    getBuildInstallLink(projectId, name, version, file)
      .then((url) => {
        setRedirectLink(url);
      })
      .catch((err) => setError(err));

  }, [projectId, loadProjectBuilds, name, version, file])

  useEffect(() => {
    if (laToken || sharedToken) {
      setShareLink(window.location.href);
      return;
    }
    createInstallLimitedAccessToken(projectId, name, version)
      .then(({ token }) => {
        const link = copySearchParams(window.location.href, `lat=${encodeURIComponent(token)}`, [ 'shared' ]);
        setShareLink(link);
    })
  }, [ projectId, name, version, laToken, sharedToken ])

  useEffect(() => {
    if (countdown <= 1) {
      if (autoInstall) {
        setAutoInstall(false);
        if (canInstall && isIPA) {
          const url = new URL(redirectLink, window.location.href);
          const itmsLink = `itms-services://?action=download-manifest&url=${url.protocol}//${url.host}${encodeURI(url.pathname)}${encodeURIComponent(url.search)}`
          setInstalling(true);
          window.setLocation(itmsLink);
        } else if (canInstall) {
          setInstalling(true);
          window.setLocation(redirectLink);
        }
      }
      return;
    }
    const timerId = setTimeout(() => setCountdown(countdown - 1), 1000);

    return () => clearTimeout(timerId);
  }, [countdown, autoInstall, redirectLink, canInstall, isIPA])

  return <div className='project-container'>
    <Error error={error} />
    <div>
      <Typography use='headline5' className='install-app-title'>{appName}</Typography>
      <br />
      {newerVersion && <Typography tag={LinkWithToken} to={`/${projectId}/builds`} use='overline'>There is a never version ({newerVersion.version}) available.</Typography>}
    </div>
    {!canInstall &&
      <div className='not-on-mobile-headline'>
        <Typography use='headline5'>Open this page on your {target === 'ios' ? 'Apple device' : 'Android device'} to install. </Typography>
      </div>}
    {canInstall && target === 'ios' && <IosInstallScreen projectId={projectId} countdown={countdown} newerVersion={newerVersion} installing={installing} version={version} name={name} link={redirectLink} />}
    {canInstall && target === 'android' && <AndroidInstallScreen projectId={projectId} countdown={countdown} newerVersion={newerVersion} installing={installing} version={version} name={name} link={redirectLink} />}
    {canInstall && <> <Typography use='headline5'>Installing on another device</Typography> </>}
    {!shareLink && <LinearProgress progress={0}/>}
    {shareLink && <IncompatibleDevice showNotification={showNotification} link={shareLink} file={file} /> }
    {canInstall && target === 'android' && <AndroidTv/>}
  </div>
}

const mapStateToProps = (state, { projectId, match: { params: { name, version, file } } = { params: {} } }) => {
  const [projectBuilds, md] = meta.extract(state.builds, projectId);
  if (!md.ready) {
    return { version, buildInfo: { loading: true, name } }
  }

  const build = resolveBuild(projectBuilds.builds, name, version);
  if (!build) {
    return {
      // FIXME Handle wrong builds
      buildInfo: { error: true }
    }
  }

  const newerVersion = getLatestBuild(projectBuilds.builds, name, build.version);

  const [ project ] = meta.extract(state.projects, projectId);

  return {
    buildInfo: {
      ...build,
      loading: false
    },
    newerVersion,
    version: build.version || version,
    project
  }

}

export default connect(mapStateToProps, { loadProjectBuilds, showNotification })(InstallIMobileApp);
