import { auth, firestore } from './firebase';
import { currentUserChanged } from '../../../store/auth-actions';
import { setSessionCookie } from '../../../api/projects-api';

import store from "../../../store/store";


class Firebase {
  constructor() {
    this.auth = auth();
    this.db = firestore;

    this.auth.onAuthStateChanged((e) => {
      if (e !== null) {
        currentUserChanged(e)(store.dispatch, store.getState);
        setSessionCookie(this.auth).catch(err => console.error(err));
      } else {
        currentUserChanged(null)(store.dispatch, store.getState);
      }

    })
  }

  sendSignInLinkToEmail(email) {
    this.auth.sendSignInLinkToEmail(email, {
      url: "https://inova-pwa.firebaseapp.com/",
      handleCodeInApp: true
    });
  }

  isSignInWithEmailLink(url) {
    this.auth().isSignInWithEmailLink(url);
  }

  signInWithEmailLink(email, url) {
    this.auth.signInWithEmailLink(email, url);
  }

  signOut() {
    this.auth.signOut();
  }
}

export default Firebase;
