
import React, { useState, useRef } from 'react'

import '@rmwc/dialog/styles'
import { useDispatch } from 'react-redux'
import { Button } from '@rmwc/button'
import { Dialog, DialogTitle, DialogContent, DialogButton, DialogActions } from '@rmwc/dialog'
import { Typography } from '@rmwc/typography'
import { TextField } from '@rmwc/textfield'
import { IconButton } from '@rmwc/icon-button';
import { uploadBuild } from 'store/project-actions';
import { extract } from 'utils/inspect-ipa';
import { muver } from 'spotlight-tools';
import './UploadBuildDialog.scss'

import '@rmwc/button/styles';
import * as path from 'path'

const UploadBuildDialog = ({ isOpen, onClose, projectId }) => {
    const [buildName, setBuildName] = useState('')
    const [buildNameValid, setBuildNameValid] = useState(false)
    const [version, setVersion] = useState('')
    const [versionValid, setVersionValid] = useState(false)
    const [filesToUpload, setFilesToUpload] = useState([])
    const inputFileUpload = useRef(null)
    const dispatcher = useDispatch()

    const handleFileUpload = async () => {
        if (filesToUpload.length > 0) {
            const getFileContent = (file) => new Promise((resolve) => {
                const reader = new FileReader()
                reader.onloadend = (e) => {
                    resolve(reader.result)
                }
                reader.onerror = () => {
                    // ignore error
                    resolve(null)
                }
                reader.readAsText(file)
            })

            let fileList = []
            for (const file of filesToUpload) {
                const ext = path.extname(file.name)
                if (ext === '.json' || ext === '.yaml') {
                    file.fileContent = await getFileContent(file)
                }
                if (ext === '.ipa') {
                    fileList = fileList.concat((await extract(file)).fileList)
                } else {
                    fileList.push(file)
                }

            }
            dispatcher(uploadBuild(projectId, buildName, version, fileList))
        }
    }

    const onFileSelectionChanged = () => {
        const fileList = inputFileUpload.current.files;
        if (fileList.length > 0) {
            const newMappedFiles = []
            for (let i = 0; i < fileList.length; i++) {
                const item = fileList.item(i)
                newMappedFiles.push(item)
            }
            const filtered = newMappedFiles.filter(it => !filesToUpload.find(oldFile => oldFile.name === it.name))
            setFilesToUpload([...filesToUpload, ...filtered])
        }
    }

    const handleRemoveSelectedFile = (file) => {
        setFilesToUpload([...filesToUpload.filter(it => it !== file)])
    }

    const handleBuildNameChange = (e) => {
        setBuildName(e.currentTarget.value)
        setBuildNameValid(e.currentTarget.value.length > 0)
    }

    const handleVersionChange = (e) => {
        setVersion(e.currentTarget.value)
        setVersionValid(muver.valid(e.currentTarget.value))
    }

    const uploadDisabled = filesToUpload.length === 0 || !buildNameValid || !versionValid

    return <Dialog open={isOpen} onClose={onClose}>

        <DialogTitle>Upload Build</DialogTitle>

        <DialogContent className='add-member-dialog-content' >

            <TextField data-id='build-name-edit' fullwidth label='Build name' helpText='Enter build name' value={buildName}
                invalid={!buildNameValid}
                onClick={(e) => e.target.select()}
                onChange={(e) => handleBuildNameChange(e)} />
            <TextField data-id='version-edit' fullwidth label='Version' helpText='Enter version' value={version}
                invalid={!versionValid}
                onClick={(e) => e.target.select()}
                onChange={(e) => handleVersionChange(e)} />
            <input
                ref={inputFileUpload}
                multiple
                id='build-upload-input'
                type='file'
                name='file'
                onChange={onFileSelectionChanged}
            />
            <label htmlFor='build-upload-input' >
                <Button id='upload_file_dialog_button' className='upload-file-button' icon='file_upload' raised tag='div'>Upload file</Button>
            </label>

            {filesToUpload.length > 0 &&
                (<div className='files-to-upload'> {
                    filesToUpload.map((it, index) => (<div key={index} className='files-to-upload-item'>
                        <Typography key={index} use='subtitle'>{it.name}</Typography>
                        <IconButton className='files-to-upload-item-button' onClick={() => handleRemoveSelectedFile(it)} icon='delete' />
                    </div>))} </div>)}

        </DialogContent>

        <DialogActions>
            <DialogButton action='close'>Close</DialogButton>
            <DialogButton data-id='upload-build-action-button' action='upoadBuildAction' onClick={handleFileUpload} disabled={uploadDisabled}>Upload</DialogButton>
        </DialogActions>

    </Dialog>
}

export default UploadBuildDialog
