import React, { useCallback } from 'react';
import { useHistory } from "react-router-dom";


import { Typography } from '@rmwc/typography';
import { IconButton } from '@rmwc/icon-button';

import {
  prepareDeviceInfoGraphData,
  sessionMetaTableMapping } from 'components/Project/Diagnostics/Statistics/DeviceInfoGraph/Helpers';
import DeviceInfoGraph from "components/Project/Diagnostics/Statistics/DeviceInfoGraph/DeviceInfoGraph";

import css from './DeviceInfoTable.module.scss';

export default function DeviceInfoTable(props) {
  const {
    projectId,
    deviceId,
    deviceInfo,
    deviceInfoGraphData,
    onClose } = props;

  const history = useHistory();

  const handleGraphClick = useCallback(() => {
    history.push(`/${ projectId }/diagnostics/${ deviceId }/statistics`);
  }, [projectId, deviceId, history]);

  const deviceInfoValues = sessionMetaTableMapping.reduce((acc, value) => {
    if (deviceInfo && deviceInfo[value.prop]) {
      acc.push({
        value: deviceInfo[value.prop],
        label: value.label,
        transform: value.transform
      });
    }
    return acc;
  }, []);

  const deviceInfoContent = deviceInfoValues.map((info, index) => renderDeviceInfoRow(info, index));
  const mappedDeviceInfoGraphData = prepareDeviceInfoGraphData(deviceInfoGraphData);
  const deviceInfoGraphContent = mappedDeviceInfoGraphData.map((value, index) => {
    return renderDeviceInfoGraphRow(value, index, handleGraphClick);
  });

  return (
    <div className={ css.deviceInfoTableWrapper }>
      <div className={ css.header }>
        <Typography className={ css.title } use='headline6'>
          { deviceInfo && deviceInfo['sessionId'] }
        </Typography>
        <IconButton icon='close' onClick={onClose} />
      </div>
      <dl className={ css.deviceInfoItem }>
        { deviceInfoContent }
      </dl>
      { deviceInfoGraphContent }
    </div>
  );
};

function renderDeviceInfoRow(info, index) {
  return (
    <React.Fragment key={index}>
      <dt className={ css.deviceInfoItemTitle }>{ info.label }</dt>
      <dd className={ css.deviceInfoItemValue }>{ info.value } </dd>
    </React.Fragment>
  );
}

function renderDeviceInfoGraphRow(info, index, handleGraphClick) {
  if (!info.values?.length) {
    return null;
  }

  const value = info.values[info.values.length - 1].y;

  return (
    <div className={ css.deviceInfoItem } key={index}>
      <div className={ css.deviceInfoItemTitle }>{ info.label }</div>
      <div className={ css.deviceInfoItemValue }>{ info.transform ? info.transform(value) : value } </div>
      <DeviceInfoGraph
        data={ [info] }
        className={ css.chart }
        onClick={ handleGraphClick }
        graphProps={ {
          hideYAxis: true,
          transform: info.transform,
        } } />
    </div>
  );
}
