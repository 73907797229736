const {TOGGLE_SIDE_MENU, CLOSE_SIDE_MENU,
  SHOW_NOTIFICATION,CLEAR_NOTIFICATION, FOOTER_VISIBILITY} = require('./ui-actions');

export const uiReducer = (state = { sideMenuOpen: false }, action) => {
  switch (action.type) {
    case TOGGLE_SIDE_MENU:
      return {
        ...state,
        sideMenuOpen: !state.sideMenuOpen
      }
    case CLOSE_SIDE_MENU:
      return {
        ...state,
        sideMenuOpen: false
      }
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      }
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: null
      }

    default:
      return state;
  }
}

export const tmpStorageUiReducer = (state = { footerVisibility: true }, action) => {
  switch (action.type) {
    case FOOTER_VISIBILITY:
      return {
        ...state,
        footerVisibility: action.payload
      };

    default:
      return state;
  }
};