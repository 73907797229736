import React, { useState } from "react";
import {Route, withRouter, useHistory } from "react-router-dom";
import LinkWithToken from '../common/LinkWithToken';
import { connect } from 'react-redux';

import {Typography} from '@rmwc/typography';
import {IconButton} from '@rmwc/icon-button';

import './layout.scss'

import { getProjectDetails } from '../../store/project-actions';
import { logout } from '../../store/auth-actions'

import { MenuSurfaceAnchor, MenuSurface, MenuItem } from '@rmwc/menu';
import { ListDivider } from '@rmwc/list';

import ProjectNavBar from '../Project/ProjectNavBar';
import AddMemberDialog from '../Settings/AddMemberDialog';

import  {TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle, TopAppBarFixedAdjust } from '@rmwc/top-app-bar';
import { extract } from "../../store/meta";
import WithPermission from "../common/WithPermission";

const AppBarMenu = ({ user, authReady, projectId, logout, authenticated }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();

  const navigateTo = (path) => {
    setMenuOpen(false);
    history.push(path);
  }

  if (!authenticated) {
    return null;
  }

  return<MenuSurfaceAnchor>
    <MenuSurface className='main-menu'
      anchorCorner='bottomStart'
      open={menuOpen}
      onSelect={evt => console.log(evt)}
      onClose={() => setMenuOpen(false)}>

      {user && <Typography className='mdc-list-item' use='subtitle2'> {`Signed in as ${user.displayName}`}</Typography>}

      <ListDivider/>

      {authenticated && projectId && <MenuItem data-id='user-menu-settings' onClick={() => navigateTo(`/${projectId}/settings/`)}>Project settings</MenuItem>}
      <WithPermission permission='editFiles'>
         <MenuItem data-id='user-menu-files' onClick={() => navigateTo(`/${projectId}/browse/`)}>Files</MenuItem>
      </WithPermission>
      {authenticated && projectId && <MenuItem onClick={() => navigateTo(`/${projectId}/help`)}>Help</MenuItem>}
      {authenticated && <ListDivider/>}
      <MenuItem data-id='user-menu-sign-out' onClick={logout}>Sign out</MenuItem>

    </MenuSurface>

    <IconButton className='mdc-theme--primary account-button' icon='account_circle' label='account' data-id='user-menu'
      onClick={evt => setMenuOpen(!menuOpen)}/>


  </MenuSurfaceAnchor>
}

const AppBar = ( { user, history, authReady, ...props } ) => {
  const {authenticated, project, logout } = props;
  const shareToken = project && project.shareTokens && project.shareTokens.find(it=> it.paths.includes('/'));
  const projectName = project ? project.name || project.id : null;
  const projectId = project ? project.id : null;
  let canShare = project && project.permissions && project.permissions.share;
  const [shareOpen, setShareOpen] = useState(false);

  if (!authReady) {
    return null;
  }

  return <div className='top-header'>
    <TopAppBar fixed dense>
    <TopAppBarRow>
      <TopAppBarSection alignStart style={{ flex: 'unset', minWidth: 'unset', paddingLeft: 0 }}>
          <TopAppBarTitle className='top-header-title'>
          <>
            {!authenticated
              ?  <Typography data-id='app-bar-home' className='mdc-theme--primary inuit-project-title' use='headline5' tag={LinkWithToken} to={`/${projectId}/`}>Spotlight</Typography>
              :  <Typography data-id='app-bar-home' className='mdc-theme--primary inuit-project-title' use='headline5' tag={LinkWithToken} to='/'>Spotlight</Typography>
            }
            { projectName && <Typography className='mdc-theme--primary separator inuit-project-title' use='headline5'>@</Typography> }
          </>
          {projectName &&
            <>
              <Typography data-id='app-bar-project-name-text' className='mdc-theme--primary inuit-project-title' use='headline5' tag={LinkWithToken} to={`/${projectId}/`}>{projectName}</Typography>
            </>
          }
          </TopAppBarTitle>
        </TopAppBarSection>
        <TopAppBarSection style={{ display: 'flex', height: "100%", paddingRight: 8 }} alignEnd>
          <div style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%", justifyContent: "end" }}>
            <div style={{ flex: 1, height: "100%", overflow: "auto" }}>
              <div className='header-tabs-desktop'>
                <Route path='/:projectId/'>
                  <ProjectNavBar/>
                </Route>
              </div>
            </div>
            <div style={{ height: "100%", display: "flex" }}>
              {canShare &&
                <IconButton className='mdc-theme--primary account-button' icon='share' label='share' onClick={evt => setShareOpen(!shareOpen)}/>}
              <AppBarMenu user={user} projectId={projectId} canShare={canShare} authenticated={authenticated} logout={logout}/>
            </div>
          </div>
        </TopAppBarSection >
    </TopAppBarRow>

    <TopAppBarRow className='header-tabs-mobile'>
      <Route path='/:projectId/'>
        <TopAppBarSection className='header-tabs-mobile-section' alignStart style={{ paddingLeft: 8 }}>
          <ProjectNavBar/>
        </TopAppBarSection>
      </Route>
    </TopAppBarRow>



  </TopAppBar>
  <TopAppBarFixedAdjust />
   <AddMemberDialog
        continueUrl={window.location.href}
        projectId={projectId}
        isOpen={shareOpen}
        shareToken={shareToken}
        onClose={() => setShareOpen(false)}></AddMemberDialog>
  </div>
}

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  const { authenticated } = state.auth;;
  const [ project ] = extract(state.projects, projectId );
  const { _meta, ...user} = state.auth;
  const authReady = _meta.ready;

  return {
    project,
    user,
    authenticated,
    authReady
  }
}

export default connect(mapStateToProps, { logout, getProjectDetails })(withRouter(AppBar));
