import {TYPES} from './project-actions';
import * as meta from './meta';

const DEF_STATE = {
  _meta: {
    ready: false,
    loading: false,
    error: false
  },
  list: []
}


const reducer = (state = DEF_STATE, action) => {
  switch (action.type) {
    case TYPES.LOAD_USER_PROJECTS_REQUEST:
      return {
        ...state,
        ...meta.requestStart(state._meta)
      }
    case TYPES.LOAD_USER_PROJECTS_FAILURE:
      return {
        ...state,
        ...meta.requestFailure(action.error, state._meta)
      }
    case TYPES.LOAD_USER_PROJECTS_SUCCESS: {
      const list = action.payload.map((p) => p.id);
      return {
        ...state,
        list,
        ...meta.requestSuccess()
      };
    }
    
    default:
      return state;
  }
};

export default reducer;
