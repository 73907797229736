
import {requestStart, requestFailure, requestSuccess} from 'store/meta';
import { TYPES } from 'store/auth-types';


const initialState = {
  _meta: {
    loading: true,
    ready: false,
    error: null
  },
  authenticated: false,
  displayName: null,
  uid: null
}

const extendedError = (error) => {
  switch (error.responseStatus) {
    case 404:
      return {
        ...error,
        errorMessage: 'This share link is invalid',
        errorHelp: 'Please check that you clicked the right link in your email, and that you copied the text correctly into your browser.\nIt is also possible the link has been revoked. Please reach out to the team to for a new link.' }
    default:
      return error;
  }
}

const pickUserProps = (user) => {
  const USER_PROPS = ['displayName', 'providerId', 'uid', 'email', 'emailVerified', 'authenticated'];
  if (!user) {
    return {
      authenticated: false,
    }
  }

  const ret = USER_PROPS.reduce((acc, key) => {
    acc[key] = user[key];
    return acc;
  }, {});
  ret.sharedToken = !user.email;
  return ret;
}


const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_FROM_CACHE':
      return {
        ...state,
        _meta: {
          ...state._meta,
          ready: false
        },
        authenticated: false
      }
    case 'AUTH_USER_CHANGED':
      return {
        _meta: {
          loading: false,
          ready: true
        },
        ...pickUserProps(action.payload)
      }
    case TYPES.SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST:
      return {
        ...requestStart({ ready: true }),
        authenticated: false,
      }
    case TYPES.SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST_FAILURE:
      return {
        ...requestFailure(extendedError(action.error), { ready: true })
      }
    case TYPES.CREATE_EMAIL_ACCOUNT_REQUEST_SUCCESS:
      return {
        ...state,
        ...pickUserProps(action.payload),
        authenticated: state.authenticated
      }
    case TYPES.SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST_SUCCESS:
      return {
        ...requestSuccess(),
        ...pickUserProps(action.payload.user),
        authenticated: true
      }
    default:
      return state;
  }
}

export default authReducer;