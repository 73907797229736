import * as React from "react";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LinearProgress } from "@rmwc/linear-progress";

import { selectSessionLogs, selectSessionLogsMeta,
  selectSession, selectSessionDeviceInfo } from "store/diagnostics-selectors";
import { showFooter } from 'store/ui-actions';
import { loadListOfDeviceSessions, loadSessionLog, loadDeviceInfo } from "store/diagnostics-actions";
import LogDetailsDataTable from "components/Project/Diagnostics/Session/DataTable/SessionLogDataTable";
import LogDetailsBreadCrumbs from "components/Project/Diagnostics/Breadcrumbs/SessionBreadcrumbs";

import css from './SessionDetails.module.scss';

const SessionDetails = (props) => {
  let { projectId, deviceId, sessionId } = props;

  const dispatch = useDispatch();

  const deviceInfo = useSelector(state => selectSessionDeviceInfo(state, projectId, deviceId, sessionId));
  const session = useSelector(state => selectSession(state, projectId, deviceId, sessionId));

  const log = useSelector((state) => selectSessionLogs(state, projectId, deviceId, sessionId) || []);
  const logMeta = useSelector((state) => selectSessionLogsMeta(state, projectId, deviceId, sessionId) || {});

  useLayoutEffect(() => {
    dispatch(showFooter(false));
    return () => dispatch(showFooter(true));
  }, [dispatch]);

  // Get list of all sessions for device
  useEffect(() => {
    dispatch(loadListOfDeviceSessions(projectId, deviceId));
  }, [projectId, deviceId, dispatch]);

  // Get latest session for device
  useEffect(() => {
    if (sessionId) {
      dispatch(loadDeviceInfo(projectId, deviceId, sessionId));
      dispatch(loadSessionLog(projectId, deviceId, sessionId));
    }
  }, [projectId, deviceId, sessionId, dispatch]);

  const isLoadingLog = !logMeta || logMeta.loading;

  if (isLoadingLog) {
    return <LinearProgress size="xlarge"/>;
  }

  // Check for errors
  if (logMeta.error) {
    return (
      <div> { logMeta.error.errorMessage } </div>
    );
  }

  return (
    <div className={ css.container }>
      <div className={ css.breadcrumbs }>
        <LogDetailsBreadCrumbs
          projectId={projectId}
          deviceId={deviceId}
          isLatest={false}
          session={session}/>
      </div>
      <div className={ css.contentContainer }>
      <LogDetailsDataTable
        projectId={projectId}
        deviceId={deviceId}
        data={[{
          data: log,
          deviceInfo: session,
          deviceInfoGraphData: deviceInfo}]}/>
      </div>
    </div>
    
  );
};

export default SessionDetails;
