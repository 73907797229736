import projectReducer  from "./project-reducer";
import authReducer from './auth-reducer';
import { combineReducers } from "redux";
import { uiReducer, tmpStorageUiReducer } from "./ui-reducer";
import contentReducer from './content-reducer';
import { USER_LOGOUT, CLEAR_STATE } from 'store/auth-types';
import buildsReducer from './builds-reducer';
import currentProjectReducer from './current-project-reducer';
import filesReducer from './files-reducer';
import uploadsReducer from './uploads-reducer';
import userProjectsReducer from './user-projects-reducer';
import diagnosticsReducer from 'store/diagnostics-reducer';

export const STORE_VERSION = 20;

const aboutReducer = (state = { storeVersion: STORE_VERSION }) => state;

const appReducer = combineReducers({
  myProjects: userProjectsReducer,
  projects: projectReducer,
  auth: authReducer,
  ui: uiReducer,
  _ui: tmpStorageUiReducer,
  content: contentReducer,
  builds: buildsReducer,
  diagnostics: diagnosticsReducer,
  currentProject: currentProjectReducer,
  files: filesReducer,
  uploads: uploadsReducer,
  about: aboutReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT || action.type === CLEAR_STATE) {
    state = undefined;
  }
  return appReducer(state, action);
}

export default rootReducer;
