

export function getErrorMessage(err) {
  if (err.code === 'unavailable') {
    return 'Out of internets :('
  } else if (err.code === 'permission-denied' || err.responseStatus === 403) {
    return 'Ops. You do not have the permission for this action.';
  }
  return (err.getLocalizedError && err.getLocalizedError())
      || err.message
      || 'Ops. Something didn\'t go as planned.';
}

export function projectDoesNotExist() {
  const err = new Error('Er... this project got lost in space.');
  return err;
}