import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import LatestLiveDeviceSession from "components/Project/Diagnostics/Session/LatestLiveDeviceSession";
import SessionDetails from "components/Project/Diagnostics/Session/SessionDetails";
import SessionList from "components/Project/Diagnostics/SessionList/SessionList";
import DeviceStatisticsGraph from "components/Project/Diagnostics/Statistics/Statistics";


const Diagnostics = (props) => {
  const { projectId } = props;

  return (<div>
    <Switch>
      <Route
        path="/:projectId/diagnostics/:deviceId/statistics" exact
        render={
          ({ match: { params: { deviceId } } }) => (
            <DeviceStatisticsGraph projectId={ projectId } deviceId={ deviceId }/>
          )
        }/>

      <Route
        path="/:projectId/diagnostics/:deviceId/:sessionId"
        render={
          ({ match: { params: { deviceId, sessionId } } }) => (
            <SessionDetails projectId={ projectId } deviceId={ deviceId } sessionId={ sessionId }/>
          )
        }/>
            
      <Route
        path="/:projectId/diagnostics/:deviceId"
        render={ 
          ({ match: { params: { deviceId } } }) => (
            <LatestLiveDeviceSession projectId={ projectId } deviceId={ deviceId } />
          )
        }/>
        
      <Route path="/:projectId/diagnostics" exact>
        <SessionList/>
      </Route>

      <Route>
        <Redirect to={ `/${ projectId }/diagnostics` }/>
      </Route>
    </Switch>
  </div>);
}

Diagnostics.propTypes = {
  projectId: PropTypes.string
};

export default Diagnostics;