import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { deleteProject, saveProjectBuildNumber } from '../../store/project-actions';
import { showNotification } from '../../store/ui-actions';


import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { SimpleDialog } from '@rmwc/dialog';
import { Redirect } from 'react-router-dom';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield'
import { Card } from '@rmwc/card'


const GeneralSettings = ({ project, projectId, projectName, permissions, saveProjectBuildNumber, ...props }) => {
  const maxBuilds = project.maxBuilds || 10
  const [deletePrompt, setDeletePrompt] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [buildToKeep, setBuildToKeep] = useState(maxBuilds);
  const [savingBuildsToKeep, setSavingBuildsToKeep] = useState(false);
  const [buildValueValid, setBuildValueValid] = useState(true);

  useEffect(() => {
    setSaveEnabled(buildToKeep !== maxBuilds)
  }, [buildToKeep, maxBuilds])

  const onDeleteProject = () => {
    showNotification({ message: `Deleting ${projectName}` });
    setDeleting(true);
    props.deleteProject(projectId)
      .then((result) => {
        setDeleting(false);
        if (result.ok) {
          setDeleted(true);
          props.showNotification({ message: `Deleted ${projectName}` });
        } else {
          props.showNotification({ error: result.error, message: `Could not delete ${projectName}.` });
        }
      })
  }

  const onSaveProject = () => {
    props.showNotification({ message: `Updating ${projectName}` })
    setSavingBuildsToKeep(true)
    saveProjectBuildNumber(projectId, buildToKeep)
      .then(() => { setSavingBuildsToKeep(false); props.showNotification({ message: `${projectName} updated` }) })
      .catch(() => { setSavingBuildsToKeep(false); props.showNotification({ message: `Could not update ${projectName}.` })})
  }

  if (deleted) {
    return <Redirect to='/'/>
  }

  const icon = deleting ? <CircularProgress theme='onPrimary'/> : null;
  const savingLoaderIcon = savingBuildsToKeep ? <CircularProgress theme='onPrimary'/> : null;

  return <Card className='settings-card' style={{padding:'0.5rem 1.5rem 1.5rem 1.5rem', marginTop:'1rem'}}>
    <div style={{ marginTop: '1rem' }}>
      <Typography use='headline5'>Storage </Typography>
    </div>
    <div>
      <Typography use='body1'>
        Used {Math.round((project.storageUsed || 0) / 1000000)}MB from {Math.round((project.storageTotal || 1000000000) / 1000000)}MB available
      </Typography>
    </div>
    <div className='settings-headline'>
      <Typography use='headline5'> Build # to keep </Typography>
      <div>
        <TextField
          data-id='general-settings-build-nbr'
          label='Build #' className='add-member-dialog-email-field'
          type='number'
          value={buildToKeep}
          min='1'
          step='1'
          style={{width: '50%'}}
          onClick={(e) => e.target.select()}
          onChange={(e) => { setBuildValueValid(e.currentTarget.value >= 1); setBuildToKeep(e.currentTarget.value && parseInt(e.currentTarget.value)) }} />
      </div>

      <Typography use='body1'>
        Limiting numbers of builds grouped by major and name..??
      </Typography>
    </div>
    <div style={{ marginTop: '1rem' }}>
      <Typography use='headline5'> Delete project </Typography>
    </div>
    <div style={{ marginBottom: '1rem' }}>
      <Typography use='body1'>
        Deleting the project will delete all releases and all related resources including documents, keys etc. This action cannot be undone.
      </Typography>
    </div>
    <div>
      <Button data-id='delete-button' disabled={!permissions || !permissions.delete} onClick={() => setDeletePrompt(true)} raised label='Delete project' icon={icon} />
      <Button data-id='save-button' disabled={!permissions || !permissions.admin || !saveEnabled || !buildValueValid} onClick={() => onSaveProject()} icon={savingLoaderIcon} raised label='Save' style={{marginLeft: '1rem'}} />
    </div>
    <SimpleDialog
      title={`Delete ${projectName}`}
      body={`Are you sure you want to delete ${projectName}. This action cannot be undone.`}
      open={deletePrompt}
      onClose={evt => {
        if (evt.detail.action === 'accept') {
          onDeleteProject();
        }
        setDeletePrompt(false);
      }}
    />
  </Card>

}

const mapDispatchToProps = { deleteProject, showNotification, saveProjectBuildNumber }
const stateToProps = (state, ownProps) => {
  return { project: state.projects && state.projects[ownProps.projectId] }
}

export default connect(stateToProps, mapDispatchToProps)(GeneralSettings);