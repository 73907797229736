import * as React from "react";
import cn from "classnames";

import css from "./DeviceInfoGraph.module.scss";
import * as d3 from "d3";
import useComponentSize from '@rehooks/component-size' ;
import { useEffect, useRef } from "react";
import moment from "moment";
import { debounce } from "lodash";

const defaultLineStrokeColor = "steelblue";
const defaultVerticalLineStrokeColor = "#DEDFE3";

const DeviceInfoGraph = (props) => {
  const { data, className, graphProps, ...rest } = props;

  const ref = useRef(null);
  const containerRef = useRef(null);
  const stateRef = useRef({});
  const updateRef = useRef(null);

  const { width: w, height: h } = useComponentSize(containerRef);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const { update, destroy } = renderChart({ svgEl: ref.current, state: stateRef });
    updateRef.current = debounce(update, 200);
    return destroy;
  }, [ref]);

  useEffect(() => {
    if (!ref.current || !updateRef.current || !w || !h) {
      return;
    }
    updateRef.current({ svgEl: ref.current, data, state: stateRef, w, h, ...graphProps });
  }, [ref, updateRef, data, w, h, graphProps]);


  return (
    <div className={ cn(css.container, className) } ref={ containerRef } { ...rest }>
      <svg
        ref={ ref }
        preserveAspectRatio="none"
        className={ cn(css.inner) }
      />
    </div>
  );
};


const updateState = (map, data, keyFn, updateFn, removeFn = null) => {
  const updatedKeys = [];
  for (const d of data) {
    const key = keyFn(d);
    updatedKeys.push(key);

    map[key] = updateFn(d);
  }

  // remove all lines that are no longer in data
  const removedKeys = Object.keys(map).filter(k => !updatedKeys.includes(k));
  for (const key of removedKeys) {
    const d = map[key];
    removeFn && removeFn(d);
    delete map[key];
  }

  return map;
}

const renderChart = (config) => {
  let svg;
  let x;
  let y = {}; // separate y scale for each line (indexed by label)
  let axisLeftGenerator;
  let axisBottomGenerator;

  const bottomAxisHeight = 10;
  let marginTop;
  const leftAxisWidth = 72;

  const computeChartVars = ({ svgEl, w = 0, h = 0, data = [], hideYAxis, verticalLines }) => {
    svg = d3.select(svgEl);

    marginTop = verticalLines?.length ? 25 : 10;

    const xExtent = [
      +d3.min(data, d => +moment(d.values[0]?.x)) || 0,
      +d3.max(data, d => +moment(d.values[d.values.length - 1]?.x)) || 0,
    ];

    const graphOffsetLeft = hideYAxis ? 0 : leftAxisWidth;
    x = d3.scaleTime()
      .domain(xExtent)
      .range([graphOffsetLeft, w - graphOffsetLeft]);

    const yExtents = data.reduce((acc, line) => {
      const minY = d3.min(line.values, v => +v.y);
      const maxY = d3.max(line.values, v => +v.y);

      const low = typeof line.valueRange?.[0] === "function" ?
        line.valueRange[0]([minY, maxY]) :
        line.valueRange?.[0];

      const high = typeof line.valueRange?.[1] === "function" ?
        line.valueRange[1]([minY, maxY]) :
        line.valueRange?.[1];

      acc[line.label] = [
        low != null ? Math.min(minY, low) : minY,
        high != null ? Math.max(maxY, high) : maxY,
      ];

      return acc;
    }, {});

    y = updateState(y, data, d => d.label,
      line => d3.scaleLinear()
        .domain(yExtents[line.label])
        .range([
          h - bottomAxisHeight - marginTop - 3,
          marginTop
        ]),
    );


    // only draw left axis if there is at least one line
    const firstLabel = data[0]?.label;
    axisLeftGenerator = data.length && !hideYAxis ?
      d3.axisLeft(y[firstLabel])
        .tickValues(yExtents[firstLabel])
        .tickFormat(d => data[0]?.transform ? data[0]?.transform(d) : d) :
      null;

    axisBottomGenerator = d3.axisBottom(x)
      .tickValues(xExtent)
      .tickFormat(d => moment(d).format('HH:mm'));
  }

  computeChartVars(config);


  const verticalLineContainer = svg.append("g")
  const updateVerticalLines = ({ w, h, verticalLines, data }) => {
    if (!verticalLines || !data?.length) {
      verticalLineContainer.html("");
      return;
    }

    verticalLineContainer
      .selectAll("line")
      .data(verticalLines)
      .enter()
      .append("line")
      .attr("fill", "none")
      .attr("stroke", defaultVerticalLineStrokeColor)
      .attr("stroke-width", 3)
      .attr("vector-effect", "non-scaling-stroke")
      .attr("x1", d => x(d) + 1.5)
      .attr("y1", marginTop)
      .attr("x2", d => x(d) + 1.5)
      .attr("y2", h - marginTop - bottomAxisHeight);

    const formatDate = (ts) => {
      const values = data[0].values;
      const first = moment(values[0].x);
      const last = moment(values[values.length - 1].x);

      const current = moment(ts);

      if (last.diff(first, "days") > 0) {
        return current.format("MMMM D[,] HH:mm")
      }

      return current.format("HH:mm");
    }

    verticalLineContainer
      .selectAll("text")
      .data(verticalLines)
      .enter()
      .append("text")
      // .attr("fill", defaultVerticalLineStrokeColor)
      .attr("fill", "black")
      .attr("x", d => x(d) + 1.5)
      .attr("y", 15)
      .text(ts => formatDate(+ts));
  };


  const axisBottom = svg.append("g");
  const axisLeft = svg.append("g");
  const updateAxes = ({ h }) => {
    axisBottom
      .attr("transform", `translate(0,${ h - marginTop - bottomAxisHeight })`)
      .call(axisBottomGenerator)
      .selectAll("text")
      .style("text-anchor", (d, i) => i % 2 ? "end" : "start");


    if (axisLeftGenerator) {
      axisLeft
        .attr("transform", `translate(${ leftAxisWidth },${ 0 })`)
        .call(axisLeftGenerator);
    } else {
      axisLeft.html("");
    }
  }

  const lineContainer = svg.append("g")

  const updateLine = ({ data }) => {
    const lineGenerator = data => d3.line()
      .x(d => x(+moment(d.x)))
      .y(d => y[data.label](d.y))
      (data.values);

    lineContainer
      .selectAll("path")
      .data(data, d => d.values)
      .join(
        enter => enter
          .append('path')
          .attr("fill", "none")
          .attr("stroke", d => d.color || defaultLineStrokeColor)
          .attr("stroke-width", 3)
          .attr("vector-effect", "non-scaling-stroke")
          .attr("d", lineGenerator),
        update => update
          .attr("d", lineGenerator),
      );
  };

  const update = (config) => {
    computeChartVars(config);
    updateVerticalLines(config);
    updateLine(config);
    updateAxes(config);
  }

  return {
    update,
    destroy() {
      svg.html("");
    }
  };
}


export default DeviceInfoGraph;
