
import React, { useState } from 'react'

import '@rmwc/dialog/styles'
import { Dialog, DialogTitle, DialogContent, DialogButton, DialogActions } from '@rmwc/dialog'
import { TextField } from '@rmwc/textfield'

const EditTextDialog = ({ isOpen, onClose, defaultDialogValue, onDialogValueChanged,  title, label}) => {
    const [dialogValue, setDialogValue] = useState(defaultDialogValue)

    const handleDialogClose = (e) => {
        if (e.detail.action === 'changeAction' && dialogValue) {
            onDialogValueChanged(dialogValue)
        }

        onClose(e)
    }

    const handleKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            onDialogValueChanged(dialogValue)
            onClose()
        }
    }

return <Dialog open={isOpen} onClose={handleDialogClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className='add-member-dialog-content' >
            <div className='add-member-people-section'>
                <TextField
                    label={label} className='add-member-dialog-email-field'
                    value={dialogValue}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => setDialogValue(e.currentTarget.value)} />
            </div>
        </DialogContent>

        <DialogActions>
            <DialogButton action='close'>Close</DialogButton>
            <DialogButton action='changeAction'>Okay</DialogButton>
        </DialogActions>
    </Dialog>
}

export default EditTextDialog
