import React from 'react';
import { connect } from 'react-redux';

import { SimpleDialog } from '@rmwc/dialog';

import { createProject } from '../../store/project-actions';

import { TextField } from '@rmwc/textfield';

import { showNotification } from '../../store/ui-actions';
import './NewProjectDialog.scss';
import { useState } from 'react';


const NewProjectDialog = (props) => {

  const [projectName, setProjectName] = useState('');

  const onDialogClose = ({ detail: { action } } ) => {
    if (action === 'accept') {
      const name = projectName;
      props.showNotification({ message: `Creating project ${name}` });

      props.createProject(niceProjectId(name), name)
        .then((result) => {
          if (result.ok) {
            props.showNotification({ message: `Created ${name}` });
          } else {
            if (result.error && result.error.responseStatus === 409) {
              props.showNotification({ message: 'Project with same id already exists:',error: result.error });
            } else {
              props.showNotification({ message: 'Project creation failed: ',error: result.error });
            }
          }
        })
    }
    props.onClose(action);
  }

  const niceProjectId = (text) => {
    let lower = text.toLowerCase().trim();
    let safe = '';
    for (let i = 0; i < lower.length; i++) {
      const ch = lower[i];
      if ((ch >= 'a' && ch <= 'z') || (ch >= '0' && ch <= '9') || ch === '-' || ch === '_') {
        safe += ch;
      } else {
        safe += ' ';
      }
    }
    return safe.split(/\s/).map(a => a.trim()).filter((a) => a.length > 0).join('-');
  }

  const projectId = niceProjectId(projectName);
  return (
    <SimpleDialog
      title='Enter a name for the project'
      acceptLabel='Create'
      cancelLabel='Cancel'
      open={props.isOpen}
      onOpen={() => setProjectName('')}
      onClose={(e) => onDialogClose(e)}>

      <div className='new-project-dialog-dialog-content' >
        <TextField
          data-id='project-name-text-field'
          label='Project Name'
          className='new-project-dialog-project-field123'
          helpText={`Project Id: ${projectId}`}
          value={projectName}
          fullwidth
          onChange={(e) => setProjectName(e.currentTarget.value)} />

      </div>
    </SimpleDialog>
  );
};


const mapDispatchToProps = { showNotification, createProject }

export default connect(null, mapDispatchToProps)(NewProjectDialog);
