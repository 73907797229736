import config from "../../../config/firebaseConfig"

import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

firebase.initializeApp(config);

const firestore = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth;

if (window.location.hostname === 'localhost') {
    firestore.useEmulator('localhost', 5002);
    // storage.useEmulator('localhost', 9199);
    auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
}

export default firebase;
export {
    firestore,
    storage,
    auth
};
