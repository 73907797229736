import React from 'react';
import { connect } from 'react-redux';
import * as meta from '../../store/meta';

import { loadProjectFiles } from '../../store/project-actions';
import { useEffect } from 'react';
import MarkdownContent from './MarkdownContent';
import SwaggerContent from './SwaggerContent';
import GraphContent from './GraphContent';
import NotFound from './NotFound';
import WithPermission from '../common/WithPermission';
import { joinPath, toggleQueryParam } from '../../common/utils';
import { Redirect, useHistory } from "react-router-dom";

const AnyContent = ({ projectId, path, ready, loadProjectFiles, actions, file, props, refresh }) => {
  useEffect(() => {
    loadProjectFiles(projectId);
  }, [projectId, loadProjectFiles, refresh]);

  const history = useHistory();

  const onEditModeChanged = (status) => {
    toggleQueryParam(history, 'edit', status);
  }

  if (!ready) {
    return null;
  }
  if (!file) {
    return <NotFound/>
  }

  if (file.contentType === 'text/markdown') {
    return <WithPermission permission='editFiles' pass={ { editable: true, ...actions } }>
      <MarkdownContent projectId={projectId} path={ path } toc { ...props } refresh={ refresh } editMode={ actions.edit }
                       onEditModeChange={ onEditModeChanged }/>
    </WithPermission>
  }

  if (['application/vnd.ms-excel', 'text/csv'].includes(file.contentType)) {
    if (file.dirname === '/ws/.pantarhei') {
      return <Redirect to={`/${projectId}/pantarhei?project=${encodeURIComponent(file.basename)}`} />;
    }

    return <GraphContent projectId={projectId} path={ path } toc { ...props } refresh={ refresh } />;
  }

  if (file.contentType && file.contentType.startsWith('application/vnd.oai.openapi')) {
    return <SwaggerContent projectId={projectId} path={ path } toc { ...props } refresh={ refresh }/>
  } else {
    return <iframe className='embedded-content' title={ file.basename } src={ `/f/${ path }` }/>
  }
}

const mapStateToProps = (state, ownProps) => {
  const { projectId, actions, path, ...props } = ownProps;

  const fullPath = ownProps.filePath || joinPath(`projects/${ projectId }/files/`, ownProps.path);

  const [files, _m] = meta.extract(state.files, projectId, 'files');
  const file = (files || []).find(f => f.path === fullPath);
  return {
    path: fullPath,
    projectId,
    actions: ownProps.actions,
    file,
    props,
    ready: _m.ready,
    refresh: _m.refresh
  }
}

export default connect(mapStateToProps, { loadProjectFiles })(AnyContent);