import * as React from "react";
import css from "./MermaidDiagram.module.scss";
import { useEffect, useState } from "react";
import useComponentSize from "@rehooks/component-size";
import mermaid from 'mermaid';


export const MermaidDiagram = React.memo(({ definition, id }) => {
  const mermaidId = id;
  const mermaidContainerRef = React.useRef();
  const containerRef = React.useRef();
  const innerContainerRef = React.useRef();

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const theme = "default";

    mermaid.init({
      startOnLoad: false,
      logLevel: 'fatal',
      theme,
    });
  }, []);

  useEffect(() => {
    if (mermaidContainerRef.current) {
      try {
        mermaid.mermaidAPI.render(mermaidId, definition, (result) => {
          mermaidContainerRef.current.innerHTML = result
        });

        setErrorMessage(null);
        // return () => mermaidContainerRef.current.innerHTML = "";
      } catch (e) {
        if (e.message.startsWith("Parse error")) {
          setErrorMessage("Failed to parse mermaid graph.");
        }
        // console.log("mermaid error: ", e.message)
      }
    }
  }, [definition, mermaidContainerRef, mermaidId]);

  let { height: h } = useComponentSize(innerContainerRef);

  return (
    <div ref={ containerRef }
         style={ { height: h, width: '100%' } }
         className={ css.mermaidContainer }
    >
      <div ref={ innerContainerRef }>
        { errorMessage && <div style={ { whiteSpace: "nowrap" } }>{ errorMessage }</div> }
        <div ref={ mermaidContainerRef }/>
      </div>
    </div>
  )
});


export default MermaidDiagram;
