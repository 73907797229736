import * as React from "react";
import css from './LogDataTable.module.scss';
import cn from "classnames";

export const DiagnosticTable = (props) => {
    const { children, className, onClick } = props;

    return (
        <div onClick={onClick} className={ cn(css.table, className) }>
            { children }
        </div>
    );
};

export const DiagnosticTableHeader = (props) => {
    const { children, className, onClick } = props;

    return (
        <div onClick={onClick} className={ cn(css.header, className) }>
            { children }
        </div>
    );
};

export const DiagnosticTableBody = (props) => {
    const { children, className, onClick } = props;

    return (
        <div onClick={onClick} className={ cn(css.body, className) }>
            { children }
        </div>
    );
};

export const DiagnosticTableRow = (props) => {
    const { children, className, onClick } = props;

    return (
        <div onClick={onClick} className={ cn(css.row, className) }>
            { children }
        </div>
    );
};

export const DiagnosticTableCell = (props) => {
    const { children, className, onClick } = props;

    return (
        <div onClick={onClick} className={ cn(css.cell, className) }>
            { children }
        </div>
    );
};

export const DiagnosticTableMultiRow = (props) => {
    const { children, className, onClick } = props;

    return (
        <div onClick={onClick} className={ cn(css.multiRow, className) }>
            { children }
        </div>
    );
};

