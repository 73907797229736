import { createDialogQueue } from '@rmwc/dialog';
import React from 'react';

export const queue = createDialogQueue();

const _prompt = queue.prompt;

queue.prompt = (props) => {
  const inputRef = React.createRef();
  const merged = {
    ...props,
    inputProps: {
      ...props.inputProps,
      inputRef: inputRef
    }
  }
  setTimeout(() => {
    inputRef.current.focus();
  }, 250);

  return _prompt(merged);
}