import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer, {STORE_VERSION} from './rootReducer';
import throttle from 'lodash.throttle';

let persistedState = undefined;

try {
  const serialized = localStorage.getItem('store');
  if (serialized) {
    persistedState = JSON.parse(localStorage.getItem('store'));
    if (!persistedState.about || persistedState.about.storeVersion !== STORE_VERSION) {
      persistedState = undefined;
    }
  }
} catch (err) {
  console.error('Failed to load persisted state', err);
}

function initStore() {
  const middleware = applyMiddleware(thunk);
  let store;
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    store = createStore(rootReducer, persistedState, middleware);
  } else {
    store = createStore(rootReducer, persistedState, composeWithDevTools(middleware));
  }
  if (persistedState) {
    store.dispatch({ type: 'INIT_FROM_CACHE'});
  }
  return store;
}

const store = initStore()

const throttledSave = throttle(() => {
  try {
    // Do not save temporary properties into local storage.
    const state = store.getState();
    const filteredState = Object.keys(state)
      .filter(key => !key.startsWith('_'))
      .reduce((obj, key) => ({ ...obj, [key]: state[key] }), {});

    const serialized = JSON.stringify(filteredState);
    localStorage.setItem('store', serialized);
  } catch (err) {
    console.error('Failed to persist to store', err);
  }
}, 1000);

store.subscribe(throttledSave);

export default store
