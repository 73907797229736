import {TYPES} from './project-actions';
import { requestSuccess, requestFailure, requestStart, extract, requestRefresh } from './meta';

const DEF_STATE = {
 
}

const projectReducer = (state = DEF_STATE, action) => {
  switch (action.type) {
    case TYPES.LOAD_USER_PROJECTS_SUCCESS: {
      const projects = action.payload.map((project) => {
        const prev = state[project.id];
        return {
          ...prev,
          ...project,
          ...requestSuccess(prev && prev._meta)
        }
      }).reduce((obj, project) => { 
        obj[project.id] = project;
        return obj
      }, {});

      return {
        ...state,
        ...projects
      };
    }
    case TYPES.LOAD_PROJECT_REQUEST: {
      const [old, _m] = extract(state, action.payload.id);
      return {
        ...state,
        [ action.payload.id] : {
          ...old,
          ...requestStart(_m)
        }
      }
    }

    case TYPES.LOAD_PROJECT_FAILURE: {

      return {
       ...state,
       [ action.payload.id ] : {
         ...state[ action.payload.id ],
         ...requestFailure(action.error)
       }
      }
    }
    case TYPES.LOAD_PROJECT_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
          ...requestSuccess()
        }
      }
    }
    case TYPES.DELETE_PROJECT_REQUEST_SUCCESS: {
      const { [ action.payload.projectId ]: _, ...others} = state;
      return {
        ...others
      }
    }
    case TYPES.LOAD_PROJECT_DOMAIN_REQUEST: {
      const [old, m] = extract(state, action.payload.id, 'domains');
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          domains: {
            list: [],
            ...old,
            ...requestStart(m)
          }
        }
        
      }

    }
    case TYPES.LOAD_PROJECT_DOMAIN_FAILURE: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          domains: {
            list: [],
            ...state[action.payload.id].domains,
            ...requestFailure(action.error)
          }
        }
      }
    }
    case TYPES.LOAD_PROJECT_DOMAIN_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          domains: {
            ...requestSuccess(),
            list: action.payload.domains
          }
        }
      }
    }
    case TYPES.LOAD_PROJECT_INVITE_REQUEST: {
      const [old, m] = extract(state, action.payload.id, 'invites');
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          invites: {
            list: [],
            ...old,
            ...requestStart(m)
          }
        }
      }

    }
    case TYPES.LOAD_PROJECT_INVITE_FAILURE: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          invites: {
            list: [],
            ...state[action.payload.id].invites,
            ...requestFailure(action.error)
          }
        }
      }
    }
    case TYPES.LOAD_PROJECT_INVITE_SUCCESS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          invites: {
            ...requestSuccess(),
            list: action.payload.invites
          }
        }
      }
    }
    case TYPES.UPDATE_PROJECT_DOMAIN_REQUEST_SUCCESS: {
      const newPerms = action.payload.permissions;
      const domainId = action.payload.domainId;
      const [domains, _meta] = extract(state, action.payload.id, 'domains');
      if (!_meta.ready) {
        return state;
      }
      const updated = domains.list.map((m) => {
        if (m.id === domainId) {
          return {
            ...m,
            permissions: newPerms
          }
        } else {
          return {
            ...m
          }
        }
      });

      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          domains: {
            ...state[action.payload.id].domains,
            list: updated
          }
        }
      }
    }
    case TYPES.UPDATE_PROJECT_DOMAIN_REQUEST_FAILURE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          domains: {
            ...state[action.payload.id].domains,
            ...requestFailure(action.error, state[action.payload.id].domains._meta)
          }
        }
      }
    case TYPES.LOAD_PROJECT_MEMBERS_REQUEST: {
      const [old, m] = extract(state, action.payload.id, 'members');
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          members: {
            list: [],
            ...old,
            ...requestStart(m)
          }
        }
      }

    }
    case TYPES.LOAD_PROJECT_MEMBERS_FAILURE: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          members: {
            list: [],
            ...state[action.payload.id].members,
            ...requestFailure(action.error)
          }
        }
      }
    }
    case TYPES.LOAD_PROJECT_MEMBERS_SUCCESS: {
      return {
      ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          members: {
            ...requestSuccess(),
            list: action.payload.members
          }
        }
      }
    }

    case TYPES.UPDATE_PROJECT_MEMBER_REQUEST_SUCCESS: {
      const newPerms = action.payload.permissions;
      const memberId = action.payload.memberId;
      const [members, _meta] = extract(state, action.payload.id,  'members');
      if (!_meta.ready) {
        return state;
      }
      const updated = members.list.map((m) => {
        if (m.userId === memberId) {
          return {
            ...m,
            permissions: newPerms
          }
        } else {
          return {
            ...m
          }
        }
      });

      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          members: {
            ...state[action.payload.id].members,
            list: updated
          }
        }
      }
    }
    case TYPES.UPDATE_PROJECT_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          members: {
            ...state[action.payload.id].members,
            ...requestFailure(action.error, state[action.payload.id].members._meta)
          }
        }
      }
    case TYPES.PROJECT_REFRESH_REQUEST: {
      const [, _m] = extract(state, action.payload.id);
      const [, _mMembers] = extract(state, [action.payload.id, 'members']);
      const [, _mDomains] = extract(state, [action.payload.id, 'domains']);
      const [, _mInvites] = extract(state, [action.payload.id, 'invites']);

      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...requestRefresh(_m),
          members: {
            ...state[action.payload.id].members,
            ...requestRefresh(_mMembers)
          },
          domains: {
            ...state[action.payload.id].domains,
            ...requestRefresh(_mDomains)
          },
          invites: {
            ...state[action.payload.id].invites,
            ...requestRefresh(_mInvites)
          }
        }
      }
    }
    default:
      return state;
  }
};

export default projectReducer;
