import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './error.scss'

import {Typography} from '@rmwc/typography';
import { getErrorMessage } from '../../common/errors';
import { Button } from '@rmwc/button';
import LinkWithToken from '../common/LinkWithToken';

//import { clearLimitedAccessToken } from '../../store/auth-actions';

//FIXME ME We need to show an error and to clear the token
const clearLimitedAccessToken = () => {};

const GeneralError = ({ title = "Ops.", message, explanation, children} ) => {
  const homeLink = null;
  return <div className='page-not-found'>
  <ul>
      <li><Typography use='headline2'>{ title }</Typography></li>
      <li><Typography use='headline4'>{ message }</Typography></li>
      <li><Typography use='headline6'>{ explanation }</Typography></li>
    </ul>
    {homeLink && <Button tag={LinkWithToken} to={homeLink} raised>Continue</Button> }
    { children }
  </div>

}

const _LimitedAccessTokenExpired = withRouter(({ error, children, match, clearLimitedAccessToken }) => {
  const link = match.url ? match.url : '/';

  return <GeneralError
    message='The link has expired. Get a fresh link or login on this device.'
    explanation='You can only install one application at a time within 15 minutes'
  >
    <a href={link} onClick={() => clearLimitedAccessToken()}>Continue</a>
  </GeneralError>
});

const LimitedAccessTokenExpired = connect((state, ownProps) => ownProps, { clearLimitedAccessToken  })(_LimitedAccessTokenExpired);


const Error = ({ error, children, ...rest }) => {
  if (!error) {
    return null;
  }

  if (error.responseError && error.responseError.code === 'invalidLimitedAccessToken') {
    return <LimitedAccessTokenExpired error={error.responseError} {...rest}/>
  }

  const msg = error.errorMessage || getErrorMessage(error);
  return <div className='error-container'>
      <Typography use='headline5' tag='h5' className='error-message'>{msg}</Typography>
      {error.errorHelp && error.errorHelp.split('\n')
        .map((line) => <div className='error-help-line-container' key={line}>
          <span className='error-help-line-text'>{line}</span>
        </div>)}
      <div className='error-help-links'>
      {children}
      </div>
  </div>
}


export default Error;