import { auth } from 'components/common/Firebase';
import { composeUrl, FETCH_DEFS, fetchWithAuth, POST_JSON } from "api/api-utils";
import { parseCSV } from "common/utils";


const requireAuth = () => {
  const currentUser = auth().currentUser;
  if (!currentUser) {
    throw new Error("Login first");
  }

  return currentUser;
}


export const getLatestSessions = async (projectId, options = {}) => {
  requireAuth()

  let {
    searchContinuationToken,
    latestDevicesContinuationToken,
    query = "",
    fromDate,
    toDate } = options;

  let headers = {};

  let reqOptions = {};

  let url;
  if (query || fromDate || toDate) {
    url = composeUrl(`/projects/${ projectId }/diagnostics/search/sessions-and-content`);
    let body = {
      query,
      fromDate,
      toDate,
      size: 100
    };

    if (searchContinuationToken) {
      headers["x-continuation"] = searchContinuationToken;
    }

    reqOptions = {
      projectId,
      ...POST_JSON,
      headers: {
        ...headers,
        ...POST_JSON.headers
      },
      body: JSON.stringify(body)
    };

    const response = await fetchWithAuth(url, reqOptions);
    const sessions = await response.json();
    searchContinuationToken = response.headers.get("x-continuation");

    return { sessions, searchContinuationToken };
  }

  if (latestDevicesContinuationToken) {
    headers["x-continuation"] = latestDevicesContinuationToken;
  }

  url = composeUrl(`/projects/${ projectId }/diagnostics/latest-devices-session`);
  reqOptions = {
    projectId,
    headers,
    ...FETCH_DEFS
  };

  const response = await fetchWithAuth(url, reqOptions);
  const sessions = await response.json();
  latestDevicesContinuationToken = response.headers.get("x-continuation");

  return { sessions, latestDevicesContinuationToken };
};

export const getDeviceSessions = async (projectId, deviceId) => {
  requireAuth()
  const url = composeUrl(`/projects/${ projectId }/diagnostics/device/${ deviceId }/sessions`);

  const response = await fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS
  });

  const sessions = await response.json();

  return { sessions };
};

export const getDeviceStatistics = async (projectId, deviceId) => {
  requireAuth();

  const url = composeUrl(`/projects/${ projectId }/diagnostics/device/${ deviceId }/device-info`);

  const response = await fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS
  });

  if (response.status !== 200)
    throw new Error("Something went wrong");

  const statsList = await response.json();

  for (const stats of statsList){
    stats.logs = parseCSV(stats.content)
    delete stats.content;
  }

  return statsList;
};

export const getSessionLog = async (projectId, logDownloadUrl) => {
  requireAuth();

  const downloadUrlResponse = await fetchWithAuth(composeUrl(`/${ logDownloadUrl }?url`), { projectId });
  const downloadUrlRes = await downloadUrlResponse.json();
  const { url } = downloadUrlRes;

  const response = await fetch(url);
  const csv = await response.text();

  return csv ? parseCSV(csv) : null;
};

export const getDeviceInfo = async (projectId, userInfoDownloadUrl) => {
  requireAuth();

  const downloadUrlResponse = await fetchWithAuth(composeUrl(`/${ userInfoDownloadUrl }?url`), { projectId });
  const downloadUrlRes = await downloadUrlResponse.json();
  const { url } = downloadUrlRes;

  const response = await fetch(url);

  if (response.status !== 200) return null;

  const csv = await response.text();

  return parseCSV(csv);
};

export const getWsUrlForLiveLogs = async (projectId, deviceId) => {
  requireAuth()
  const url = composeUrl(`/projects/${ projectId }/diagnostics/device/${ deviceId }/ws`);

  const response = await fetchWithAuth(url, {
    projectId,
    ...POST_JSON
  });

  if (response.status !== 200)
    throw new Error("Something went wrong while listening to live logs.");

  return await response.json();
};
