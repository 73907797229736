import {
  basenameWithoutExtension,
  dirname,
  joinPath,
  pathJoin,
  getProjectIdAndNameFromFilePath
} from '../../common/utils';
import * as semver from 'semver';


export const groupsBuilds = (builds) => {
  const names = {};
  if (builds) {
    builds.forEach(b => {
      //FIXME: This is temporary change for ios versions
      const major = b.version.split('.')[0];
      const name = b.name + major;
      let builds = names[name];
      if (!builds) {
        builds = []
        names[name] = builds;
      }
      builds.push(b);
    });
  }
  return Object.keys(names)
    .map(key => {
      const list = names[key];
      const latest = list[0];
      return {
        ...latest,
        list
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
}

export const getPathToBuildFile = (projectId, buildName, version, fileName) => {
  return `projects/${ projectId }/files/builds/${ buildName }/${ version }/${ fileName }`;
}

export const getFileUrl = (file) => {
  const { projectId, name } = getProjectIdAndNameFromFilePath(file.path);
  return pathJoin('/', projectId, 'files', name);
}

export const getFilesInFolder = (projectId, basedir, files) => {
  const baseLink = `/${ projectId }/browse/`;
  const basedirName = dirname(basedir);

  //a list of files in folder basedir, without 'firebase storage' folders i.e. application/x-www-form-urlencoded
  const fileList = files.filter((f) => f.dirname === basedirName && !f.contentType.startsWith('application/x-www-form-urlencoded'))
    .map(f => ({ ...f, link: joinPath(baseLink, basedir, f.name) }));

  const defaultFolders = [{
    dirname: '/ws'
  }, {
    dirname: '/builds'
  }];
  // since we don't have folders, create those from file paths under basedir
  const nested = files.concat(defaultFolders).filter((f) => f.dirname.startsWith(basedir))
    .map((f) => {
      const remainingPath = f.dirname.substring(basedir.length);
      const fi = remainingPath.indexOf('/');
      const next = fi > 0 ? remainingPath.substring(0, fi) : remainingPath
      return { ...f, folder: next };
    })
    .reduce((arr, f) => {
      if (f && f.folder && !arr[f.folder]) {
        arr[f.folder] = true
        arr.push(f);
      }
      return arr;
    }, [])
    .map(f => {
      return {
        contentType: 'Folder',
        updatedAt: null,
        name: f.folder,
        path: joinPath(`projects/${ projectId }/files/`, basedir, f.folder),
        link: joinPath(baseLink, basedir, f.folder, '/')
      }
    })

  const versionSort = !nested.find(it => !semver.valid(it.name))
  // Sort only version descending
  if (versionSort) {
    fileList.push(...nested.sort((it1, it2) => semver.compare(it2.name, it1.name)));
  } else {
    fileList.push(...nested);
  }

  return fileList;
}

const getFriendlyName = (fileName) => {
  const base = basenameWithoutExtension(fileName).replace(/-/g, ' ');
  return base.charAt(0).toUpperCase() + base.substring(1).toLowerCase();
}

export const getProjectTabs = (project, files, builds, showDiagnostics = true) => {

  const supportedTypes = ['text/markdown', 'application/vnd.oai.openapi+json', 'application/vnd.oai.openapi+yaml', 'text/html'];
  const isMimeTypeSupported = (contentType) => supportedTypes.indexOf(contentType.toLowerCase()) >= 0;

  const tabs = [];
  const { id } = project;
  const releasesTab = {
    name: 'Releases',
    type: 'builds',
    path: {
      path: `/${ id }/builds`,
      strict: true,
      exact: true
    }
  };


  const readme = /^readme(\..*)?$/gi;
  const fileTabs = !files ? [] : files.filter(file => isMimeTypeSupported(file.contentType) &&
    file.dirname === '/ws' && !file.basename.match(readme))
    .map((file) => ({
      type: 'markdown',
      name: getFriendlyName(file.basename),
      filePath: file.path,
      path: {
        path: `/${ id }/ws/${ file.name }`
      }
    }));

  tabs.push({
    name: 'Overview',
    type: 'overview',
    path: {
      path: `/${ id }/`,
      exact: true,
      strict: true
    }
  });

  tabs.push(...fileTabs);

  if (builds && builds.length > 0) {
    tabs.push(releasesTab);
  }

  if (showDiagnostics) {
    tabs.push({
      name: 'Diagnostics',
      type: 'diagnostics',
      path: {
        path: `/${ id }/diagnostics`,
      }
    });
  }

  const csvMimeType = 'text/csv';
  const areAnyProgressFiles = (!files ? [] : files).filter((file) => file.contentType === csvMimeType &&
    file.dirname.toLocaleLowerCase() === '/ws/.pantarhei').length !== 0;

  if (areAnyProgressFiles) {
    tabs.push({
      name: 'Panta Rhei',
      type: 'pantarhei',
      path: {
        path: `/${ id }/pantarhei`,
      }
    });
  }

  return tabs;
}