import { copySearchParams } from '../common/utils';
import { auth } from 'components/common/Firebase';

const AUTH_TIMEOUT = 2000;

const tokens = {};

export function getIdToken() {
  if (!auth().currentUser) {
    return new Promise((resolve, reject) => {
      let unsubscribe;
      // fail on timeout
      const timerId = setTimeout(() => {
        unsubscribe();
        reject(new Error('timeout'))
      }, AUTH_TIMEOUT);

      unsubscribe = auth().onIdTokenChanged(() => {
        clearTimeout(timerId);
        unsubscribe();
        if (auth().currentUser) {
          resolve();
        } else {
          reject(new Error('no user logged in'));
        }
      }, reject);
    }).then(() => auth().currentUser.getIdToken());
  } else {
    return auth().currentUser.getIdToken().then(token => {
      return token;
    });
  }
}

export function setLimitedAccessToken(projectId, limitedAccessToken)  {
  tokens[projectId] = { limitedAccessToken };
}

export function setSharedAccessToken(projectId, sharedAccessToken) {
  tokens[projectId] = { sharedAccessToken };
}

export function applyAuthorizationHeader(projectId, url, headers) {
  if (projectId) {
   const { limitedAccessToken, sharedAccessToken} = tokens[projectId] || {};
    if (limitedAccessToken) {
      headers.set('Authorization', `LimitedAccessToken ${limitedAccessToken}`);
      return Promise.resolve();
    } else if (sharedAccessToken) {
      headers.set('Authorization', `SharedAccessToken ${sharedAccessToken}`);
      return Promise.resolve();
    }
  }
  return getIdToken()
    .then((token) => {
      headers.set('Authorization', `Bearer ${token}`);
    });
}

export function appendAccessToken(projectId, url) {
  const { limitedAccessToken, sharedAccessToken} = tokens[projectId] || {};
  if (limitedAccessToken) {
    return Promise.resolve(copySearchParams(url, `limitedAccessToken=${encodeURIComponent(limitedAccessToken)}`));
  } else if (sharedAccessToken) {
    return Promise.resolve(copySearchParams(url, `shared=${encodeURIComponent(sharedAccessToken)}`));
  } else {
    return getIdToken(true)
      .then(token => copySearchParams(url, `accessToken=${encodeURIComponent(token)}`));
  }
}

export function canAccessProjectWithToken(projectId) {
  const { limitedAccessToken, sharedAccessToken} = tokens[projectId] || {};
  return !!(limitedAccessToken || sharedAccessToken);
}