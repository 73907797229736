import * as React from "react";
import { useLayoutEffect, useRef, useState } from "react";
import * as moment from 'moment';
import useComponentSize from "@rehooks/component-size";

import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow
} from "@rmwc/data-table";

import css from "./LogRowDetails.module.scss";

const LogRowDetails = ({ line }) => {
  const [, setForceRenderer] = useState(0);

  const wrapperRef = useRef(null);
  const wrapperSize = useComponentSize(wrapperRef);

  const tableRef = useRef(null);
  const tableSize = useComponentSize(tableRef);

  // force a renderer when the line changes to get the element dimensions after render
  useLayoutEffect(() => setForceRenderer(Math.random()), [line]);

  if (!line) return null;

  const longTime = moment(line["local_time"]).format("YYYY-MM-DD HH:mm:ss");

  const mappings = [
    ["File", line.file, css.fileCell],
    ["Line", line.line, css.lineCell],
    ["Method", line.function, css.methodCell],
    ["Tag", line.tag, css.tagCell],
    ["Date", longTime, css.dateCell],
  ];

  return (
    <div
      className={ css.wrapper }
      style={ { height: tableSize.height } }
      ref={ wrapperRef }>
        <DataTable
          className={ css.tableContainer }
          style={ { width: wrapperSize.width } }
          ref={ tableRef }>
            <DataTableContent className={ css.table }>
              <DataTableHead>
                <DataTableRow>
                  {
                    mappings.map(([key, value, className], index) =>
                      <DataTableHeadCell key={ index } className={ className }>
                        { key }
                      </DataTableHeadCell>
                    )
                  }
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>
                <DataTableRow>
                  { 
                    mappings.map(([key, value, className], index) =>
                      <DataTableCell key={ index } className={ className }>
                        { value }
                      </DataTableCell>
                    )
                  }
                </DataTableRow>
              </DataTableBody>
            </DataTableContent>
        </DataTable>
    </div>
  );
}

export default LogRowDetails;
