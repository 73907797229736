export const TYPES = {
  SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST : 'SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST',
  SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST_FAILURE : 'SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST_FAILURE',
  SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST_SUCCESS : 'SIGN_IN_WITH_CUSTOM_TOKEN_REQUEST_SUCCESS',
  CREATE_EMAIL_ACCOUNT_REQUEST: 'CREATE_EMAIL_ACCOUNT_REQUEST',
  CREATE_EMAIL_ACCOUNT_REQUEST_FAILURE: 'CREATE_EMAIL_ACCOUNT_REQUEST_FAILURE',
  CREATE_EMAIL_ACCOUNT_REQUEST_SUCCESS: 'CREATE_EMAIL_ACCOUNT_SUCCESS',
}
  
export const USER_LOGOUT = 'USER_LOGOUT';
export const CLEAR_STATE = 'CLEAR_STATE';
  