import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import { useState } from 'react';
import { showNotification } from '../../store/ui-actions';
import { verifyEmail } from '../../store/auth-actions';

const VERIFY_PROMPT = 'Please verify your email to get the full experience.';

const EmailVerification = ({ needsToVerify, email, ...props }) => {

  const [ open, setOpen ] = useState(needsToVerify);

  useEffect(() => {
    setOpen(needsToVerify);
  }, [ needsToVerify ]);

  const sendVerificationEmail = () => {
    const at = window.location;
    const continueUrl = `${at}`;
    props.verifyEmail(continueUrl);
    props.showNotification({message: `We sent an email to ${email}. Open it to verify your email.`})
    setOpen(false);
  }

  if (!needsToVerify) {
    return null;
  }

  return <>
    <Snackbar open={open} leading timeout={-1} message={VERIFY_PROMPT} dismissIcon='close'
      action={[
        <SnackbarAction label="Verify" onClick={sendVerificationEmail} />,
      ]}
    />

  </>
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const { email } = auth;
  const sharedToken = new URLSearchParams(window.location.search).get('shared');
  const needsToVerify = (auth._meta.ready && auth.authenticated && !auth.sharedToken && !sharedToken &&  !auth.emailVerified);

  return {
    needsToVerify,
    email
  }
}

export default connect(mapStateToProps, { showNotification, verifyEmail }) (EmailVerification)