import React, { useState } from 'react';

import { Typography } from '@rmwc/typography';
import { useEffect } from 'react';
import { Redirect, Link } from 'react-router-dom';

import { acceptProjectInvite } from '../../store/project-actions';
import { connect } from 'react-redux';
import SmartRedirect from '../common/SmartRedirect';


const AcceptInvite = ({acceptProjectInvite, ...props}) => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('inviteToken');
  const url = params.get('url') || '/';
  const projectId = props.match.params.projectId;

  const [accepted, setAccepted] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    acceptProjectInvite(projectId, token)
      .then((result) => {
        if (result.ok) {
          setAccepted(true);
        } else {
          setError(result.error);
        }
      })
  }, [token, acceptProjectInvite, projectId]);

  if (!token) {
    return <Redirect to='/'/>
  }

  if (accepted) {
    return <SmartRedirect to={url}/>
  }

  return <div className='create-account-panel main-panel '>
     {!error && <Typography use='headline4' className='login-welcome-title'> Just a few more seconds.</Typography>}
     { error && <>
       <Typography use='headline4' className='login-welcome-title'> This invite is no longer valid.</Typography>
       <Typography use='subtitle1' className='accept-invite-error'>
         Your invite might have expired. Reach out to the project owner for a new invite.
         <br/>
         <Link to='/home'>Take me home </Link>
       </Typography>
       </>
     }
  </div>
}

export default connect(undefined, { acceptProjectInvite })(AcceptInvite);