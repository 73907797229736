import { debounce } from "lodash";

const getParameters = (query, fromDate, toDate) => {
  const urlSearchParams = new URLSearchParams();

  urlSearchParams.set('isDiagnosticSearch', true);
  
  if (query) {
    urlSearchParams.set('q', query);
  }

  if (fromDate && fromDate instanceof Date) {
    urlSearchParams.set('fromDate', fromDate.toISOString());
  }

  if (toDate && toDate instanceof Date) {
    urlSearchParams.set('toDate', toDate.toISOString());
  }

  return urlSearchParams.toString();
};

export const submitQueryDebounce = debounce((query, fromDate, toDate, { history }) => {
  history.push({ search: getParameters(query, fromDate, toDate) });
}, 800);

export const submitQuery = (query, fromDate, toDate, { history }) => {
  history.push({ search: getParameters(query, fromDate, toDate) });
};
