import React from "react";
import { connect } from 'react-redux';

import { clearNotification } from '../../store/ui-actions';
import { Snackbar } from '@rmwc/snackbar';

import { getErrorMessage } from '../../common/errors';

const Notification = (props) => {
  if (!props.notification) {
    return null;
  }
  const msg = props.notification.error
    ? `${props.notification.message} ${getErrorMessage(props.notification.error)}`
    : props.notification.message;
  return (
    <Snackbar open={true} message={msg} onClose={() => props.clearNotification()} />
  );

}

const mapStateToProps = (state) => {
  return {
    notification: state.ui.notification
  }
}

const mapDispatchToProps = { clearNotification }

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
