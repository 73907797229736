import React from "react";
import { Link } from 'react-router-dom';
import { copySearchParams } from "../../common/utils";

const parseLinkDest = (to) => {
  let link;
  let url;
  if (to.match(/^[\w]+:\/\//)) {
    url = new URL(to);
    // make a "internal" link if it's the same host
    if (url.hostname === window.location.hostname) {
      link = url.pathname + url.search;
      url = null;
    }
  } else {
    link = to;
  }

  return { url, link };
}

const LinkWithToken = ({ to, ...props }) => {
  let { link, url } = parseLinkDest(to);
  if (url) {
    return React.createElement('a', {...props, href: url }, props.children);
  }
  
  if (link) {
    const params = new URLSearchParams(window.location.search);
    if (params.get('shared')) {
      link = copySearchParams(link, `?shared=${encodeURIComponent(params.get('shared'))}`);
    }
  }
  return <Link to={link} {...props} />
}

export default LinkWithToken;