import { TYPES } from './project-actions'

import { requestStart, requestSuccess, requestFailure, requestRefresh, extract } from './meta';

export default (state = {}, action) => {
  switch (action.type) {
    case TYPES.LOAD_PROJECT_FILES_REQUEST: {
      const projectId = action.payload.id;
      const [old, _m] = extract(state, projectId);
      return {
        ...state,
        [projectId]: {
          ...old,
          ...requestStart(_m)
        }
      }
    }
    case TYPES.SAVE_FILE_CONTENT_REQUEST_SUCCESS: {
      const { projectId } = action.payload;
      const [old, _m] = extract(state, projectId);
      if (old) {
        return {
          ...state,
          [projectId]: {
            ...old,
            _meta: {
              ...old,
              ...requestRefresh(_m)
            }
          }
        }
      }
      return state;
    }
    case TYPES.LOAD_PROJECT_FILE_REQUEST_SUCCESS: {
      const projectId = action.payload.id;
      return {
        ...state,
        [projectId]: {
          ...requestSuccess(),
          ...action.payload.files
        }
      }
    }
    case TYPES.LOAD_PROJECT_FILES_REQUEST_FAILURE: {
      const projectId = action.payload.id;
      const [, _meta] = extract(state, projectId);
      return {
        ...state,
        [projectId]: {
          ...requestFailure(action.error, _meta),
          ...action.payload.files
        }
      }
    }
    case TYPES.PROJECT_REFRESH_REQUEST: {
      const projectId = action.payload.id;
      const [old, _m] = extract(state, projectId);
      return {
        ...state,
        [projectId]: {
          ...old,
          ...requestRefresh(_m)
        }
      }
    }
    case TYPES.DELETE_PROJECT_FILE_REQUEST_SUCCESS: {
      const { projectId, path } = action.payload;
      const projectState = state[projectId];
      if (!projectState) {
        return state;
      }
      // if could be a folder, not just one file
      const newFiles = projectState.files.filter((f) => !f.path.startsWith(path));
      return {
        ...state,
        [projectId]: {
          ...projectState,
          files: newFiles
        }
      }
    }
    case TYPES.FILE_META_DATA_UPDATED: {
      const { projectId, path, contentType } = action.payload;
      const projectState = state[projectId];
      if (!projectState) {
        return state;
      }

      return {
        ...state,
        [projectId]: {
          ...projectState,
          files: projectState.files.map(file => file.path === path ? { ...file, contentType } : file)
        }
      }
    }
    
    default:
      return state;
  }
}

