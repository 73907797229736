import React, { useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import './Project.scss';

import { CircularProgress } from '@rmwc/circular-progress';

import AnyContent from '../Content/AnyContent';
import ProjectHelp from './ProjectHelp';
import Error from '../common/Error';
import FileBrowser from '../Files/FileBrowser';

import { getProjectDetails, loadProjectBuilds, loadProjectFiles, refreshProject } from 'store/project-actions';
import { extract, isRefresh } from 'store/meta';
import { getProjectTabs } from 'components/Project/projects-utils';

import { Route, Switch, useHistory } from "react-router-dom";
import Builds from './Builds'

import { toggleQueryParam } from 'common/utils';
import Overview from 'components/Project/Overview';
import { useProjectChanges } from "components/Project/project-changes";

import Diagnostics from 'components/Project/Diagnostics/Diagnostics';
import { useShouldShowDiagnosticsTab } from 'components/Project/Diagnostics/Helper';
import { searchSessions } from "store/diagnostics-actions";
import { useQueryStringParams } from "common/hooks/useQueryStringParams";
import Progress from "./Progress";


const Project = ({ projectId, project, files, builds, ready, error, loadProjectFiles, loadProjectBuilds, match, refreshProject, refreshFiles, refreshBuilds }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { showDiagnostics } = useShouldShowDiagnosticsTab(projectId);
  const { lat, shared, isDiagnosticSearch } = useQueryStringParams();
  const isShared = lat || shared;

  useEffect(() => {
    loadProjectFiles(projectId);
  }, [loadProjectFiles, projectId, refreshFiles]);

  useEffect(() => {
    loadProjectBuilds(projectId)
  }, [loadProjectBuilds, projectId, refreshBuilds]);

  useEffect(() => {
    if (isShared || isDiagnosticSearch) return;
    dispatch(searchSessions(projectId, { fromStart: true }));
  }, [isShared, dispatch, projectId, isDiagnosticSearch]);

  useProjectChanges(projectId, "Projects", refreshProject);

  const actions = {};
  const params = new URLSearchParams(window.location.search);
  if (params.has('edit') && (params.get('edit') === 'true' || params.get('edit') === '')) {
    actions.edit = true;
  }

  const onEditModeChanged = (edit) => {
    if (edit) {
      toggleQueryParam(history, 'edit', true);
    } else {
      toggleQueryParam(history, 'edit', false);
    }
  }

  let baseUrl = match.url;
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }
  if (error) {
    return <Error error={ error }/>
  }

  if (!ready) {
    return <CircularProgress className='full-page-loader' size={ 128 }/>;
  }

  const tabs = getProjectTabs(project, files, builds, showDiagnostics)
    .map((tab) => {
      let display;
      if (tab.type === 'builds') {
        display = <Builds projectId={ projectId }/>;
      } else if (tab.type === 'overview') {
        display = <Overview projectId={ projectId }/>;
      } else if (tab.type === 'diagnostics') {
        display = <Diagnostics projectId={ projectId }/>;
      } else if (tab.type === 'pantarhei') {
        display = <Progress projectId={ projectId } />
      } else {
        display = <AnyContent actions={ actions } projectId={ projectId } filePath={ tab.filePath }
                              onEditModeChanged={ onEditModeChanged }/>
      }
      return { ...tab, display };
    })

  return <div className='project-container'>
    <Error error={ error }/>
    { tabs.length > 0 &&

    <div>
      <Switch>
        { tabs.slice().reverse().map((tab) => <Route key={ tab.path.toString() } { ...tab.path }
                                                     render={ () => tab.display }/>) }
        <Route strict exact path='/:projectId/help'>
          <ProjectHelp/>
        </Route>
        <Route strict path='/:projectId/builds' exact render={ () => <Builds projectId={ projectId }/> }/>
        <Route strict path='/:projectId/browse/:base(.*)'
               render={ ({ match: { params: { base } } }) => <FileBrowser projectId={ projectId } base={ base }/> }/>
        <Route strict path='/:projectId/builds/:name/:version?'
               render={ ({ match: { params: { name, projectId, version } } }) => <Builds { ...actions }
                                                                                         projectId={ projectId }
                                                                                         name={ name }
                                                                                         version={ version }/> }/>
        <Route path={ '/:projectId/(files)?/:path+' }
               render={ ({ match: { params: { projectId, path } } }) =>
                 <AnyContent actions={ actions }
                             projectId={ projectId }
                             path={ path }
                             onEditModeChanged={ onEditModeChanged }/> }/>
      </Switch>
    </div>
    }
  </div>;
};


const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.projectId;

  const [project, projectState] = extract(state.projects, projectId);
  const [files, filesState] = extract(state.files, projectId, 'files');
  const [builds, buildsState] = extract(state.builds, projectId, 'builds');

  const loading = projectState.loading || filesState.loading || buildsState.loading;
  const error = projectState.error || filesState.error || buildsState.error;
  const ready = projectState.ready && filesState.ready && buildsState.ready;

  return {
    projectId,
    loading,
    error,
    ready,
    project,
    files,
    builds,
    refreshFiles: isRefresh(state.files[projectId]),
    refreshBuilds: isRefresh(state.builds[projectId])
  };
}

const mapDispatchToProps = { getProjectDetails, loadProjectFiles, loadProjectBuilds, refreshProject };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Project);
