import { useEffect } from 'react';
import { connect } from 'react-redux';
import { extract } from '../../store/meta';


const TitleSetter = ({ title } ) => {
  useEffect(() => {
    document.title = title;
  }, [ title ]);
  return null;
}

const mapStateToProps = (state, ownProps) => {
  const { match: { params: { projectId } } } = ownProps;
  let title = 'Spotlight @ Inova';
  if (projectId) {
    const [ project ] = extract(state.projects, projectId);
    if (project && project.name) {
      title = project.name;
    }
  }

  return { title };
}


export default connect(mapStateToProps)(TitleSetter);