import React from 'react';
import Markdown from 'react-markdown/with-html';
import StyleCodeBlock from './StyleCodeBlock';
import LinkWithToken from '../common/LinkWithToken';
import MermaidDiagram from "components/MermaidDiagram";

const Marked = ({ toc = false, source, disableDiagrams = false, children, ...props }) => {
  const headings = [];
  let mermaidCounter = 0;

  const renderers = {
    code: (props) => {
      const { language } = props;

      if (!disableDiagrams && language === "mermaid") {
        const diagramDef = props.value;

        return <MermaidDiagram key={ diagramDef } definition={ diagramDef }
                               id={ `MarkdownMermaid-${ mermaidCounter++ }` }/>
      }

      return <StyleCodeBlock { ...props } />;
    },
    heading: (props) => {
      const child = props.children[0];
      // ignore empty tags, so we can link them
      if (!child || typeof child.props.value !== 'string') {
        return Markdown.renderers.heading(props);
      }
      const key = child.props.value.replace(/\s/g, '-');
      if (toc) {
        headings.push(<li key={ key + '-link' + headings.length } className={ `toc-heading-${ props.level }` }><a
          href={ '#' + key }>{ props.children }</a></li>)
      }
      return React.createElement(`h${ props.level }`, { id: key }, props.children)
    },
    link: (props) => {
      if (props.href && props.href.startsWith('#')) {
        return <a href={ props.href }>{ props.children }</a>
      } else if (props.href) {
        return <LinkWithToken to={ props.href }>{ props.children }</LinkWithToken>
      } else {
        return null;
      }
    }
  }

  return <div className={ toc ? 'markdown-grid-container-with-toc' : 'markdown-grid-container' }>

    <div className={ toc ? 'markdown-page-content-with-toc' : 'markdown-page-content' }>
      { children }
      <Markdown className='markdown-body'
                { ...props }
                escapeHtml={ false } source={ source }
                renderers={ renderers }
      />
    </div>

    { toc &&
    <div className='markdown-page-toc'>
      <ul>
        { headings }
      </ul>
    </div>
    }
  </div>
}

export default Marked;