
import React, { useState } from 'react'

import '@rmwc/dialog/styles'
import 'components/Project/GeneralDialog.scss'
import { useDispatch } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogButton, DialogActions } from '@rmwc/dialog'
import { Button } from '@rmwc/button'
import { Typography } from '@rmwc/typography'
import { TextField } from '@rmwc/textfield'
import { Checkbox } from '@rmwc/checkbox'

import { showNotification } from 'store/ui-actions'
import { sendAnnouncement } from 'store/project-actions'
import { isEmailValid } from 'components/utils'

const AnnouncementDialog = ({ isOpen, onClose, projectId, buildName, version }) => {
    const [email, setEmail] = useState('')
    const [emailInvalid, setEmailInvalid] = useState(true)
    const [recepients, setRecepients] = useState({})
    const [sendPushNotifications, setSendPushNotifications] = useState(true)
    const [sendToSelf, setSendToSelf] = useState(true)
    const [announcement, setAnnouncement] = useState('')
    const dispatch = useDispatch()

    const addEmail = () => {
        recepients[email] = true
        setRecepients(recepients)
        setEmail('')
    }

    const handleKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            addEmail()
        }
    }

    const handleEmail = (e) => {
        setEmailInvalid(!isEmailValid(e.currentTarget.value))
        setEmail(e.currentTarget.value)
    }

    const postAnnouncement = async () => {
        dispatch(showNotification({ message: `Sending announcement` }))
        try {
            await dispatch(sendAnnouncement({ projectId, announcement, sendPush: sendPushNotifications, sendToSelf, emails: Object.keys(recepients), version, buildName }))
            dispatch(showNotification({ message: `Announcement sent` }))
        } catch (_) {
            dispatch(showNotification({ message: `Failed to send announcement` }))
        }
    }

    const handleDialogClose = (e) => {
        if (e.detail.action === 'sendAnnouncementAction') {
            addEmail()
            postAnnouncement()
        }

        onClose(e)
    }

    const handleEmailRemove = (email) => () => {
        delete recepients[email]
        setRecepients({ ...recepients })
    }

    return <Dialog className='general-dialog' open={isOpen} onClose={handleDialogClose}>

        <DialogTitle>Send an Announcement</DialogTitle>

        <DialogContent className='add-member-dialog-content' >
            <div className='add-member-people-section'>
                <Typography use='subtitle'>Add recepients</Typography>
                <TextField
                    label='Email' className='dialog-email-field'
                    helpText='Enter email address'
                    value={email}
                    invalid={emailInvalid}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => handleEmail(e)} />
            </div>

            {Object.keys(recepients).length > 0 && (<div className='add-member-people-section' style={{ marginTop: '1rem' }}>
                <Typography use='title'>Recepients</Typography>
                {Object.keys(recepients).filter(it => it).map(it => (
                    <div key={it} style={{ marginLeft: '1rem' }}>
                        <Typography use='subtitle' >{it}</Typography>
                        <Button label='Remove' onClick={handleEmailRemove(it)} />
                    </div>))}
            </div>)}

            <TextField
                textarea
                className='dialog-email-field'
                label='Announcement'
                helpText='Enter announcement text'
                value={announcement}
                fullwidth
                rows={5}
                onChange={(e) => setAnnouncement(e.currentTarget.value)} />

            <Checkbox label='Send push notifications' checked={sendPushNotifications} onChange={(e) => setSendPushNotifications(Boolean(e.currentTarget.checked))} />
            <Checkbox label='Send to self' checked={sendToSelf} onChange={(e) => setSendToSelf(Boolean(e.currentTarget.checked))} />

        </DialogContent>

        <DialogActions>
            <DialogButton action='close'>Close</DialogButton>
            <DialogButton action='sendAnnouncementAction'>Send Announcement</DialogButton>
        </DialogActions>

    </Dialog>
}

export default AnnouncementDialog
