import React from 'react';
import { withRouter } from "react-router";

import {Card, CardPrimaryAction } from "@rmwc/card";

import {Typography} from '@rmwc/typography';


class ProjectCard extends React.Component {

  render() {
    const projectPath = `/${encodeURI(this.props.projectId)}/`;
    const projectName = this.props.projectName || this.props.projectId

    return (
    <Card className='project-card'>
      <CardPrimaryAction className='project-card__primary-action' onClick={() => this.props.history.push(projectPath)}>
        <div className='project-card__primary'>
          <Typography use='headline6' tag='h6' className='project-card__title' data-name={projectName}>
            {projectName}
          </Typography>
          <Typography use='subtitle2' className='project-card__subtitle'>
          </Typography>
        </div>
        <Typography use='body2' className='project-card__secondary'>
          {this.props.projectDescription}
        </Typography>
      </CardPrimaryAction>
     
  </Card>);
  }
}

export default withRouter(ProjectCard);