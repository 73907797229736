import React, { useState, useEffect } from "react";

import { SimpleListItem, CollapsibleList } from '@rmwc/list'

import { Switch } from '@rmwc/switch';
import { Button } from '@rmwc/button';

const Member = ({ member, onRemove, userPermissions, onUpdateMemberPermissions }) => {
  const primary = (member) => {
    // used for domain and members
    if (member.firstName || member.lastName || member.id) {
      const arr = [member.firstName, member.lastName, member.id];
      return arr.filter((a) => a).join(' ');
    }
    return member.email;
  }

  useEffect(() => {
    setPermissions(member.permissions)
  }, [member.permissions])

  const [permissions, setPermissions] = useState(member.permissions);
  const dirty = JSON.stringify(permissions) !== JSON.stringify(member.permissions);
  const isAdmin = userPermissions['admin'];

  // used for domain and members
  let key = member.userId || member.id
  const permsList = Object.keys(permissions).map(perm => {
    return (<SimpleListItem key={key + perm} className='permission-list-item'
      text={perm}
      meta={
        <Switch checked={permissions[perm]} disabled={!isAdmin}
          onChange={evt => setPermissions({ ...permissions, [perm]: evt.currentTarget.checked })} />
      } />)
  }
  )

  return <CollapsibleList
    handle={
      <SimpleListItem
        text={primary(member)}
        metaIcon="chevron_right"
      />
    }>
    {permsList}

    <div className='permission-list-item-action-container'>
      <Button label='Remove' disabled={member.me || !userPermissions['addMember']} onClick={() => onRemove(member)} />
      {Boolean(permsList.length) && <Button label='Save' disabled={!dirty} onClick={() => onUpdateMemberPermissions(member, permissions)} />}
    </div>

  </CollapsibleList>
}

export default Member;
