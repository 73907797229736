import React from 'react';
import Marked from '../Content/Marked';

import docs from '../../docs/getting-started.md';
import { useState } from 'react';
import { useEffect } from 'react';


const ProjectHelp = ({ children }) => {
  const [gettingStarted, setGettingStarted] = useState('...');
  useEffect(() => {
    fetch(docs).then(res => res.text()).then(text => setGettingStarted(text));
  });


  return <Marked toc source={gettingStarted}>
      {children}
      </Marked>
}



export default ProjectHelp;