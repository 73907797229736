
import { filesizeToBytes, getFriendlyFileSize } from "common/utils";

/**
 * Adds or removes 1 major unit of the size provided
 * @param size {number} the size in bytes
 * @param add {boolean} wether to add or subtract 1 major unit
 */
const oneMajorUnitDist = (size, add = true) => {
  const HRSize = getFriendlyFileSize(size);
  const multiplier = add === true ? 1 : -1;

  // 1 of whatever unit size is in
  const unitSize = filesizeToBytes(`1${ HRSize.slice(-2) }`);

  return size + (unitSize * multiplier);
};

export const sessionMetaTableMapping = [
  { prop: "deviceAppVersion", label: "Version" },
  { prop: "deviceAppBuild", label: "Build" },
  { prop: "deviceName", label: "Device Name" },
  { prop: "deviceModel", label: "Model" },
  { prop: "deviceSystem", label: "System" },
  { prop: "deviceSystemOS", label: "OS" },
  { prop: "deviceSystemLanguage", label: "Language" },
  { prop: "userId", label: "User ID" }
];

const lineColors = ["#C62828", "#1A237E", "#388E3C", "#0091EA", "#EF6C00", "#4E342E", "#512DA8", "#37474F"];

export const deviceInfoTableMapping = [
  { prop: "battery_level", label: "Battery Level", valueRange: [0, 100], color: lineColors[0] },
  { prop: "thermal_level", label: "Thermal Level", color: lineColors[1] },
  {
    prop: "free_space", label: "Free space", transform: getFriendlyFileSize,
    valueRange: [v => oneMajorUnitDist(v[0], false), v => oneMajorUnitDist(v[1], true)],
    color: lineColors[2],
  },
  {
    prop: "used_memory", label: "Used memory", transform: getFriendlyFileSize,
    valueRange: [v => oneMajorUnitDist(v[0], false), v => oneMajorUnitDist(v[1], true)],
    color: lineColors[3],
  },
  {
    prop: "used_CPU", label: "Used CPU", valueRange: [0, 1], transform: (value) => Math.round(value * 100) + "%",
    color: lineColors[4],
  },
];

export const prepareDeviceInfoGraphData = (graphData) => {
  if (!graphData || !Array.isArray(graphData)) {
    return [];
  }

  const graphInfoGridValues = [];
  for (const map of deviceInfoTableMapping) {
    if (graphData.length === 1) {
      graphInfoGridValues.push({
        value: graphData[0][map.prop],
        label: map.label,
        transform: map.transform
      });
    } else {
      graphInfoGridValues.push({
        label: map.label,
        transform: map.transform,
        values: graphData.map(row => ({ x: row.local_time, y: row[map.prop] })),
        valueRange: map.valueRange,
        color: map.color,
      });
    }
  }
  
  return graphInfoGridValues;
};
