import React, { useEffect, useState, useCallback } from "react";

import { connect } from 'react-redux';

import * as meta from '../../store/meta';

import { Typography } from '@rmwc/typography';
import { LinearProgress } from '@rmwc/linear-progress';
import { IconButton } from '@rmwc/icon-button';
import { Icon } from '@rmwc/icon';
import { CollapsibleList } from '@rmwc/list';
import { Tooltip } from '@rmwc/tooltip';
import { MenuSurfaceAnchor, MenuSurface, MenuItem } from '@rmwc/menu';
import { Portal } from "@rmwc/base";
import cn from "classnames";
import { getProjectDetails,loadProjectBuilds, deleteProjectBuild, createProjectBuildFile, addRemoveWatchFromBuild, changeBuildName, pinVersion, unpinVersion } from 'store/project-actions';
import { showNotification } from 'store/ui-actions';
import { isFileMarkdown, getFriendlyFileSize, getProjectRelativePath, toggleQueryParam } from 'common/utils';
import { groupsBuilds, getPathToBuildFile, getFileUrl } from './projects-utils';

import MarkdownContent from '../Content/MarkdownContent';
import BuildActions from './BuildActions';
import WithPermission from '../common/WithPermission';
import { queue } from '../common/dialog-queue';

import * as moment from 'moment';

import './builds.scss';
import LinkWithToken from "../common/LinkWithToken";

import { Route, useHistory, matchPath, Redirect } from "react-router-dom";
import AnnouncementDialog from "./AnnouncementDialog";
import EditTextDialog from "components/common/EditTextDialog"
import ShareVersionDialog from "components/Project/ShareVersionDialog";
import { useQueryStringParams } from "common/hooks/useQueryStringParams";

const CHANGELOG_TEMPLATE = `
### Added
-

### Changed
-
`;

const linkTo = (projectId, build) => `/${projectId}/builds/${build.name}/${build.version}`;



const DownloadableFiles = ({projectId, build, showNotification}) => {
//  const noDownload = ['.ipa', '.apk', '.md', '.markdown', '.png'];
  //const list = build.files.filter((f) => !noDownload.find((extension) => f.basename.endsWith(extension)));
  const list = build.files;

  if (list.length === 0) {
    return null;
  }

  const fileRow = (file) =>
    <div key={file.path}>
      <dt><LinkWithToken to={getFileUrl(file)}>{file.basename}</LinkWithToken> </dt>
      <span> </span>
      <dd>({getFriendlyFileSize(file.size)})</dd>
    </div>

  return  <div className='builds-list-container'>
    <dl className='builds-list'>
      { list.map(fileRow)}
      </dl>
    </div>
}

const NewFile = ({ projectId, onEditModeChange, build, fileName, createProjectBuildFile, ...props }) => {
  const path = getPathToBuildFile(projectId, build.name, build.version, fileName);

  return <MarkdownContent projectId={projectId} newFile path={ path } editable { ...props } content={ CHANGELOG_TEMPLATE }
                          editMode={ true }
                          onEditModeChange={ onEditModeChange }
  />
}

const BuildMoreMenu = ({ project, build, addRemoveWatchFromBuild, pinVersion, unpinVersion }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [watching, setWatching] = React.useState(build.watching)
  const [openAnnouncement, setOpenAnnouncement] = useState(false)
  const [openShareVersion, setOpenShareVersion] = useState(false)
  const { shared: sharedToken = '' }= useQueryStringParams()
  const isShareToken = !!sharedToken

  const watchClicked = () => {
    const isWatching = !watching
    addRemoveWatchFromBuild(project.id, build.name, isWatching)
    setWatching(isWatching)
  }

  const handlePinBuild = async () => {
    if (build.pinned) {
      await unpinVersion(project.id, build.name, build.version)
    } else {
      await pinVersion(project.id, build.name, build.version)
    }
  }

  const handleCloseAnnouncementDialog = useCallback(() => { setOpenAnnouncement(false) }, [])
  const handleCloseShareDialog = useCallback(() => { setOpenShareVersion(false) }, [])

  const token = project.shareTokens && project.shareTokens.find(it =>
    it.paths &&
    it.paths.length === 1 &&
    it.paths[0] === `/builds/${build.name}/${build.version}`)

  return <>{ !isShareToken &&
    <MenuSurfaceAnchor>
      <MenuSurface className='main-menu'
        anchorCorner='bottomStart'
        renderToPortal
        open={menuOpen}
        onSelect={evt => console.log(evt)}
        onClose={() => setMenuOpen(false)}>
        <WithPermission permission='upload'>
          <MenuItem onClick={() => { setOpenAnnouncement(true); setMenuOpen(!menuOpen); }}>Send an Announcement</MenuItem>
        </WithPermission>
        <MenuItem onClick={watchClicked}>{watching ? `Stop watching ${build.name}` : `Start watching ${build.name}`}</MenuItem>
        <MenuItem data-id='build-more-action-pin' onClick={handlePinBuild}>{build.pinned ? 'Unpin' : 'Pin'} {build.name} - {build.version}</MenuItem>
        <WithPermission permission='share'>
          <MenuItem data-id='build-more-action-share-button' onClick={() => { setOpenShareVersion(true); setMenuOpen(!menuOpen); }}>Share {build.name} - {build.version}</MenuItem>
        </WithPermission>

      </MenuSurface>
      <IconButton icon='more_vert' data-id='build-more-action-button'
        onClick={_ => setMenuOpen(!menuOpen)} />
    </MenuSurfaceAnchor>}
    <AnnouncementDialog
      continueUrl={window.location.href}
      projectId={project.id}
      version={build.version}
      isOpen={openAnnouncement}
      buildName={build.name}
      shareToken=''
      onClose={handleCloseAnnouncementDialog} />

    <ShareVersionDialog
      projectId={project.id}
      buildName={build.name}
      version={build.version}
      isOpen={openShareVersion}
      shareToken={token ? token.token : ''}
      onClose={handleCloseShareDialog}/>
  </>
}

const BuildMenu = connect(null, { deleteProjectBuild, showNotification, createProjectBuildFile })(({ build, builds, project, addRemoveWatchFromBuild, pinVersion, unpinVersion, ...props }) => {
  const history = useHistory();

  const deleteBuild = () => {
    queue.confirm({
      title: `Delete ${build.name} ${build.version}`,
      body: `Are you sure you want to delete ${build.name} ${build.version}. This action cannot be undone.`,
      acceptLabel: 'Delete',
      danger: true,
      cancelLabel: 'Cancel',
    }).then((response) => {
      if (response) {
        props.showNotification({message: `Deleting ${build.name} ${build.version}`});
        props.deleteProjectBuild(project.id, build.name, build.version);
      }
    })
  }

  const mdFile = build.files.find((f) => isFileMarkdown(f.path));
  const fileName = mdFile ? mdFile.basename : 'Changelog.md';

  const editLink = `/${project.id}/builds/${build.name}/${build.version}/${fileName}?edit`;

  const onEditChangelog = () => {
    history.replace(editLink);
  }

  return <><dt className='build-menu-actions'>
      <WithPermission permission='editFiles'>
        <Tooltip content={mdFile ? `Edit ${mdFile.basename}` : `Add ${fileName}`}>
          <IconButton data-id='edit-file-button' icon='create' onClick={onEditChangelog} />
        </Tooltip>
        <Tooltip content={`Delete ${build.version}`}>
          <IconButton data-id='delete-build' icon='delete' onClick={deleteBuild} />
        </Tooltip>
      </WithPermission>
    </dt>
    <dt className='build-menu-actions'>
      <BuildMoreMenu project={project} build={build} addRemoveWatchFromBuild={addRemoveWatchFromBuild} pinVersion={pinVersion} unpinVersion={unpinVersion}/>
    </dt>
  </>
});

const Build = ({build, builds, refresh, projectId, editAction, project, addRemoveWatchFromBuild, changeBuildName, pinVersion, unpinVersion, ...props}) => {
  const history = useHistory();
  const [buildNameToChange, setBuildNameToChange] = useState(null)
  const onEditModeChanged = (status) => {
    toggleQueryParam(history, 'edit', status);
  }

  const buildPath = `builds/${build.name}/${build.version}`;
  const editMatch = matchPath(history.location.pathname, {
    path: `/:projectId/${buildPath}/:fileName(.*)`
  });

  let existingFile = false;

  const mdFiles = build.files.filter((f) => isFileMarkdown(f.basename))
    .map (f => {
      if (editMatch && `${buildPath}/${editMatch.params.fileName}` === getProjectRelativePath(f.path) ) {
        existingFile = true;
        return {
          ...f,
          edit: editAction
        }
      } else {
        return f;
      }
    });

  const versionRow = (b) => <div key={b.name + b.version} className={b.version === build.version && build.name === b.name ? 'active' : ''}>
    <dt><LinkWithToken to={linkTo(projectId, b)}>{b.version}</LinkWithToken> </dt>
    <span/>
    <dd>{moment(b.createdAt).format('LL')}</dd>
    {b.pinned && <Icon className='build-menu-small-icon' icon='push_pin' />}
  </div>

  const handleBuildNameChangeClick = () => {
    setBuildNameToChange(build.name)
  }

  const handleBuildNameToChange = async (buildName) => {
    await changeBuildName(projectId, buildNameToChange, buildName)
    history.goBack()
    setBuildNameToChange(null)
  }

  return <div className='build-container'>

      <ul className='build-info'>
        <li>
          <div className='build-info-header'>
            <Typography className='build-title' use='headline4' tag={LinkWithToken} to={`/${projectId}/builds/${build.name}`} > {build.name} </Typography>
            <WithPermission permission='admin'>
              <IconButton icon='edit' onClick={handleBuildNameChangeClick} />
            </WithPermission>
            <span/>
            <BuildMenu pinVersion={pinVersion} unpinVersion={unpinVersion} addRemoveWatchFromBuild={addRemoveWatchFromBuild} build={build} project={project} builds={builds}/>
          </div>
        </li>
        <li><Typography className='build-title' use='headline5' data-name={build.version}>{build.version}</Typography></li>
        <li><Typography use='subtitle1'>{moment(build.createdAt).format('LLL')}</Typography></li>
      </ul>
     
     
      <BuildActions className='install-button' build={build} projectId={projectId} raised/>
   
    {
      mdFiles.map(file =>
        <WithPermission key={file.path} permission='editFiles' pass={ { editable: false, edit: file.edit} }>
          <MarkdownContent projectId={projectId} refresh={refresh} path={file.path} onEditModeChange={onEditModeChanged} editMode={editAction}/>
        </WithPermission> )
    }
    {editAction && !existingFile &&
      <WithPermission permission='editFiles'>
        <Route key='new-file-route' path={`/${projectId}/builds/${build.name}/${build.version}/:fileName(.*)`}
          render={({ match: { params : { fileName } } }) =>
            <NewFile build={build} projectId={projectId} fileName={fileName} onEditModeChange={onEditModeChanged}/>}>
        </Route>
      </WithPermission>
    }
    <DownloadableFiles projectId={projectId} build={build} showNotification={ props.showNotification} />
    {builds.length > 1 &&
    <CollapsibleList data-id='build-all-versions' handle={<Typography use='subtitle1' tag='p' className='builds-list-title'>all versions <Icon icon='chevron_right'/> </Typography>}>
      <div className='builds-list-container'>
        <dl className='builds-list'>
        {builds.map(versionRow)}
        </dl>
      </div>
    </CollapsibleList>
    }
    {buildNameToChange && <EditTextDialog
      title='Change Build Name'
      label='Build Name'
      isOpen={buildNameToChange}
      onDialogValueChanged={handleBuildNameToChange}
      defaultDialogValue={buildNameToChange}
      onClose={() => setBuildNameToChange(null)} />}
  </div>
}

const NamedBuild = ({ projectId, refresh, builds, selected, project, addRemoveWatchFromBuild, pinVersion, unpinVersion, ...props }) =>
  <div className={ cn("condensedBuild", props.className) }>
    <Build pinVersion={ pinVersion } unpinVersion={ unpinVersion } changeBuildName={ changeBuildName }
           addRemoveWatchFromBuild={ addRemoveWatchFromBuild } refresh={ refresh }
           project={ project } projectId={ projectId } build={ selected } builds={ builds } { ...props } />
  </div>

const Builds = ({ projectId, buildMeta, buildName, version, project, builds, loadProjectBuilds, addRemoveWatchFromBuild, pinVersion, unpinVersion, ...props }) =>  {
  const { shared: sharedToken = '' }= useQueryStringParams()
  
  useEffect(() => {
    loadProjectBuilds(projectId);
  }, [ loadProjectBuilds, projectId, buildMeta.refresh ]);

  let list = builds;
  if (buildName) {
    list = builds.filter((b) => b.name === buildName);
  }

  // build was deleted
  if (buildName && list.length === 0 && !sharedToken) {
    return <Redirect to={`/${projectId}/`}/>
  }

  const groups = groupsBuilds(list)
    .map((group) => {
      if (version) {
        group.selected = group.list.find((b) => b.version === version)
      }

      if (!group.selected) {
        group.selected = group.list[0];
      }
      return group;
    })
  .filter((g) => g);

  if (!buildMeta.ready) {
    return <LinearProgress/>;
  }
  const cn = groups.length > 1 ? 'multiple-builds' : 'single-build'

  return <div className="project-builds">
    {/* Portal needed for more menu displaying, so it is not cut off */}
    <Portal />
    { groups.map((group) => <NamedBuild pinVersion={pinVersion} unpinVersion={unpinVersion} addRemoveWatchFromBuild={addRemoveWatchFromBuild} className={cn}
                                        {...props} refresh={buildMeta.refresh} key={group.name + group.list[0].version} project={project}
                                        projectId={projectId} builds={group.list} selected={group.selected} showNotification={props.showNotification}/> )}
  </div>
}

const mapDispatchToProps = { getProjectDetails, loadProjectBuilds, deleteProjectBuild, createProjectBuildFile, showNotification, addRemoveWatchFromBuild, changeBuildName, pinVersion, unpinVersion };

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.projectId;
  const buildName = ownProps.name;
  const version = ownProps.version;

  const [project] = meta.extract(state.projects, projectId);
  const [builds, buildMeta] = meta.extract(state.builds, projectId, 'builds');

  return {
    projectId,
    project,
    buildName,
    version,
    builds: builds || [],
    buildMeta,
    editAction: ownProps.edit
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Builds);