/*
_meta:
  loading: "request is in progress",
  ready: "object is ready to be used (but could be stale, loading in background, in a failed state)",
  error: ""

*/

import { getErrorMessage } from '../common/errors';


const loadTime = new Date().getTime();

export const meta = (state) => (state && state._meta) || { ready: false, loading: false};

export const isLoading = (state) => state && state._meta && state._meta.loading;
export const isReady = (state) => state && state._meta && state._meta.ready;
export const isRefresh = (state) => !!(state && state._meta && state._meta.refresh);

export const extract = (state, ...paths) => {
  if (!state) {
    return [state, stateUnknown()._meta]
  }
  let _meta = state._meta;
  let value = state;

  for (let p of paths) {
    let child = value[p];
    if (!child) {
      value = null;
      break;
    }
    if (child._meta) {
      _meta = child._meta;
    }
    value = child;
  }
  if (value && value._meta) {
    let {_meta: _ignore, ...newValue } = value;
    value = newValue;
  }

  return [value, _meta || stateUnknown()._meta];
}

export const isCachedData = (meta) => {
  const [lastUpdatedAt = null] = meta?.updatedAt || [];

  return lastUpdatedAt < loadTime;
}

export const shouldFetch = (state, path, opts ) => {
  const ttl = (opts && opts.ttl) || 30 * 1000;
  const [, md] = extract(state, ...path);
  if (md.refresh) {
    return true;
  }
  if (md.loading) {
    // the loading flag might be from an older session
    if (!md.updatedAt || md.updatedAt[1] !== loadTime) {
      return true;
    }
    return false;
  }
  if (md.error || !md.ready) {
    return true;
  }

  if (!md.updatedAt) {
    console.warn('No update times', path, md);
    // not times to compare
    return true;
  }
  const [ session, ts ] = md.updatedAt;
  // if the data was loaded in a previous session
  if (session !== loadTime) {
    return true;
  }

  return new Date().getTime() - ts > ttl;
}

const clearError = (_meta) => {
  if (!_meta) {
    return _meta;
  }
  const { error: _e,
    errorMessage: _em,
    errorCode: _ec,
    errorHelp: _eh, ...rest } = _meta;

  return rest;
}

export const stateUnknown = (meta) => { return { _meta: { refresh: false, ready: false, ...meta, loading: false }}}
export const requestStart = (meta) => { return { _meta: { refresh: false, ready: false, ...meta, loading: true, updatedAt:  [ new Date().getTime(), loadTime ] }}}
export const requestRefresh = (meta) => { return { _meta: { ...meta, refresh: true }}}
export const requestSuccess = (meta) => { return { _meta: { refresh: false, updatedAt:  [ new Date().getTime(), loadTime ], loading: false, ready: true, ...clearError(meta) }}}
export const requestFailure = (error, meta) => {
  const errorMessage = error.errorMessage ? error.errorMessage : getErrorMessage(error);
  return {
    _meta: {
      loading: false,
      ready: false,
      refresh: false,
      ...meta,
      error,
      errorMessage,
      errorCode: error.responseStatus || -1,
      errorHelp: error.errorHelp
    }
  }
}



export const getErrorCodeAndMessage = (objWithMeta) => {
  if (objWithMeta && objWithMeta._meta && objWithMeta._meta.error) {
    const {error, errorCode, errorMessage, errorHelp} = objWithMeta._meta;
    return {error, errorCode, errorMessage, errorHelp}
  }
  return false;
}