import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import { extract } from '../../store/meta';

const WithPermission = ({ permission, children, pass, projects }) => {
  const checkPermission = ({ match: { params: { projectId }}}) => {
    const [ project, state ] = extract(projects, projectId);
    if (!state.ready) {
      return null;
    }
    if (project.permissions[permission]) {
      if (pass) {
        return React.cloneElement(children, pass)
      }
      return children;
    }
    if (pass) {
      return children;
    }

    return null;
  }

  return <Route path='/:projectId/' render={checkPermission}/>
}

const mapStateToProps = (state) => {
  return { projects:  state.projects };
}

export default connect(mapStateToProps)(WithPermission);