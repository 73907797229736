import React, { useRef, useState, useEffect, useCallback } from "react";
import useComponentSize from "@rehooks/component-size";
import * as d3 from "d3";
import { formatMinutesToDaysHoursMinutesFormat, generateQuartersDataBetweenDates, getMovingAverage, formatMinutesToBusinessDays, formatBusinessDaysToMinutes, getMedianValue } from "common/utils";

const TimelineChart = ({ svgHeight = 350, data, xAxisLabel = '', yAxisLabel = '', infoDataElement, isTimelineChartBusiness = false }) => {
    const timelineChartContainerRef = useRef(null);
    const tooltipRef = useRef(null);
    const zoomRectRef = useRef(null);
    const linesAndAxisContainerRef = useRef(null);

    const averageLineColor = "#1e88e5";
    const movingAverageLineColor = "#880e4f";
    const continuosLineColor = "#81c784";
    const circleColor = '#ff8a65';
    const quarterLineColor = '#cfd8dc';
    const circleRadius = 3; // px

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const [averageValue, setAverageValue] = useState(undefined);
    const [minValue, setMinValue] = useState(undefined);
    const [maxValue, setMaxValue] = useState(undefined);
    const [medianValue, setMedianValue] = useState(undefined);

    const { width: svgWidth } = useComponentSize(timelineChartContainerRef);

    const margin = { top: 20, right: 80, bottom: 50, left: 80 };

    const hoverTimeoutId = useRef(null);

    useEffect(() => {
        setWidth(svgWidth - margin.left - margin.right);
        setHeight(svgHeight - margin.top - margin.bottom);
    }, [margin, svgWidth, svgHeight]);

    const getAxisTickValues = useCallback((axis, preferredTicksNumber = 8) => {
        let tickValues = axis.ticks(preferredTicksNumber);
        const oddOrEven = tickValues.length % 2;

        if (tickValues.length > 2) {
            const diffBetweenTicks = Number(tickValues[1]) - Number(tickValues[0]);
            const [domainMin, domainMax] = axis.domain();
            const diffToMin = Number(tickValues[0]) - Number(domainMin);
            if (diffToMin < diffBetweenTicks / 2) {
                tickValues.shift();
            }
            const diffToMax = Number(domainMax) - Number(tickValues[tickValues.length - 1]);
            if (diffToMax < diffBetweenTicks / 2) {
                tickValues.pop();
            }
        }

        if (tickValues.length > preferredTicksNumber) {
            tickValues = tickValues.filter((_, idx) => idx % 2 === oddOrEven);
        }

        return tickValues.concat(axis.domain());
    }, []);

    const getXAxisTickValues = useCallback((xAxis) => {
        return getAxisTickValues(xAxis);
    }, [getAxisTickValues]);

    const getYAxisTickValues = useCallback((yAxis) => {
        const ticks = getAxisTickValues(yAxis, 5);

        if (isTimelineChartBusiness) {
            return Array.from(new Set(ticks.map(formatMinutesToBusinessDays))).map(formatBusinessDaysToMinutes);
        }

        return ticks;
    }, [getAxisTickValues, isTimelineChartBusiness]);

    useEffect(() => {
        const averageData = data.reduce((pV, cV) => pV + cV.y, 0) / data.length;
        const medianValue = getMedianValue(data.map((v) => v.y));

        setAverageValue(averageData);
        setMedianValue(medianValue);
    }, [data]);

    useEffect(() => {
        const zoomRect = d3.select(zoomRectRef.current);
        const linesAndAxisContainer = d3.select(linesAndAxisContainerRef.current);

        if (!data || !data.length) {
            return;
        }

        const xDomain = d3.extent(data, d => new Date(d.x));
        const yDomain = d3.extent(d3.extent(data, d => d.y));

        setMinValue(yDomain[0]);
        setMaxValue(yDomain[1]);

        const generatedData = generateQuartersDataBetweenDates(xDomain[0], xDomain[1]);

        const quartersLineData = generatedData.map((quarterDate) => yDomain.map((y) => ({ x: quarterDate, y })));

        const getXDomain = () => {
            const tmpDomain = d3.scaleTime()
                .domain(xDomain)
                .range([0, width]);

            const circle = circleRadius * 2; // px

            return [tmpDomain.invert(-circle), tmpDomain.invert(width + circle)];
        };
        const x = d3.scaleTime()
            .domain(getXDomain())
            .range([0, width]);

        const getYDomain = () => {
            const tmpDomain = d3.scaleLinear()
                .domain(yDomain)
                .range([height, 0]);

            const circle = circleRadius * 2; // px

            const paddingLeft = yDomain[0] - (yDomain[1] - tmpDomain.invert(circle));
            const paddingRight = isTimelineChartBusiness ? formatBusinessDaysToMinutes(Math.ceil(yDomain[1] / 8 / 60)) : yDomain[1] + (yDomain[1] - tmpDomain.invert(circle));

            return [paddingLeft, paddingRight];
        };
        const y = d3.scaleLinear()
            .domain(getYDomain())
            .range([height, 0]);

        // Add the X Axis.
        const xFormat = (d) => `${d.toLocaleDateString()}`;
        const xAxis = d3
            .axisBottom(x)
            .tickValues(getXAxisTickValues(x))
            .tickFormat(xFormat);

        linesAndAxisContainer.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(xAxis)
            .attr("class", "axis axis--x")
            .selectAll("text")
                .attr("class", "mdc-typography--body2");

        // Add the Y Axis.
        const yFormat = (d) => {
            if (isTimelineChartBusiness) {
                return formatMinutesToBusinessDays(d);
            }

            return d;
        };
        const yAxis = d3
            .axisLeft(y)
            .tickValues(getYAxisTickValues(y))
            .tickFormat(yFormat);

        linesAndAxisContainer.append("g")
            .call(yAxis)
            .attr("class", "axis axis--y")
            .selectAll("text")
                .attr("class", "mdc-typography--body2");

        const line = d3.line()
            .x((d) => x(d.x))
            .y((d) => y(d.y));

        // Calculate average line data.
        const averageLineData = data.map(({ x }) => ({ x, y: averageValue }));

        // Append average data line.
        linesAndAxisContainer.append("path")
            .data([averageLineData])
            .attr("class", "lines-id-v1 csv-line-2")
            .attr("stroke", averageLineColor)
            .attr("d", line);

        const linePath = linesAndAxisContainer.append("path")
            .data([data])
            .attr("class", "lines-id-v1 csv-line-1")
            .attr("stroke", continuosLineColor)
            .attr("d", line);

        // Append quarters lines
        const quarterLines = quartersLineData.map((quarterLineData) => {
            const quarterLine = linesAndAxisContainer.append("path")
                .data([quarterLineData])
                .attr("class", "lines-id-v1 csv-line-1")
                .attr("stroke", quarterLineColor)
                .attr("stroke-dasharray", "10, 10")
                .attr("d", line);

            return quarterLine;
        });

        // Calculate moving average line data.
        const movingAverageLineData = getMovingAverage(data.map(({ y }) => y)).map((mAvg, i) => ({ x: data[i].x, y: mAvg }));

        // Append moving average data line.
        const movingAverageLine = linesAndAxisContainer.append("path")
            .data([movingAverageLineData])
            .attr("class", "lines-id-v1 csv-line-1")
            .attr("stroke", movingAverageLineColor)
            .attr("d", line);

        const handleZoom = () => {
            if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush

            const t = d3.event.transform;

            const newXScale = t.rescaleX(x);

            xAxis.tickValues(getXAxisTickValues(newXScale));

            linesAndAxisContainer.select(".axis--x")
                .call(xAxis.scale(newXScale))
                .selectAll("text")
                .attr("class", "mdc-typography--body2");

            circlesContainer
                .attr('cx', (d)=> newXScale(d.x))
                .attr('cy', (d) => y(d.y));

            linePath
                .attr("d", d3.line()
                    .x((d) => newXScale(d.x))
                    .y((d) => y(d.y)));

            quarterLines.forEach((line) => {
                line.attr("d", d3.line()
                    .x((d) => newXScale(d.x))
                    .y((d) => y(d.y)));
            });

            movingAverageLine
                .attr("d", d3.line()
                    .x((d) => newXScale(d.x))
                    .y((d) => y(d.y)));
        }

        const zoom = d3.zoom()
            .scaleExtent([1, Infinity])
            .translateExtent([[0, 0], [width, height]])
            .extent([[0, 0], [width, height]])
            .on('zoom', handleZoom);

        zoomRect
            .call(zoom);

        const circlesContainer = linesAndAxisContainer
            .append("g")
            .attr('class', 'circle-id-v1')
            .selectAll('.circle')
            .data(data)
            .enter()
                .append('circle')
                    .attr('r', circleRadius)
                    .attr('stroke-width', 2)
                    .attr('stroke', circleColor)
                    .attr('fill', 'white')
                    .attr('cx', (d) => x(d.x))
                    .attr('cy', (d) => y(d.y));

        circlesContainer
            .on("mouseover", (e) => {
                const currentIssueData = Object.entries(e.data)
                    .map(([key, value]) => {
                        const formatKey = (k) => k.split(/(?=[A-Z])/)
                            .map((keyPart, index) => index === 0 ? `${keyPart[0].toUpperCase()}${keyPart.slice(1).toLowerCase()}` : keyPart.toLowerCase())
                            .join(' ');

                        if (key === 'time') {
                            return `<b>${formatKey(key)}:</b> ${formatMinutesToDaysHoursMinutesFormat(value)}`;
                        }

                        return `<b>${formatKey(key)}:</b> ${value}`;
                    })
                    .join('<br />');

                tooltipRef.current.style.visibility = 'visible';
                tooltipRef.current.innerHTML = currentIssueData;
            })
            .on("mousemove", () => {
                tooltipRef.current.style.top = `${d3.event.pageY - 10}px`;
                tooltipRef.current.style.left = `${d3.event.pageX + 10}px`;
            })
            .on("mouseout", () => {
                hoverTimeoutId.current = setTimeout(() => {
                    tooltipRef.current.style.visibility = 'hidden';
                }, 500);
            });

        return () => {
            linesAndAxisContainer
                .selectAll("g")
                .remove();

            linesAndAxisContainer
                .selectAll("path")
                .remove();
        };
    }, [data, getXAxisTickValues, getYAxisTickValues, height, width, isTimelineChartBusiness, averageValue]);

    return (
        <div style={{ paddingTop: 16, paddingBottom: 16, scrollBehavior: 'none' }}>
            <div style={{ display: 'flex', gap: 16 }}>
                {infoDataElement ? infoDataElement(averageValue, data.length, minValue, maxValue, medianValue) : null}
            </div>
            <div className="timelinechart-container" ref={timelineChartContainerRef}>
                <svg width={svgWidth} height={svgHeight}>
                    <rect className="zoom" width={width} height={height} transform={`translate(${margin.left}, ${margin.top})`} ref={zoomRectRef} />
                    <g className='linesAndAxisContainer' transform={`translate(${margin.left}, ${margin.top})`} ref={linesAndAxisContainerRef}>
                        <text className='mdc-typography--body2' textAnchor='middle' transform={`translate(${width / 2}, ${svgHeight - margin.top})`}>{xAxisLabel}</text>
                        <text className='mdc-typography--body2' textAnchor='middle' transform={`rotate(-90) translate(-${(svgHeight - margin.top - margin.bottom) / 2}, -60)`}>{yAxisLabel}</text>
                    </g>
                    <defs><clipPath id="clip"><rect width={width} height={height}></rect></clipPath></defs>
                </svg>
            </div>
            <div
                className="timelinechart-tooltip"
                ref={tooltipRef}
                onMouseOver={() => {
                    clearTimeout(hoverTimeoutId.current);
                    hoverTimeoutId.current = null;
                }}
                onMouseOut={() => {
                    hoverTimeoutId.current = setTimeout(() => {
                        tooltipRef.current.style.visibility = 'hidden';
                    }, 500);
                }}
            />
        </div>
    );
};

export default TimelineChart;
