import React from 'react';
import { useDispatch } from 'react-redux';

import { Dialog, DialogTitle, DialogContent, DialogButton, DialogActions } from '@rmwc/dialog';

import { Button } from '@rmwc/button';

import 'components/Project/GeneralDialog.scss';

import { showNotification } from 'store/ui-actions';

import { Typography } from '@rmwc/typography';

import { getProjectDetails } from 'store/project-actions';

import * as projectsAPI from 'api/projects-api'

const ShareVersionDialog = ({ projectId, shareToken, isOpen, onClose, buildName, version }) => {
    const dispatcher = useDispatch()
    const link = `${window.location.href}?shared=${shareToken}`

    const handleCreateShareToken = async () => {
        try {
            await projectsAPI.createBuildVersionShareToken(projectId, buildName, version)
            dispatcher(getProjectDetails(projectId, true))
            dispatcher(showNotification({ message: 'Sharing with link enabled' }))
        } catch (e) {
            dispatcher(showNotification({ message: 'Could not enable link sharing' }))
        }
    }

    const handleRemoveShareToken = async () => {
        try {
            await projectsAPI.deleteShareToken(projectId, shareToken)
            dispatcher(showNotification({ message: 'Link sharing disabled' }))
            dispatcher(getProjectDetails(projectId, true))
        } catch (e) {
            dispatcher(showNotification({ message: 'Could not disable link sharing' }))
        }
    }

    const copyLink = async () => {
        await navigator.clipboard.writeText(link)
        dispatcher(showNotification({ message: 'Link copied to clipboard' }))
    }

    return (
        <Dialog className='general-dialog' open={isOpen} onClose={onClose}>
            <DialogTitle>Share with others
          <div className='dialog-right-top-header'>
                    <Button data-id='share-dialog-get-sharable-link' label='Get sharable link' trailingIcon='link' dense onClick={handleCreateShareToken} />
                </div>
            </DialogTitle>

            <DialogContent className='dialog-content' >
                <Typography className='permission-text' use='headline6'>Allows access only to <b>{buildName} - {version}</b> version</Typography>
                {shareToken && <div className='link-sharing'>
                    Anyone with the link can access (no login required)<br />
                    <div className='link-sharing-options'>
                        <Typography data-id='share-dialog-link' className='link-sharing-options-link' use='caption'>{link}</Typography>
                        <Button data-id='share-dialog-copy' className='link-sharing-options-button' icon='content_copy' label='Copy' onClick={copyLink} />
                        <Button className='link-sharing-options-button' icon='delete' label='Remove' onClick={handleRemoveShareToken} />
                    </div>
                </div>}
            </DialogContent>

            <DialogActions>
                <DialogButton data-id='share-dialog-close' action='close'>Close</DialogButton>
            </DialogActions>
        </Dialog>
    );
}

export default ShareVersionDialog;
