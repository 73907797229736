import { applyAuthorizationHeader } from './auth';
import config from 'config/firebaseConfig.js';


// for debugging async functions
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const FETCH_DEFS = {
  credentials: 'include'
}

export const POST = {
  method: 'POST'
}

export const POST_URL_ENCODED = {
  ...POST,
  'Content-Type': 'application/x-www-form-urlencoded',
}

export const PATCH_JSON = {
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json'
  }
}

export const POST_JSON = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  }
}

export const composeUrl = (path, fullPath) => {
  const url = `${config.API_BASE}${path}`;
  if (fullPath && !url.startsWith('http')) {
    return new URL(url, window.location.toString()).toString();
  }

  return url;
};

export function checkForOk(request) {
  if (!request.ok) {
    const error = new Error('Request failed');
    error.responseStatus = request.status;
    const contentType = request.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return request.json()
        .then((json) => {
          error.responseError = json.error;
          error.errorMessage = (json.error && json.error.message) || error.errorMessage;
          throw error
        });
    }
    throw error;
  }
  return request;
}


export const fetchWithAuth = async (url, opts) => {
  const {projectId, ...newOpts} = opts || {};
  newOpts.headers = new Headers(newOpts.headers);

  await applyAuthorizationHeader(projectId, url, newOpts.headers);
  let result1 = await fetch(url, newOpts);

  return await checkForOk(result1);
};
