import * as actions from 'store/diagnostics-actions';
import { createReducer } from "typesafe-actions";
import { requestFailure, requestStart, requestSuccess } from "store/meta";

const initialState = {
  sessions: {},
  sessionsMeta: {},
  latestDevicesContinuationTokens: {},
  searchContinuationTokens: {},
  queriesForSessions: {}, // represents the query string used to fetch a given session
  sessionLogs: {},
  sessionLogsMeta: {},
  liveLogsSessions: {},
  olderSessionLiveMeta: {},
  firstSessionLiveMeta: {},
  refreshSessionLiveMeta: {},
  deviceInfo: {},
  devicesSessions: {},
  devicesSessionsMeta: {},
  deviceStatistics: {},
  deviceStatisticsMeta: {},
};

const diagnosticsReducer = createReducer(initialState)
  // sessions
  .handleAction(actions.fetchSearchSessionsAsync.request, (state, { payload: { projectId } }) =>
    ({
      ...state,
      sessionsMeta: { ...state.sessionsMeta, [`${ projectId }`]: requestStart()._meta }
    }))
  .handleAction(actions.fetchSearchSessionsAsync.success,
    (
      state, 
      { payload: { projectId, sessions, searchContinuationToken, latestDevicesContinuationToken, fromStart, query, type } }
    ) => ({
      ...state,
      sessions: {
        ...state.sessions,
        [`${ projectId }`]: fromStart ?
          sessions :
          [
            ...state.sessions[`${ projectId }`] || [],
            ...sessions,
          ],
      },
      queriesForSessions: { ...state.queriesForSessions, [projectId]: query || type ? { query, type } : undefined },
      sessionsMeta: {
        ...state.sessionsMeta,
        [`${ projectId }`]: requestSuccess()._meta
      },
      latestDevicesContinuationTokens: {
        ...state.latestDevicesContinuationToken,
        [`${projectId}`]: latestDevicesContinuationToken
      },
      searchContinuationTokens: {
        ...state.searchContinuationTokens,
        [`${ projectId }`]: searchContinuationToken,
      },
    }))
  .handleAction(actions.fetchSearchSessionsAsync.failure, (state, { payload: { error, projectId } }) =>
    ({
      ...state,
      sessionsMeta: {
        ...state.sessionsMeta,
        [`${ projectId }`]: requestFailure(error)._meta
      }
    }))

  // device logs
  .handleAction(actions.fetchDeviceStatisticsAsync.request, (state, { payload: { projectId, deviceId } }) =>
    ({
      ...state,
      deviceStatisticsMeta: {
        ...state.deviceStatisticsMeta,
        [`${ projectId }-${ deviceId }`]: requestStart()._meta,
      },
    }))
  .handleAction(actions.fetchDeviceStatisticsAsync.success, (state, { payload: { projectId, deviceId, stats } }) =>
    ({
      ...state,
      deviceStatistics: {
        ...state.deviceStatistics,
        [`${ projectId }-${ deviceId }`]: stats,
      },
      deviceStatisticsMeta: {
        ...state.deviceStatisticsMeta,
        [`${ projectId }-${ deviceId }`]: requestSuccess()._meta,
      },
    }))
  .handleAction(actions.fetchDeviceStatisticsAsync.failure, (state, { payload: { error, projectId, deviceId } }) =>
    ({
      ...state,
      deviceStatisticsMeta: {
        ...state.deviceStatisticsMeta,
        [`${ projectId }-${ deviceId }`]: requestFailure(error)._meta,
      }
    }))

  // session logs
  .handleAction(actions.fetchSessionLogAsync.request, (state, { payload: { projectId, deviceId, sessionId } }) =>
    ({
      ...state,
      sessionLogsMeta: {
        ...state.sessionLogsMeta,
        [`${ projectId }-${ deviceId }-${ sessionId }`]: requestStart()._meta
      }
    }))
  .handleAction(actions.fetchSessionLogAsync.success, (state, { payload: { projectId, deviceId, sessionId, log } }) => {
    return {
      ...state,
      sessionLogs: { ...state.sessionLogs, [`${ projectId }-${ deviceId }-${ sessionId }`]: log },
      sessionLogsMeta: {
        ...state.sessionLogsMeta,
        [`${ projectId }-${ deviceId }-${ sessionId }`]: requestSuccess()._meta
      }
    };
  })
  .handleAction(actions.fetchSessionLogAsync.failure, (state, { payload: { error, projectId, deviceId, sessionId } }) => ({
    ...state,
    sessionLogsMeta: {
      ...state.sessionLogsMeta,
      [`${ projectId }-${ deviceId }-${ sessionId }`]: requestFailure(error)._meta
    }
  }))
  .handleAction(actions.fetchListOfDeviceSessionsAsync.request, (state, { payload: { projectId, deviceId } }) => ({
    ...state,
    devicesSessionsMeta: {
      ...state.devicesSessionsMeta,
      [projectId]: {
        ...state.devicesSessionsMeta[projectId],
        [deviceId]: requestStart()._meta
      }
    }
  }))

  // device info
  .handleAction(actions.fetchDeviceInfoAsync.success, (state, { payload: { projectId, deviceId, sessionId, deviceInfo } }) => {
    return ({
      ...state,
      deviceInfo: { ...state.deviceInfo, [`${ projectId }-${ deviceId }-${ sessionId }`]: deviceInfo || undefined },
    });
  })

  // device sessons
  .handleAction(actions.fetchListOfDeviceSessionsAsync.success, (state, { payload: { projectId, deviceId, sessions } }) => ({
    ...state,
    devicesSessions: {
      ...state.devicesSessions,
      [projectId]: {
        ...state.devicesSessions[projectId],
        [deviceId]: sessions && sessions.length ? sessions : state.devicesSessions[projectId][deviceId]
      }
    },
    devicesSessionsMeta: {
      ...state.devicesSessionsMeta,
      [projectId]: {
        ...state.devicesSessionsMeta[projectId],
        [deviceId]: requestSuccess()._meta
      }
    }
  }))
  .handleAction(actions.fetchListOfDeviceSessionsAsync.failure, (state, { payload: { projectId, deviceId, error } }) => ({
    ...state,
    devicesSessionsMeta: {
      ...state.devicesSessionsMeta,
      [projectId]: {
        ...state.devicesSessionsMeta[projectId],
        [deviceId]: requestFailure(error)._meta
      }
    }
  }))
  .handleAction(
    actions.fetchOlderSessionLiveDiagnosticsAsync.request,
    (state, { payload: { projectId, deviceId } }) => ({
      ...state,
      olderSessionLiveMeta: {
        ...state.olderSessionLiveMeta,
        [`${projectId}-${deviceId}`]: requestStart()._meta
      }
    })
  )
  .handleAction(
    actions.fetchOlderSessionLiveDiagnosticsAsync.success,
    (state, { payload: { projectId, deviceId, sessionId, log, deviceInfoData, sessionData, timestamp }}) => {
      const deviceLiveLogsSessions = state.liveLogsSessions[`${projectId}-${deviceId}`] ? state.liveLogsSessions[`${projectId}-${deviceId}`] : {};

      return {
        ...state,
        liveLogsSessions: {
          ...state.liveLogsSessions,
          [`${projectId}-${deviceId}`]: {
            ...deviceLiveLogsSessions,
            [`${sessionId}`]: {
              timestamp,
              log,
              deviceInfoData,
              sessionData
            }
          }
        },
        olderSessionLiveMeta: {
          ...state.olderSessionLiveMeta,
          [`${projectId}-${deviceId}`]: requestSuccess()._meta
        }
      };
    }
  )
  .handleAction(
    actions.fetchOlderSessionLiveDiagnosticsAsync.failure,
    (state, { payload: { error, projectId, deviceId }}) => ({
      ...state,
      olderSessionLiveMeta: {
        ...state.olderSessionLiveMeta,
        [`${projectId}-${deviceId}`]: requestFailure(error)._meta
      }
    })
  )
  .handleAction(
    actions.fetchFirstSessionLiveDiagnosticsAsync.request,
    (state, { payload: { projectId, deviceId } }) => ({
      ...state,
      firstSessionLiveMeta: {
        ...state.firstSessionLiveMeta,
        [`${projectId}-${deviceId}`]: requestStart()._meta
      }
    })
  )
  .handleAction(
    actions.fetchFirstSessionLiveDiagnosticsAsync.success,
    (state, { payload: { projectId, deviceId, sessionId, log, deviceInfoData, sessionData, timestamp }}) => ({
      ...state,
      liveLogsSessions: {
        ...state.liveLogsSessions,
        [`${projectId}-${deviceId}`]: {
          [`${sessionId}`]: {
            timestamp,
            log,
            deviceInfoData,
            sessionData
          }
        }
      },
      firstSessionLiveMeta: {
        ...state.firstSessionLiveMeta,
        [`${projectId}-${deviceId}`]: requestSuccess()._meta
      }
    })
  )
  .handleAction(
    actions.fetchFirstSessionLiveDiagnosticsAsync.failure,
    (state, { payload: { error, projectId, deviceId }}) => ({
      ...state,
      firstSessionLiveMeta: {
        ...state.firstSessionLiveMeta,
        [`${projectId}-${deviceId}`]: requestFailure(error)._meta
      }
    })
  )
  .handleAction(
    actions.fetchRefreshSessionLiveDiagnosticsAsync.request,
    (state, { payload: { projectId, deviceId } }) => ({
      ...state,
      refreshSessionLiveMeta: {
        ...state.refreshSessionLiveMeta,
        [`${projectId}-${deviceId}`]: requestStart()._meta
      }
    })
  )
  .handleAction(
    actions.fetchRefreshSessionLiveDiagnosticsAsync.success,
    (state, { payload: { projectId, deviceId, sessionId, log, deviceInfoData, sessionData, timestamp }}) => {
      const deviceLiveLogsSessions = state.liveLogsSessions[`${projectId}-${deviceId}`] ? state.liveLogsSessions[`${projectId}-${deviceId}`] : {};
      return {
        ...state,
        liveLogsSessions: {
          ...state.liveLogsSessions,
          [`${projectId}-${deviceId}`]: {
            ...deviceLiveLogsSessions,
            [`${sessionId}`]: {
              timestamp,
              log,
              deviceInfoData,
              sessionData
            }
          }
        },
        refreshSessionLiveMeta: {
          ...state.refreshSessionLiveMeta,
          [`${projectId}-${deviceId}`]: requestSuccess()._meta
        }
      };
    }
  )
  .handleAction(
    actions.fetchRefreshSessionLiveDiagnosticsAsync.failure,
    (state, { payload: { error, projectId, deviceId }}) => ({
      ...state,
      refreshSessionLiveMeta: {
        ...state.refreshSessionLiveMeta,
        [`${projectId}-${deviceId}`]: requestFailure(error)._meta
      }
    })
  );


export default diagnosticsReducer;