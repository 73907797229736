
export const TOGGLE_SIDE_MENU = 'TOGGLE_SIDE_MENU';
export const CLOSE_SIDE_MENU = 'CLOSE_SIDE_MENU';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const FOOTER_VISIBILITY = 'FOOTER_VISIBILITY';


export const toggleSideMenu = () => (dispatch) => {
  dispatch({ type: TOGGLE_SIDE_MENU} );
}

export const closeSideMenu = () => (dispatch) => {
  dispatch({ type: CLOSE_SIDE_MENU});
}

export const showNotification = (message) => (dispatch) => {
  dispatch({ type: CLEAR_NOTIFICATION, payload:message})
  dispatch({ type: SHOW_NOTIFICATION, payload:message})
}

export const clearNotification = (message) => (dispatch) => {
  dispatch({ type: CLEAR_NOTIFICATION, payload:message})
}

export const showFooter = (show) => (dispatch) => {
  dispatch({ type: FOOTER_VISIBILITY, payload: show });
}