import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import { showNotification } from '../../store/ui-actions';

import KeysTabTable from 'components/Settings/KeysTabTable'

const projectsAPI = require('../../api/projects-api');
const errors = require('../../common/errors');

const Keys = (props) => {
  const [keys, setKeys] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false)

  const projectId = props.match.params.projectId;

  const fourLetters = ({ id: text }) => text.substring(0, text.length - 4).split('').map(() =>  "*").join('') + text.substring(text.length - 4);

  const createKey = () => {
    projectsAPI.createProjectKeys(projectId)
      .then(() => setRefresh(!refresh))
      .catch((err) => props.showNotification({ message: errors.getErrorMessage(err) } ));
  }

  const deleteKey = (keyID) => {
    projectsAPI.deleteProjectKey(projectId, keyID)
      .then(() => { setRefresh(!refresh); props.showNotification({ 'message': 'Key successfully deleted' }) })
      .catch((err) => props.showNotification({ message: errors.getErrorMessage(err) } ));
  }

  useEffect(() => {
    const fetchKeys = async () => {
      try {
        setLoading(true);
        setError(null);
        // not using global state as a precaution
        const data = await projectsAPI.getProjectKeys(projectId)

        setKeys(data.keys);
      } catch (err) {
        setError(errors.getErrorMessage(err));
      }
      setLoading(false);
    }
    fetchKeys();
  }, [projectId, refresh])

  const copyKey = ({ id }) => navigator.clipboard.writeText(id).then(() => props.showNotification({ message: 'Key copied to clipboard' }));

  return <KeysTabTable keys={keys} idFormatter={fourLetters} handleDeleteKey={deleteKey} handleCreateKey={createKey} copyKey={copyKey} error={error} loading={loading} />
}

export default connect(null, { showNotification })(Keys);

