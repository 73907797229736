import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { selectLatestSessions, selectLatestSessionsMeta, selectQueryForProject } from "store/diagnostics-selectors";


export const shouldShowDiagnosticsTab = (sessionsMeta, sessions, searchQuery) => {
    return (
      (!sessionsMeta?.error  || (sessionsMeta?.error && sessionsMeta?.error.responseStatus !== 403))
      && !!sessions?.length
    )
    || !!searchQuery;
}

export const useShouldShowDiagnosticsTab = (projectId) => {
    const [showDiagnostics, setShowDiagnostics] = useState(false);

    const sessions = useSelector((state) => selectLatestSessions(state, projectId));
    const sessionsMeta = useSelector((state) => selectLatestSessionsMeta(state, projectId));
    const searchQuery = useSelector((state) => selectQueryForProject(state, projectId));

    useEffect(() => {
        if (shouldShowDiagnosticsTab(sessionsMeta, sessions, searchQuery)) {
            setShowDiagnostics(true);
        } else {
            setShowDiagnostics(false);
        }
    }, [sessionsMeta, sessions, searchQuery]);

    return { showDiagnostics };
};
