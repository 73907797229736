import React, { Component } from "react";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";

import {Tab, TabBar} from '@rmwc/tabs';

import Members from './Members';
import Keys from './Keys';
import ShareTokens from './ShareTokens';
import GeneralSettings from './GeneralSettings';
import LiveLogs from './LiveLogs';


import { getProjectDetails,getProjectMembers } from '../../store/project-actions';
import { connect } from 'react-redux';

import './settings.scss';


class Settings extends Component {
  constructor(props) {
    super(props);
    this.projectId = props.match.params.projectId;
    this.props = props;
    this.state = {
      activeTabIndex : 0
    }
  }

  componentDidMount() {
    this.props.getProjectDetails(this.projectId);
    this.updateActiveTab();
  }

  componentDidUpdate() {
    this.updateActiveTab();
  }

  updateActiveTab() {
    const pathname = window.location.pathname;
    let activeTabIndex = 0;
    if (pathname.endsWith('members')) {
      activeTabIndex = 1;
    } else if (pathname.endsWith('keys')) {
      activeTabIndex = 2;
    } else if (pathname.endsWith('shares')) {
      activeTabIndex = 3;
    } else if (pathname.endsWith('live-logs')) {
      activeTabIndex = 4;
    }
    if (activeTabIndex !== this.state.activeTabIndex) {
      this.setState({ activeTabIndex });
    }
  }

  render() {
    const { url, path } = this.props.match;
    return (
      <div className='settings-page'>
        <h1> Settings</h1>
        <TabBar activeTabIndex={this.state.activeTabIndex}>

          <Tab tag={NavLink} to={`${url}/general`}>
            General
          </Tab>
          <Tab tag={NavLink} to={`${url}/members`}>
            Users and permissions
          </Tab>
          <Tab tag={NavLink} to={`${url}/keys`}>
            Keys
          </Tab>
          <Tab tag={NavLink} to={`${url}/shares`}>
            Shares
          </Tab>
          <Tab tag={NavLink} to={`${url}/live-logs`}>
            Live logs
          </Tab>
        </TabBar>

        <Switch>
          <Route path={`${path}members`} exact>
            <Members projectId={this.projectId}/>
          </Route>
          <Route path={`${path}general`} exact>
            <GeneralSettings projectId={this.projectId} projectName={this.props.name} permissions={this.props.permissions}/>
          </Route>
          <Route path={`${path}keys`} exact component={Keys} />
          <Route path={`${path}shares`} exact >
            <ShareTokens projectId={this.projectId}/>
          </Route>
          <Route path={`${path}live-logs`} exact>
            <LiveLogs projectId={this.projectId} projectName={this.props.name}/>
          </Route>

          <Redirect to='./general'/>
        </Switch>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const projectId = ownProps.match.params.projectId;
  const projects = state.projects;
  const ret = projects[projectId] || {};
  return ret;
}

const mapDispatchToProps = {getProjectDetails, getProjectMembers};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);