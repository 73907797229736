import React, { useEffect, useRef, useState, useMemo, createRef, useCallback } from 'react';
import cn from "classnames";
import * as moment from 'moment';
import { debounce } from "lodash";

import { Chip } from "@rmwc/chip";
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableCell,
  DataTableHeadCell
} from '@rmwc/data-table';

import css from 'components/Project/Diagnostics/Session/DataTable/LogDataTable.module.scss';

const LogRow = React.forwardRef(({log, index, className, sessionData, onClick, separator}, ref) => {
  // Start and end row for sessions
  if (separator) {
    return <DataTableRow ref={ref} className={ className } onClick={() => onClick?.(null, null, sessionData)}>
      <DataTableCell className={ cn(css.separatorCell, css.separatorTime) }> { moment(log['local_time']).format('HH:mm:ss') } </DataTableCell>
      <DataTableCell colSpan={2} className={ cn(css.separatorCell, css.separatorMessage) }> { log.message } </DataTableCell>
    </DataTableRow>
  }

  // Actual row with data
  const shortTime = moment(log['local_time']).format('HH:mm:ss');

  return (
    <DataTableRow
      ref={ref}
      onClick={() => onClick?.(log, index, sessionData)}
      className={ cn(css.logRow, className, {
        [css.warning]: log.severity === "warning",
        [css.error]: log.severity === "error",
      }) }>
      <DataTableCell className={css.logCell }> 
        { shortTime }
      </DataTableCell>
      <DataTableCell className={css.logCell }> 
         { log.tag ? <Chip className={ css.logChip } label={log.tag} /> : '' }
      </DataTableCell>
      <DataTableCell className={css.logCell }> 
        { log.message }
      </DataTableCell>
    </DataTableRow>
  );
});

export default function LogDataTable(props) {
  const {
    data,
    liveLogs,
    onLogRowClick,
    highlightedIndex,
    onScroll } = props;

  const [rows, setRows] = useState([]);
  const [elRefs, setElRefs] = React.useState([]);
  const [currentRowDate, setCurrentRowDate] = useState('');

  const logDataTableScrollHeight = useRef(0);
  const previousScrollPosition = useRef(0);
  const previousScrollHeight = useRef(0);
  const previousElementHeight = useRef(0);
  const logDataTableRef = useRef(null);

  const rowClick = useCallback((log, index, sessionData) => {
    if (log && log['local_time']) {
      const shortTime = moment(log['local_time']).format('MMM DD, YYYY');
      setCurrentRowDate(shortTime);
    } else if (sessionData && sessionData.reportDate) {
      const shortTime = moment(sessionData.reportDate).format('MMM DD, YYYY');
      setCurrentRowDate(shortTime);
    }

    onLogRowClick(log, index, sessionData);
  }, [onLogRowClick])

  useEffect(() => {
    const newRows = data.reduce((acc, session, index) => {
      const startDate = session.deviceInfo && session.deviceInfo.reportDate;
      const endDate = session.data && session.data.length ? session.data[session.data.length - 1]['local_time'] : startDate;
      const isLastSession = Number(index) === Number(data.length) - 1;

      acc.push({
        log: {
          local_time: startDate,
          message: 'Session started'
        },
        sessionData: session.deviceInfo,
        className: css.tableSessionStartRow,
        onClick: rowClick,
        separator: true
      });

      if (session.data) {
        acc = acc.concat(session.data.map((log) => ({
          log: log,
          sessionData: session.deviceInfo,
          onClick: rowClick
        })));
      }

      if (!liveLogs || (liveLogs && !isLastSession))  {
        acc.push({
          log: {
            local_time: endDate,
            message: 'Session ended',
          },
          sessionData: session.deviceInfo,
          className: css.tableSessionEndRow,
          onClick: rowClick,
          separator: true
        });
      }

      return acc;
    }, []);

    setRows(newRows)
  }, [liveLogs, data, onLogRowClick, rowClick]);


  // Render log rows\
  let allRows = useMemo(() => {
    const refs = Array(rows.length).fill().map((data, index) => ({
      ref: createRef(),
      data: rows[index].log
    }));
    const logRows = rows.map((data, index) => (
      <LogRow
        ref={refs[index].ref}
        key={index}
        log={data.log}
        separator={data.separator}
        index={index}
        sessionData={data.sessionData}
        className={data.className}
        onClick={data.onClick}/>
    ));
    setElRefs(refs);
    return logRows;
  }, [rows]);

  if (highlightedIndex) {
    const highlightedRow = <LogRow
      key={highlightedIndex}
      log={rows[highlightedIndex].log}
      index={highlightedIndex}
      className={ css.active }
      onClick={() => rows[highlightedIndex].onClick?.(null, null)}/>

    allRows = [
      ...allRows.slice(0, highlightedIndex),
      highlightedRow,
      ...allRows.slice(highlightedIndex + 1),
    ];
  }

  useEffect(() => {
    if (!liveLogs || !logDataTableRef.current) {
      return;
    }

    const scrollHeight = logDataTableRef.current.scrollHeight;
    const scrollTop = logDataTableRef.current.scrollTop;
    const clientHeight = logDataTableRef.current.clientHeight;

    if (
      logDataTableScrollHeight.current !== scrollHeight
      || ( scrollTop === 0 && scrollHeight === clientHeight )
    ) {
      // Calculate difference in scroll height before and now.
      let difference = scrollHeight - logDataTableScrollHeight.current;
      difference = difference < 0 ? 0 : difference;

      const isBottom = previousScrollHeight.current - (previousScrollPosition.current + previousElementHeight.current) < 5;
      const scrollPosition = scrollTop + difference;

      logDataTableScrollHeight.current = scrollHeight;
        
      // Set current scroll position based on condition
      if (isBottom) {
        logDataTableRef.current.scrollTop = scrollHeight;
      } else if (previousScrollPosition.current < 10) {
        logDataTableRef.current.scrollTop = scrollPosition;
      }

      // Load new logs when data from one session cannot fill whole height.
      if (clientHeight === scrollHeight && scrollTop === 0) {
        onScroll && onScroll(0);
      }
    }
  }, [allRows, liveLogs, onScroll]);

  const calculateScrollDebounce = debounce((e) => {
    if (e && e.target && e.target.id === 'main-scroll') {
      const scrollTop = e.target.scrollTop;
      let highlightedElement;

      for (const el of elRefs) {
        if (el.ref.current && !!el.ref.current.offsetTop) {
          highlightedElement = el;
        }
  
        if (el.ref.current && el.ref.current.offsetTop > scrollTop) {
          break;
        }
      }

      if (highlightedElement && highlightedElement.data && highlightedElement.data['local_time']) {
        const shortTime = moment(highlightedElement.data['local_time']).format('MMM DD, YYYY');
        setCurrentRowDate(shortTime);
      }

      previousScrollPosition.current = scrollTop;
      previousScrollHeight.current = logDataTableRef.current && logDataTableRef.current.scrollHeight;
      previousElementHeight.current = logDataTableRef.current && logDataTableRef.current.clientHeight;
      onScroll && onScroll(scrollTop);
    }
  }, 200);

  const handleScroll = (e) => {
    e.persist();
    calculateScrollDebounce(e);
  };

  return (
    <DataTable
      id="main-scroll"
      ref={logDataTableRef}
      onScroll={(e) => handleScroll(e)}
      className={css.table}>
              
      <DataTableContent className={ css.tableContent }>
        <DataTableHead>
          <DataTableRow>
            <DataTableHeadCell className={ cn(css.headCell, css.stickyRow1) }>Time</DataTableHeadCell>
            <DataTableHeadCell className={ cn(css.headCell, css.stickyRow1) }>Tag</DataTableHeadCell>
            <DataTableHeadCell className={ cn(css.headCell, css.stickyRow1) }>Message</DataTableHeadCell>
          </DataTableRow>
          <DataTableRow>
            <DataTableHeadCell className={ cn(css.headCell, css.stickyRow2) }>{currentRowDate}</DataTableHeadCell>
            <DataTableHeadCell className={ cn(css.headCell, css.stickyRow2) }></DataTableHeadCell>
            <DataTableHeadCell className={ cn(css.headCell, css.stickyRow2) }></DataTableHeadCell>
          </DataTableRow>
        </DataTableHead>
        <DataTableBody>
          { allRows }
        </DataTableBody>
      </DataTableContent>
    </DataTable>
  );
}

