import React, { useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux'
import { extract } from 'store/meta'

import KeysTabTable from 'components/Settings/KeysTabTable'
import { showNotification } from "store/ui-actions";
import * as projectsAPI from "api/projects-api";
import { getProjectDetails } from "store/project-actions";

const ShareTokens = ({ projectId }) => {
    const dispatch = useDispatch()
    const [shareTokens, meta] = useSelector(store => extract(store, 'projects', projectId, 'shareTokens'))
    if (shareTokens) {
        shareTokens.map(it => { it.id = it.token; return it })
    }

    useEffect(() => {
        dispatch(getProjectDetails(projectId, true))
    }, [projectId, dispatch])

    const handleDeleteKey = async (keyId) => {
        dispatch(showNotification({ message: 'Link sharing disabled' }))
        try {
            await projectsAPI.deleteShareToken(projectId, keyId)
            dispatch(getProjectDetails(projectId, true))
        } catch (e) {
            dispatch(showNotification({ message: 'Could not disable link sharing' }))
        }
    }

    const getCopyUrl = (key) => {
        const host = window.location.href.replace(new URL(window.location.href).pathname, '')
        const url = `${host}/${projectId}${key.paths[0] || ''}?shared=${key.token}`
        return url
    }
    
    const copyKey = (key) => {
        navigator.clipboard.writeText(getCopyUrl(key)).then(() => dispatch(showNotification({ message: 'Key copied to clipboard' })))
    }

    return <KeysTabTable keys={shareTokens} handleDeleteKey={handleDeleteKey} error={meta.error} loading={meta.loading} copyKey={copyKey} getCopyUrl={getCopyUrl} />
}

export default ShareTokens