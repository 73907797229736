import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadDeviceStatistics } from "store/diagnostics-actions";
import { selectDeviceStatistics, selectDeviceStatisticsMeta } from "store/diagnostics-selectors";
import { LinearProgress } from '@rmwc/linear-progress';
import { Link } from "react-router-dom";
import Error from "components/common/Error";

import { Grid, GridCell, GridRow } from "@rmwc/grid";
import { Typography } from '@rmwc/typography';

import Breadcrumbs from "components/Project/Diagnostics/Breadcrumbs/Breadcrumbs";
import DeviceInfoGraph from "components/Project/Diagnostics/Statistics/DeviceInfoGraph/DeviceInfoGraph";
import { deviceInfoTableMapping } from "components/Project/Diagnostics/Statistics/DeviceInfoGraph/Helpers";

import css from "./Statistics.module.scss";

const DeviceStatisticsGraph = (props) => {
  const { projectId, deviceId } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDeviceStatistics(projectId, deviceId));
  }, [dispatch, projectId, deviceId]);

  const statistics = useSelector((state) => selectDeviceStatistics(state, projectId, deviceId));
  const meta = useSelector((state) => selectDeviceStatisticsMeta(state, projectId, deviceId)) || {};

  const combinedLogs = useMemo(() =>
    statistics?.reduce((acc, stat) => {
      acc.push(...stat.logs);
      return acc;
    }, []) || [],
    [statistics]);


  const sessionStartPositions = useMemo(() =>
    statistics
      ?.reduce((acc, stat) => {
        acc.push(stat.logs[0]?.ts);
        return acc;
      }, [])
      .filter(v => v != null) || [],
    [statistics]);

  if (meta.error) {
    return <Error error={ meta.error }/>;
  } else if (!meta.ready) {
    return <LinearProgress/>;
  }

  const graphData = deviceInfoTableMapping.map((map, index) =>
    ({
      label: map.label,
      values: combinedLogs.map(row => ({ x: row.local_time, y: row[map.prop] })),
      valueRange: map.valueRange,
      color: map.color,
      transform: map.transform,
    }));

  const deviceName = statistics[0]?.deviceName || "unknown device";

  return (
    <Grid style={ { padding: 0 } }>
      <GridRow>
        <GridCell span={ 12 }>
          <Breadcrumbs typographyProps={ { use: "subtitle1" } } parts={ [
            <Link to={ `/${ projectId }/diagnostics/` }>Diagnostics</Link>,
            <Link to={ `/${ projectId }/diagnostics/${ deviceId }` }>{ deviceName }</Link>,
            <span>statistics</span>,
          ] }/>
        </GridCell>
      </GridRow>

      <GridRow>
        <GridCell span={ 12 }>
          { graphData.map(d =>
            <div key={ d.label } className={ css.container }>
              <Typography className={ css.label } use="headline5">{ d.label }</Typography>
              <DeviceInfoGraph
                className={ css.graph }
                data={ [d] }
                graphProps={ { verticalLines: sessionStartPositions } }
              />
            </div>
          ) }
        </GridCell>
      </GridRow>
    </Grid>
  );
};


export default DeviceStatisticsGraph;