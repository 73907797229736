import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { createEmailAccount } from '../../store/auth-actions';

import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';

import { CircularProgress } from '@rmwc/circular-progress';

import * as firebaseui from 'firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { auth } from 'components/common/Firebase';


import './invited.scss'

const MIN_PASSWORD_LENGTH = 8;

const firebaseUIConfig = {
  signInSuccessUrl: '/',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  signInFlow: 'popup',
  signInOptions: [
    auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
  }
};


const Invited = ({ authenticated, ...props }) => {
  const projectId = props.match.params.projectId;
  const search = window.location.search;

  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(search.substring(1));
    if (params.get('email')) {
      setEmail(params.get('email'));
    }
  }, [search])

  const [password, setPassword] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false);

  const [displayName, setDisplayName] = useState('');
  const [displayNameInvalid, setDisplayNameInvalid] = useState('');

  const [creatingAccount, setCreatingAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const setAutoComplete = (value) => {
    return (ref) => { if (ref) ref.setAttribute('autocomplete', value) };
  }
  const setPasswordRef = setAutoComplete('new-password');
  const setUserRef = setAutoComplete('usename');
  const setNameRef = setAutoComplete('name');

  const createEmailAccount = () => {
    setPasswordInvalid(false);
    setEmailInvalid(false);
    setDisplayNameInvalid(false);
    setErrorMessage(null);

    const pass = password.trim();
    let allOk = true;
    if (pass.length < MIN_PASSWORD_LENGTH) {
      setPasswordInvalid(`Your password should have at least ${MIN_PASSWORD_LENGTH} characters`);
      allOk = false;
    }
    if (email.indexOf('@') <= 0) {
      setEmailInvalid('Hm, this does not appear a valid email');
      allOk = false;
    }
    if (displayName.trim().length === 0) {
      setDisplayNameInvalid('Please enter a you name');
      allOk = false;
    }

    if (allOk) {
      setCreatingAccount(true);
      props.createEmailAccount({ password, email, displayName })
        .then((result) => {
          if (result.error) {

            setErrorMessage(result.errorMessage);
          }
          setCreatingAccount(false);
        })
    }
  }

  if (authenticated) {
    return (<Redirect to={`/${projectId}/acceptInvite${search}`}/>);
  }

  return (
    <div className='create-account-panel main-panel '>
      <Typography use='headline4' className='login-welcome-title'>Log in or create an account to continue.</Typography>
      {errorMessage && <Typography use='caption' className='invalid-field-caption'>{errorMessage}</Typography>}
      <div className='create-account-panel-options'>
        <StyledFirebaseAuth uiConfig={firebaseUIConfig} firebaseAuth={auth()} sign/>
        <p>
          or
        </p>
        <form>
          <TextField outlined label='Your name' inputRef={setNameRef} value={displayName} onChange={(e) => setDisplayName(e.currentTarget.value)} invalid={displayNameInvalid} />
          {displayNameInvalid && <Typography use='caption' className='invalid-field-caption'>{displayNameInvalid}</Typography>}

          <TextField outlined type='email' label='Email' inputRef={setUserRef} value={email} onChange={(e) => setEmail(e.currentTarget.value)} invalid={emailInvalid} />
          {emailInvalid && <Typography use='caption' className='invalid-field-caption'>{emailInvalid}</Typography>}

          <TextField outlined type='password' label='Password' inputRef={setPasswordRef} value={password} onChange={(e) => setPassword(e.currentTarget.value)} />
          {passwordInvalid && <Typography use='caption' className='invalid-field-caption'>{passwordInvalid}</Typography>}
        </form>
        {!creatingAccount && <Button raised label='Create account' onClick={createEmailAccount} />}
        {creatingAccount && <Button raised icon={<CircularProgress theme='onPrimary'/>} /> }
        <Typography use='caption' tag='p'>
          Already have an an account? <Link to={`/login${search}`}>Log in</Link>
        </Typography>

        <Typography use='caption'>To make Spotlight work, we log user data for internal processing only. Click “Sign In” above to accept the Terms of Service & Privacy Policy.</Typography>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state.auth
  }
}

export default connect(mapStateToProps, { createEmailAccount })(Invited);