const ALL_PERMISSIONS = {
  share: {
    capability: 'Share project'
  },
  listMembers: {
    capability: 'List project members'
  },
  addMember: {
    capability: 'Invite members'
  },
  delete:  {
    capability: 'Delete project'
  },
  upload: {
    capability: 'Upload builds and content'
  },
  manageKeys: {
    capability: 'Add and remove project API keys'
  }
}


export function describePermissions(key) {
  const descriptions = Object.keys(key.permissions)
    .filter((perm) => key.permissions[perm] && ALL_PERMISSIONS[perm])
    .map((perm) => ALL_PERMISSIONS[perm].capability);

  if (key.paths && !key.paths.includes('/')) {
    descriptions.push('Read only for: ')
    key.paths.forEach(it => descriptions.push(`- ${it}`))
  }

  if (descriptions.length === 0) {
    descriptions.push('Read only');
  }
  return descriptions;
}


export function describePermissionsAsSentence(permissions) {
  const descriptions = describePermissions(permissions);
  const first = descriptions[0];
  descriptions[0] = first.charAt(0).toUpperCase() + first.slice(1);

  if (descriptions.length === 1) {
    return descriptions[0];
  }

  const last = descriptions.pop();
  return `${descriptions.join(',')} and ${last}`;
}