import React from 'react';
import { connect } from 'react-redux';

import { TabBar, Tab } from '@rmwc/tabs'

import { withRouter, matchPath, useHistory } from 'react-router-dom';
import { getProjectTabs } from './projects-utils';

import { extract } from '../../store/meta';
import { copySearchParams } from '../../common/utils';
import { selectLatestSessions, selectLatestSessionsMeta, selectQueryForProject } from "store/diagnostics-selectors";
import { shouldShowDiagnosticsTab } from 'components/Project/Diagnostics/Helper';

const ProjectNavBar = ({ tabs, ready }) => {
  const history = useHistory();

  if (!ready || tabs.length <= 1) {
    return null;
  }


  const switchTab = (tab) => {
    const link = copySearchParams(tab.path.path, window.location.search, ['lat']);
    history.push(link);
  }

  return <TabBar activeTabIndex={tabs.selectedIndex}>
    { tabs.map((tab) =>
      <Tab data-id={`tab-${tab.name}`} minWidth key={tab.name} onInteraction={() => switchTab(tab)}>
        {tab.name}
      </Tab>
    )}
  </TabBar>
}

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps.match.params;
  const [ project, meta ] = extract(state.projects, projectId);

  if (!meta.ready) {
    return { projectId, ready: false }
  }

  const [builds ] = extract(state.builds, projectId, 'builds');
  const [files ] = extract(state.files, projectId, 'files');
  const showDiagnostics = shouldShowDiagnosticsTab(
    selectLatestSessionsMeta(state, projectId),
    selectLatestSessions(state, projectId),
    selectQueryForProject(state, projectId)
  );

  const tabs = getProjectTabs(project, files || [], builds || [], showDiagnostics);
  tabs.selectedIndex = tabs.findIndex((tab) => matchPath(window.location.pathname, tab.path));

  return { projectId,
    ...project,
    tabs,
    ready:true };
}

export default withRouter(connect(mapStateToProps)(ProjectNavBar));


