import React from "react";

import { Button } from '@rmwc/button';

import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';
import { Typography } from '@rmwc/typography'
import { version } from '../../package.json';

const welcomeMessage = `
  # Welcome

  - Install your app straight from the oven
  - Check on progress and stay up to date
`

const Welcome = () =>
  <div>
    <Markdown source={welcomeMessage} />
    <Button data-id='sign-in' tag={Link} to={`/login?url=${window.location.pathname + window.location.search}`}>Sign in or create an account</Button>
    
    <div style={{ position:"absolute", bottom: 0, right: 0, display: "flex", justifyContent: "flex-end", margin: "2rem"}}>
      <Typography use="subtitle1">v{version} - {process.env.REACT_APP_BUILD_TAG}</Typography>
    </div>
  </div>

export default Welcome;