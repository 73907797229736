import * as React from "react";
import PropTypes from 'prop-types';
import { useEffect, useRef, useCallback, useMemo, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { LinearProgress } from "@rmwc/linear-progress";

import { selectDeviceSessions, selectDeviceSessionsMeta,
  selectLiveLogsSessions, selectOlderSessionLiveMeta,
  selectFirstSessionLiveMeta, selectRefreshSessionLiveMeta } from "store/diagnostics-selectors";
import { loadListOfDeviceSessions, fetchOlderSessionForLiveDiagnostics,
  fetchFirstSessionLiveDiagnostics, refreshSessionLiveDiagnostics } from "store/diagnostics-actions";
import SessionLogDataTable from "components/Project/Diagnostics/Session/DataTable/SessionLogDataTable";
import SessionBreadcrumbs from "components/Project/Diagnostics/Breadcrumbs/SessionBreadcrumbs";
import { showFooter } from 'store/ui-actions';
import * as diagnosticsApi from "api/diagnostics-api";

import css from './LatestLiveDeviceSession.module.scss';

const LatestLiveDeviceSession = (props) => {
  let { projectId, deviceId } = props;

  const [sessionData, setSessionData] = useState(null);

  const dispatch = useDispatch();

  const sentRequestDeviceSessionsRef = useRef(false);
  const sentRequestLatestSessionRef = useRef(false);
  const deviceSessionIndexRef = useRef(1);
  const messageHistory = useRef([]);

  const deviceSessionsMeta = useSelector((state) => selectDeviceSessionsMeta(state, projectId, deviceId));
  const deviceSessions = useSelector((state) => selectDeviceSessions(state, projectId, deviceId) || []);

  const deviceSessionsLength = deviceSessions.length;
  const sessionId = deviceSessionsLength ? deviceSessions[0].sessionId : undefined;
  const session = deviceSessionsLength ? deviceSessions[0] : undefined;

  const liveLogs = useSelector((state) => selectLiveLogsSessions(state, projectId, deviceId) || {});
  const firstSessionLiveMeta = useSelector((state) => selectFirstSessionLiveMeta(state, projectId, deviceId) || {});
  const olderSessionLiveMeta = useSelector((state) => selectOlderSessionLiveMeta(state, projectId, deviceId) || {});
  const refreshSessionLiveMeta = useSelector((state) => selectRefreshSessionLiveMeta(state, projectId, deviceId) || {});

  const isLoadingLatestSessions = !deviceSessionsMeta || deviceSessionsMeta.loading;
  const isLoadingFirstSession = !firstSessionLiveMeta || firstSessionLiveMeta.loading;
  const isLoadingRefreshSession = !refreshSessionLiveMeta || refreshSessionLiveMeta.loading;
  const isLoadingOlderSession = !olderSessionLiveMeta || olderSessionLiveMeta.loading;

  // Initialize firebase authentication to access document changes
  const getSocketURL = useCallback(async () => {
    const { url } = await diagnosticsApi.getWsUrlForLiveLogs(projectId, deviceId)
    return url;
  }, [deviceId, projectId]);

  const {
    lastMessage,
    readyState,
  } = useWebSocket(getSocketURL, {
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
  });

  messageHistory.current = useMemo(() => {
    if (lastMessage) {
      return messageHistory.current.concat(lastMessage);
    } else {
      return messageHistory.current;
    }
  }, [lastMessage]);

  useEffect(() => {
    const connectionStatus = {
      [ReadyState.CONNECTING]: 'Connecting',
      [ReadyState.OPEN]: 'Open',
      [ReadyState.CLOSING]: 'Closing',
      [ReadyState.CLOSED]: 'Closed',
      [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    console.log('ws status: ', connectionStatus);
  }, [readyState]);

 // Refresh session if new logs were added
  const refreshLiveSession = useCallback((newSessionId) => {
    const exists = deviceSessions.some((obj) => obj.sessionId === newSessionId);
    if (exists) {
      dispatch(refreshSessionLiveDiagnostics(projectId, deviceId, newSessionId));
    } else {
      // First get all sessions and then retrieve logs.
      dispatch(loadListOfDeviceSessions(projectId, deviceId));
      sentRequestLatestSessionRef.current = false;
    }
  }, [projectId, deviceId, deviceSessions, dispatch]);

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

    try {
      const data = JSON.parse(lastMessage.data)
      refreshLiveSession(data.sessionId);
    } catch (err) {
      console.error(err);
    }
  }, [messageHistory, lastMessage, refreshLiveSession]);

  useLayoutEffect(() => {
    dispatch(showFooter(false));
    return () => dispatch(showFooter(true));
  }, [dispatch]);

  // Load older session when scrolled at top or too little data in latest session
  const loadOlderSession = useCallback(() => {
    if (deviceSessions && deviceSessionIndexRef.current < deviceSessions.length) {
      const previousSessionId = deviceSessions[deviceSessionIndexRef.current].sessionId;
      dispatch(fetchOlderSessionForLiveDiagnostics(projectId, deviceId, previousSessionId));
      deviceSessionIndexRef.current++;
    }
  }, [deviceId, deviceSessions, dispatch, projectId]);

  // Handle scroll event from session log data table
  // Load older session if user reached top scroll position
  const onScroll = useCallback((position) => {
    if (position === 0) {
      loadOlderSession();
    }
  }, [loadOlderSession]);

  // Get list of all sessions for device
  useEffect(() => {
    if (sentRequestDeviceSessionsRef.current === false) {
      dispatch(loadListOfDeviceSessions(projectId, deviceId));
      sentRequestDeviceSessionsRef.current = true;
    }
  }, [projectId, deviceId, dispatch]);

  // Get latest session for device
  useEffect(() => {
    if (sentRequestLatestSessionRef.current === false && sessionId) {
      dispatch(fetchFirstSessionLiveDiagnostics(projectId, deviceId, sessionId, session));
      sentRequestLatestSessionRef.current = true;
    }
  }, [projectId, deviceId, sessionId, session, dispatch]);

  // Set session data for table
  useEffect(() => {
    // Guard until session is loaded
    if (isLoadingFirstSession || !Object.keys(liveLogs).length) {
      return;
    }

    let data = [];
    Object.keys(liveLogs).sort().forEach((key) => {
      data.push({
        data: liveLogs[key].log,
        deviceInfo: liveLogs[key].sessionData,
        deviceInfoGraphData: liveLogs[key].deviceInfoData,
      });
    });

    setSessionData(data);
  }, [isLoadingFirstSession, liveLogs]);

  // Check for errors
  if (deviceSessionsMeta?.error) {
    return (
      <div> { deviceSessionsMeta.error.errorMessage } </div>
    );
  }

  if (isLoadingLatestSessions) {
    return <LinearProgress size="xlarge"/>;
  }

  if (!session) {
    return 'No sessions yet or they have expired and been deleted.';
  }

  return (
    <div className={ css.container }>
      <div className={ css.breadcrumbs }>
        <SessionBreadcrumbs
          projectId={projectId}
          deviceId={deviceId}
          isLatest={true}
          session={session}/>
      </div>
      <div className={ css.contentContainer }>
        {
          isLoadingFirstSession && <LinearProgress size="xlarge"/>
        }

        <SessionLogDataTable
          liveLogs={true}
          projectId={projectId}
          deviceId={deviceId}
          data={sessionData}
          loadingLiveLogs={isLoadingRefreshSession}
          loadingOlderLogs={isLoadingOlderSession}
          onScroll={onScroll}/>
      </div>
    </div>

  );
};

LatestLiveDeviceSession.propTypes = {
  projectId: PropTypes.string,
  deviceId: PropTypes.string
};

export default LatestLiveDeviceSession;
