import React from 'react';

import { pathToInstallPage } from '../../common/utils';

import { Button } from '@rmwc/button';

import LinkWithToken from '../common/LinkWithToken';
import css from './BuildActions.module.scss'

const BuildActions = ({ build, projectId, ...props }) => {
  const actionableFiles = build.files.filter((f) => f.basename.endsWith('.ipa') || f.basename.endsWith('.apk'))

  return <div className='build-actions'>
  { actionableFiles.map((file, index) => {
    const icon = file.basename.endsWith('.ipa') ? 'phone_iphone' : 'android';
    return <div key={index}><LinkWithToken key={file.path} className='download-link' to={pathToInstallPage(projectId, build, file)}>
        <Button { ...props } icon={icon} data-id='install-button' className={css.downloadButton}>{actionableFiles.length > 1 ? file.basename : 'Install'}</Button>
      </LinkWithToken>
    </div>
  })}
  </div>
}

export default BuildActions;