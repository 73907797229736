import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import SwaggerUI from "swagger-ui-react"


import { stateUnknown } from '../../store/meta';
import { loadFileContent, saveFileMarkdownContent } from '../../store/project-actions';

import { showNotification } from "../../store/ui-actions";
import "swagger-ui-react/swagger-ui.css"

import './content.scss';
import './swagger.scss';


const SwaggerContent = ( { projectId, refresh, path, newFile, content, loadFileContent, editable, edit, toc, _meta, ...props}) => {
  const [contents, setContents] = useState()

  useEffect(() => {
    setContents(content)
  }, [content])

  useEffect(() => {
    if ((path && !newFile) || refresh) {
      loadFileContent(path, projectId);
    }
  }, [path, loadFileContent, newFile, refresh, projectId]);


  if (!path) {
    return null;
  }

  return <SwaggerUI spec={contents} defaultModelsExpandDepth={-1} docExpansion='list' />
}


const mapStateToProps = (state, ownProps) => {
  const { path } = ownProps;
  const info = state.content[path];
  if (info) {
    const ret = {
      ...info,
      ...ownProps
    }
    return ret;
  }

  return {
    ...stateUnknown()
  }
}

const mapDispatchToProps = { loadFileContent, saveFileMarkdownContent, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(SwaggerContent);
