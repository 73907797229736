import React from 'react';
import { Redirect } from "react-router-dom";


/// Support for external links and strips hostname for "absolute" internal links
const SmartRedirect = ({to, ...props}) => {
  let newTo = to;
  let url;
  try {
    url = new URL(to);
    if (url.hostname === window.location.hostname) {
      newTo = url.pathname + url.search;
      url = null;
    }
  } catch (err) {
    // ignore
  }

  if (url) {
    window.location = url;
    return null;
  }

  return <Redirect to={newTo} {...props}/>
}

export default SmartRedirect;
