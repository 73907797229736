import * as React from "react";
import { useEffect, useState } from "react";
import { TextField } from "@rmwc/textfield";
import { useHistory } from 'react-router-dom';
import { Button } from '@rmwc/button';
import { useQueryStringParams } from "common/hooks/useQueryStringParams";
import { submitQueryDebounce, submitQuery } from './LocationHelper';
import cn from "classnames";
import { Grid, GridCell, GridRow } from "@rmwc/grid";

import css from "./SessionSearch.module.scss";
import '@rmwc/select/styles';
import '@rmwc/button/styles';
import { IconButton } from "@rmwc/icon-button";

const SessionSearch = ({ projectId }) => {
    const history = useHistory();
    const qsParams = useQueryStringParams();

    const [query, setQuery] = useState("");
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect(() => {
        const { q, fromDate, toDate } = qsParams;
        q ? setQuery(q) : setQuery('');
        fromDate ? setFromDate(new Date(fromDate)) : setFromDate(null);
        toDate ? setToDate(new Date(toDate)) : setToDate(null);
    }, [qsParams]);

    const setDateUTC = (date) => {
      const utcDate = new Date();
      utcDate.setUTCDate(date.getDate());
      utcDate.setUTCMonth(date.getMonth());
      utcDate.setUTCFullYear(date.getFullYear());
      utcDate.setUTCHours(date.getHours());
      utcDate.setUTCMinutes(date.getMinutes());
      utcDate.setUTCSeconds(date.getSeconds());

      return utcDate;
    }

    const handleChange = (e) => {
      const value = e.target?.value;
      setQuery(value);
      submitQueryDebounce(value, fromDate, toDate, { history });
    };

    const handleSubmit = (e) => {
      submitQuery(query, fromDate, toDate, { history });
    };

    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        submitQuery(query, fromDate, toDate, { history })
      }
    };

    const handleFromDateChange = (e) => {
      const date = setDateUTC(new Date(e.target?.value));
      if (date && (!toDate || (toDate && date < toDate))) {
        setFromDate(date);
      } else {
        setFromDate(null);
      }
    };

    const handleToDateChange = (e) => {
      const date = setDateUTC(new Date(e.target?.value));
      if (date && (!fromDate || (fromDate && date > fromDate))) {
        setToDate(date)
      } else {
        setToDate(null);
      }
    };
    
    return (
      <Grid className={css.grid}>
        <GridRow>
          <GridCell span={12} className={css.title}>
            What are you looking for?
          </GridCell>
        </GridRow>
        <GridRow className={ css.row1 }>
          <GridCell span={12}>
            <TextField
              outlined
              icon="search"
              label="Search for user id, device name, log content ..."
              onChange={handleChange}
              value={query}
              onKeyDown={handleKeyDown}
              className={cn(css.search)}/>
          </GridCell>
        </GridRow>
        <GridRow>
          <GridCell className={css.row2} span={12}>
            <div className={css.row2Wrapper}>
              <div className={css.dateRangeWrapper}>
                <div className={`${css.datePickerFirstWrapper} ${css.datePickerWrapper}`}>
                  <TextField
                    outlined
                    onChange={handleFromDateChange}
                    value={fromDate && !isNaN(fromDate) ? fromDate.toISOString().slice(0, 19) : ''}
                    label="From date"
                    type="datetime-local"
                    step="1"/>

                  <IconButton
                    className={ css.datePickerClear }
                    onClick={() => setFromDate(null)}
                    icon="close" />
                </div>
                <div className={css.datePickerWrapper}>
                  <TextField
                    outlined
                    onChange={handleToDateChange}
                    value={toDate && !isNaN(toDate) ? toDate.toISOString().slice(0, 19) : ''}
                    label="To date"
                    type="datetime-local"
                    step="1"/>
                  <IconButton
                    className={ css.datePickerClear }
                    onClick={() => setToDate(null)}
                    icon="close" />
                </div>
              </div>
              <div className={css.searchSubmit}>
                <Button
                  onClick={handleSubmit}
                  className={css.submit}
                  label="Search"
                  theme={['secondaryBg', 'onSecondary']} />
              </div>
            </div>
          </GridCell>
        </GridRow>
      </Grid>
    );
};

export default SessionSearch;
