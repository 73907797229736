import config from 'config/firebaseConfig.js';

import { auth, storage } from 'components/common/Firebase';

import { appendAccessToken, getIdToken } from 'api/auth';

import * as moment from 'moment';
import { joinPath } from 'common/utils.js';
import { FETCH_DEFS, PATCH_JSON, POST, POST_URL_ENCODED, POST_JSON, composeUrl, checkForOk, fetchWithAuth } from "api/api-utils";


function toUrlEncoded(obj) {
  const params = Object.keys(obj)
    .reduce((prev, current) => {
      const value = obj[current];
      prev.set(current, value);
      return prev;
    }, new URLSearchParams());

  return params;
}

function composeProjectsUrl(projectId, op) {
  if (!op) {
    return `${config.API_BASE}/projects/${encodeURIComponent(projectId)}`;
  }
  return `${config.API_BASE}/projects/${encodeURIComponent(projectId)}/${op}`;
}


export const getProject = (projectId) => {
  const url = composeProjectsUrl(projectId);
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS
  }).then(response => response.json());
}

export const createProject = (projectId, name) => {
  const url = composeProjectsUrl(projectId);
  const body = toUrlEncoded({
    name
  });

  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    ...POST_URL_ENCODED,
    body
  });
}

export const saveProjectBuildNumber = (projectId, maxBuilds) => {
  const url = composeProjectsUrl(projectId);
  const body = {
    maxBuilds
  };

  return fetchWithAuth(url, {
    ...FETCH_DEFS,
    ...PATCH_JSON,
    body: JSON.stringify(body)
  });
}

export const saveProjectLiveLogsSettings = (projectId, liveLogsSeconds, liveLogsPayloadSize) => {
  const url = composeProjectsUrl(projectId);
  const body = {
    liveLogsSeconds,
    liveLogsPayloadSize
  };

  return fetchWithAuth(url, {
    ...FETCH_DEFS,
    ...PATCH_JSON,
    body: JSON.stringify(body)
  });
}

export const deleteProject = (projectId) => {
  const url = composeProjectsUrl(projectId);
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    method: 'DELETE'
  });
}


export const getProjectMembers = (projectId) => {
  const url = composeProjectsUrl(projectId, 'members');
  return fetchWithAuth(url, { projectId })
    .then(request => request.json())
}

export const addMemberToProject = (projectId, continueUrl, email) => {
  const url = composeProjectsUrl(projectId, 'share');
  const body = toUrlEncoded({
    projectId,
    continueUrl,
    email
  });
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    ...POST_URL_ENCODED,
    body
  }).then(checkForOk);
}

export const acceptProjectInvite = (projectId, inviteToken) => {
  const url = composeProjectsUrl(projectId, 'acceptInvite');
  const body = toUrlEncoded({
    inviteToken: inviteToken
  });
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    ...POST_URL_ENCODED,
    body
  })
}

export const removeMemberFromProject = (projectId, memberId) => {
  const url = composeProjectsUrl(projectId, `members/${memberId}`);
  return fetchWithAuth(url, { projectId, method: 'DELETE' });
}

export const updateMemberPermissions = (projectId, memberId, permissions) => {
  const url = composeProjectsUrl(projectId, `members/${memberId}`);
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    ...PATCH_JSON,
    body: JSON.stringify({ permissions })
  })
}

export const getUserProjects = () => {
  const url = composeUrl('/me/projects');
  return fetchWithAuth(url).then(checkForOk)
    .then(response => response.json());
}

export const getProjectFiles = (projectId) => {
  const url = composeUrl(`/projects/${projectId}/files/`);
  return fetchWithAuth(url, { projectId }).then(checkForOk)
    .then(response => response.json());
}


export const getProjectFileContent = (path, projectId) => {
  return fetchWithAuth(composeUrl('/' + path), { projectId })
    .then(response => response.text())
}

export const getUploadUrl = (path) => {
  const headers = new Headers();
  headers.set('x-goog-resumable', 'start');
  const url = composeUrl('/' + path);
  return fetchWithAuth(url, {
    ...FETCH_DEFS,
    ...POST,
    headers
  })
    .then(response => response.json())
    .then(r => r.url);
}

export const postFileMarkdownContent = (path, content) => {
  const m = path.match(/projects\/([^/]+)\/files\/(.*)/);
  if (!m) {
    throw new Error('no a valid path')
  }
  const projectId = m[1];
  const name = m[2];
  const body = new TextEncoder().encode(content);
  return uploadMarkdownFileProjectFile(projectId, name, body)
}

export const moveFileToProject = (projectFilePath) => {
  return fetchWithAuth(composeUrl(`/me/${projectFilePath}`), { method: 'PUT' });
}


const uploadMarkdownFileProjectFile = (projectId, name, file) => {
  const currentUser = auth().currentUser;
  if (!currentUser) {
    return Promise.reject(new Error('Login first'));
  }
  const ref = storage.ref(`home/${currentUser.uid}/projects/${projectId}/${name}`);
  return ref.put(file, {
    contentType: 'text/markdown'
  }).then(() => {
    return fetchWithAuth(composeUrl(`/me/projects/${projectId}/files/${name}`),
      { method: 'PUT' });
  });
}

export const getProjectBuilds = (projectId) => {
  const url = composeUrl(`/projects/${projectId}/builds`);
  return fetchWithAuth(url, { projectId })
    .then(response => response.json())
}

export const deleteProjectBuild = (projectId, name, version) => {
  const url = composeProjectsUrl(projectId, `builds/${name}/${version}`);
  return fetchWithAuth(url, { method: 'DELETE' });
}

export const deleteProjectFilePath = (projectId, filePath) => {
  const url = composeUrl(joinPath('/', filePath));
  return fetchWithAuth(url, { projectId, method: 'DELETE' });
}


const getDownloadLink = (projectId, projectRelativePath) => {
  return fetchWithAuth(composeUrl(`/${projectRelativePath}?url`), { projectId })
    .then(response => response.json())
    .then(({ url }) => url);
}

export const getBuildInstallLink = (projectId, buildName, buildVersion, file) => {
  return getInstallLink(projectId, `projects/${projectId}/files/builds/${buildName}/${buildVersion}/${file}`);
}

export const createInstallLimitedAccessToken = (projectId, buildName, buildVersion) => {
  const url = composeUrl(`/me/tokens/install/${projectId}/${buildName}/${buildVersion}`);
  return fetchWithAuth(url, { ...POST })
    .then(response => response.json())
}

const getInstallLink = (projectId, projectRelativePath) => {
  if (projectRelativePath.endsWith('.ipa')) {
    const link = composeUrl(`/${projectRelativePath}?install=yes}`, true);
    return appendAccessToken(projectId, link);
} else {
  return getDownloadLink(projectId, projectRelativePath);
}
}

export const getProjectKeys = (projectId) => {
  const url = composeUrl(`/projects/${projectId}/keys?type=apiKey`);
  return fetchWithAuth(url, { projectId })
    .then(response => response.json())
    .then((data) => {
      const keys = data.keys.map((key) => {
        return {
          ...key,
          createdAt: moment(key.createdAt).toDate(),
          lastUsed: moment(key.lastUsed).toDate()
        }
      })
      return { keys }
    });
}

export const createProjectKeys = (projectId) => {
  const url = composeUrl(`/projects/${projectId}/keys`);
  return fetchWithAuth(url, { projectId, method: 'POST' });
}

export const deleteProjectKey = (projectId, keyId) => {
  const url = composeUrl(`/projects/${projectId}/keys/${keyId}`);
  return fetchWithAuth(url, { projectId, method: 'DELETE' });
}

export const createBuildVersionShareToken = (projectId, buildName, buildVersion, permissions) => {
  const body = {
    permissions,
    paths: [`/builds/${buildName}/${buildVersion}`]
  }
  return createShareToken(projectId, body)
}

export const getShareTokens = (projectId) => {
  const url = composeProjectsUrl(projectId, 'shareToken');
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    method: 'GET'
  }).then(response => response.json());
}

export const createShareToken = (projectId, body) => {
  const url = composeProjectsUrl(projectId, 'shareToken');
  if (!body) {
    body = { paths: ['/'] }
  }
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    ...POST_JSON,
    body: JSON.stringify(body)
  });
}

export const deleteShareToken = (projectId, token) => {
  const url = composeProjectsUrl(projectId, `shareToken/${token}`);
  return fetchWithAuth(url, { projectId, method: 'DELETE' });
}

export const exchangeShareTokenForAuthToken = (shareToken) => {
  const url = composeUrl('/exchange/jwt');
  const body = toUrlEncoded({
    apiKey: shareToken
  });

  return fetch(url, {
    ...FETCH_DEFS,
    ...POST_URL_ENCODED,
    body
  })
    .then(checkForOk)
    .then(res => res.json());
}

export const setSessionCookie = (auth) => {
  const url = '/f/me/login';

  return getIdToken(auth).then(idToken => {
    const body = toUrlEncoded({
      idToken
    });

    return fetch(url, {
      ...FETCH_DEFS,
      ...POST_URL_ENCODED,
      body
    })
  })
  .then(checkForOk);
}

export const getProjectDomains = (projectId) => {
  const url = composeProjectsUrl(projectId, 'domains');

  return fetchWithAuth(url, {
    projectId,
    method: 'GET'
  }).then(res => res.json())
}

export const removeDomainFromProject = (projectId, domain) => {
  const url = composeProjectsUrl(projectId, `domains/${domain}`);

  return fetchWithAuth(url, {
    projectId,
    method: 'DELETE'
  })
}

export const addDomainToProject = (projectId, domain) => {
  const url = composeProjectsUrl(projectId, 'domains');
  const body = toUrlEncoded({
    domain
  });
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    ...POST_URL_ENCODED,
    body
  }).then(checkForOk);
}

export const updateDomainPermissions = (projectId, domainId, permissions) => {
  const url = composeProjectsUrl(projectId, `domains/${domainId}`);
  return fetchWithAuth(url, {
    projectId,
    ...FETCH_DEFS,
    ...PATCH_JSON,
    body: JSON.stringify({ permissions })
  })
}

export const getProjectInvites = (projectId) => {
  const url = composeProjectsUrl(projectId, `invites`);
  return fetchWithAuth(url, {
    projectId,
    method: 'GET'
  }).then(res => res.json())
}

export const removeInviteFromProject = (projectId, inviteEmail) => {
  const url = composeProjectsUrl(projectId, `invites/${inviteEmail}`);
  return fetchWithAuth(url, {
    projectId,
    method: 'DELETE'
  }).then(res => res.json())
}

export const sendAnnouncement = ({ projectId, version, announcement, emails, sendPush, sendToSelf, buildName }) => {
  const url = composeProjectsUrl(projectId, 'announcement')
  return fetchWithAuth(url, {
    ...POST_JSON,
    body: JSON.stringify({
      buildName,
      version,
      announcement,
      emails,
      sendPush,
      sendToSelf
    })
  })
}

export const addRemoveWatchFromBuild = (projectId, buildName, add) => {
  const url = composeProjectsUrl(projectId, 'watchers');
  return fetchWithAuth(url, {
    ...FETCH_DEFS,
    method: add ? 'POST' : 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ buildName })
  })
}

export const changeFilesContentType = (filePath, contentType) => {
  const url = `${config.API_BASE}/${filePath}`;
  return fetchWithAuth(url, {
    ...FETCH_DEFS,
    ...PATCH_JSON,
    body: JSON.stringify({ contentType })
  })
}

export const changeBuildName = (projectId, oldBuildName, newBuildName) => {
  const url = composeProjectsUrl(projectId, `renameFiles`);
  return fetchWithAuth(url, {
    ...FETCH_DEFS,
    ...POST_JSON,
    body: JSON.stringify({ files: [{ oldFilePath: `projects/${projectId}/builds/${oldBuildName}`, newFilePath: `projects/${projectId}/builds/${newBuildName}` }] })
  })
}

export const pinVersion = (projectId, buildName, version) => {
  const url = composeProjectsUrl(projectId, `pinVersion`);
  return fetchWithAuth(url, {
    ...FETCH_DEFS,
    ...POST_JSON,
    body: JSON.stringify({ buildName, version })
  })
}

export const unpinVersion = (projectId, buildName, version) => {
  const url = composeProjectsUrl(projectId, `unpinVersion`);
  return fetchWithAuth(url, {
    ...FETCH_DEFS,
    ...POST_JSON,
    body: JSON.stringify({ buildName, version })
  })
}
