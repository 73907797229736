import * as projectsAPI from '../api/projects-api';
import { extract, shouldFetch } from '../store/meta';
import { getProjectIdAndNameFromFilePath, joinPath, getMimeType } from '../common/utils';
import { showNotification } from 'store/ui-actions';
import { firebase, auth, storage } from 'components/common/Firebase';

export const TYPES = {
  LOAD_PROJECT_REQUEST: 'LOAD_PROJECT_REQUEST',
  LOAD_PROJECT_FAILURE: 'LOAD_PROJECT_FAILURE',
  LOAD_PROJECT_SUCCESS: 'LOAD_PROJECT_SUCCESS',

  CREATE_PROJECT_REQUEST: 'CREATE_PROJECT_REQUEST',
  CREATE_PROJECT_FAILURE: 'CREATE_PROJECT_FAILURE',
  CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',

  DELETE_PROJECT_REQUEST: 'DELETE_PROJECT_REQUEST',
  DELETE_PROJECT_REQUEST_SUCCESS: 'DELETE_PROJECT_REQUEST_SUCCESS',
  DELETE_PROJECT_REQUEST_FAILURE: 'DELETE_PROJECT_REQUEST_FAILURE',

  LOAD_USER_PROJECTS_REQUEST: 'LOAD_USER_PROJECTS_REQUEST',
  LOAD_USER_PROJECTS_SUCCESS: 'LOAD_USER_PROJECTS_SUCCESS',
  LOAD_USER_PROJECTS_FAILURE: 'LOAD_USER_PROJECTS_FAILURE',

  LOAD_PROJECT_MEMBERS_REQUEST: 'LOAD_PROJECT_MEMBERS_REQUEST',
  LOAD_PROJECT_MEMBERS_FAILURE: 'LOAD_PROJECT_MEMBERS_FAILURE',
  LOAD_PROJECT_MEMBERS_SUCCESS: 'LOAD_PROJECT_MEMBERS_SUCCESS',

  LOAD_PROJECT_DOMAIN_REQUEST: 'LOAD_PROJECT_DOMAIN_REQUEST',
  LOAD_PROJECT_DOMAIN_FAILURE: 'LOAD_PROJECT_DOMAIN_FAILURE',
  LOAD_PROJECT_DOMAIN_SUCCESS: 'LOAD_PROJECT_DOMAIN_SUCCESS',

  LOAD_PROJECT_INVITE_REQUEST: 'LOAD_PROJECT_INVITE_REQUEST',
  LOAD_PROJECT_INVITE_FAILURE: 'LOAD_PROJECT_INVITE_FAILURE',
  LOAD_PROJECT_INVITE_SUCCESS: 'LOAD_PROJECT_INVITE_SUCCESS',

  ADD_PROJECT_DOMAIN_REQUEST: 'ADD_PROJECT_DOMAIN_REQUEST',
  ADD_PROJECT_DOMAIN_REQUEST_SUCCESS: 'ADD_PROJECT_DOMAIN_REQUEST_SUCCESS',
  ADD_PROJECT_DOMAIN_REQUEST_FAILURE: 'ADD_PROJECT_DOMAIN_REQUEST_FAILURE',

  REMOVE_PROJECT_DOMAIN_REQUEST: 'REMOVE_PROJECT_DOMAIN_REQUEST',
  REMOVE_PROJECT_DOMAIN_REQUEST_SUCCESS: 'REMOVE_PROJECT_DOMAIN_REQUEST_SUCCESS',
  REMOVE_PROJECT_DOMAIN_REQUEST_FAILURE: 'REMOVE_PROJECT_DOMAIN_REQUEST_FAILURE',

  REMOVE_PROJECT_INVITE_REQUEST: 'REMOVE_PROJECT_INVITE_REQUEST',
  REMOVE_PROJECT_INVITE_REQUEST_SUCCESS: 'REMOVE_PROJECT_INVITE_REQUEST_SUCCESS',
  REMOVE_PROJECT_INVITE_REQUEST_FAILURE: 'REMOVE_PROJECT_INVITE_REQUEST_FAILURE',

  UPDATE_PROJECT_DOMAIN_REQUEST: 'UPDATE_PROJECT_DOMAIN_REQUEST',
  UPDATE_PROJECT_DOMAIN_REQUEST_SUCCESS: 'UPDATE_PROJECT_DOMAIN_REQUEST_SUCCESS',
  UPDATE_PROJECT_DOMAIN_REQUEST_FAILURE: 'UPDATE_PROJECT_DOMAIN_REQUEST_FAILURE',

  LOAD_PROJECT_FILE_REQUEST: 'LOAD_PROJECT_FILE_REQUEST',
  LOAD_PROJECT_FILE_REQUEST_SUCCESS: 'LOAD_PROJECT_FILE_REQUEST_SUCCESS',
  LOAD_PROJECT_FILE_REQUEST_FAILURE: 'LOAD_PROJECT_FILE_REQUEST_FAILURE',

  LOAD_PROJECT_KEYS_REQUEST: 'LOAD_PROJECT_KEYS_REQUEST',
  LOAD_PROJECT_KEYS_REQUEST_SUCCESS: 'LOAD_PROJECT_KEYS_REQUEST_SUCCESS',
  LOAD_PROJECT_KEYS_REQUEST_FAILURE: 'LOAD_PROJECT_KEYS_REQUEST_FAILURE',

  LOAD_PROJECT_BUILDS_REQUEST: 'LOAD_PROJECT_BUILDS_REQUEST',
  LOAD_PROJECT_BUILDS_REQUEST_SUCCESS: 'LOAD_PROJECT_BUILDS_REQUEST_SUCCESS',
  LOAD_PROJECT_BUILDS_REQUEST_FAILURE: 'LOAD_PROJECT_BUILDS_REQUEST_FAILURE',

  SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',

  LOAD_PROJECT_FILES_REQUEST: 'LOAD_PROJECT_FILES_REQUEST',
  LOAD_PROJECT_FILES_REQUEST_SUCCESS: 'LOAD_PROJECT_FILES_REQUEST_SUCCESS',
  LOAD_PROJECT_FILES_REQUEST_FAILURE: 'LOAD_PROJECT_FILES_REQUEST_FAILURE',

  LOAD_FILE_CONTENT_REQUEST: 'LOAD_FILE_CONTENT_REQUEST',
  LOAD_FILE_CONTENT_REQUEST_SUCCESS: 'LOAD_FILE_CONTENT_REQUEST_SUCCESS',
  LOAD_FILE_CONTENT_REQUEST_FAILURE: 'LOAD_FILE_CONTENT_REQUEST_FAILURE',

  SAVE_FILE_CONTENT_REQUEST: 'SAVE_FILE_CONTENT_REQUEST',
  SAVE_FILE_CONTENT_REQUEST_SUCCESS: 'SAVE_FILE_CONTENT_REQUEST_SUCCESS',
  SAVE_FILE_CONTENT_REQUEST_FAILURE: 'SAVE_FILE_CONTENT_REQUEST_FAILURE',

  REMOVE_PROJECT_MEMBER_REQUEST: 'REMOVE_PROJECT_MEMBER_REQUEST',
  REMOVE_PROJECT_MEMBER_REQUEST_SUCCESS: 'REMOVE_PROJECT_MEMBER_REQUEST_SUCCESS',
  REMOVE_PROJECT_MEMBER_REQUEST_FAILURE: 'REMOVE_PROJECT_MEMBER_REQUEST_FAILURE',

  ADD_PROJECT_MEMBER_REQUEST: 'ADD_PROJECT_MEMBER_REQUEST',
  ADD_PROJECT_MEMBER_REQUEST_SUCCESS: 'ADD_PROJECT_MEMBER_REQUEST_SUCCESS',
  ADD_PROJECT_MEMBER_REQUEST_FAILURE: 'ADD_PROJECT_MEMBER_REQUEST_FAILURE',

  UPDATE_PROJECT_MEMBER_REQUEST: 'UPDATE_PROJECT_MEMBER_REQUEST',
  UPDATE_PROJECT_MEMBER_REQUEST_SUCCESS: 'UPDATE_PROJECT_MEMBER_REQUEST_SUCCESS',
  UPDATE_PROJECT_MEMBER_REQUEST_FAILURE: 'UPDATE_PROJECT_MEMBER_REQUEST_FAILURE',

  DELETE_PROJECT_BUILD_REQUEST: 'DELETE_PROJECT_BUILD_REQUEST',
  DELETE_PROJECT_BUILD_REQUEST_SUCCESS: 'DELETE_PROJECT_BUILD_REQUEST_SUCCESS',
  DELETE_PROJECT_BUILD_REQUEST_FAILURE: 'DELETE_PROJECT_BUILD_REQUEST_FAILURE',

  CREATE_PROJECT_FILE_REQUEST: 'CREATE_PROJECT_FILE_REQUEST',
  DELETE_PROJECT_FILE_REQUEST: 'DELETE_PROJECT_FILE_REQUEST',
  DELETE_PROJECT_FILE_REQUEST_SUCCESS: 'DELETE_PROJECT_FILE_REQUEST_SUCCESS',
  DELETE_PROJECT_FILE_REQUEST_FAILURE: 'DELETE_PROJECT_FILE_REQUEST_FAILURE',

  ACCEPT_PROJECT_INVITE_REQUEST: 'ACCEPT_PROJECT_INVITE_REQUEST',
  ACCEPT_PROJECT_INVITE_REQUEST_SUCCESS: 'ACCEPT_PROJECT_INVITE_REQUEST_SUCCESS',
  ACCEPT_PROJECT_INVITE_REQUEST_FAILURE: 'ACCEPT_PROJECT_INVITE_REQUEST_FAILURE',

  PROJECT_REFRESH_REQUEST: 'PROJECT_REFRESH_REQUEST',

  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_REQUEST_PROGRESS: 'UPLOAD_FILE_REQUEST_PROGRESS',
  UPLOAD_FILE_REQUEST_FAILURE: 'UPLOAD_FILE_REQUEST_FAILURE',
  UPLOAD_FILE_REQUEST_SUCCESS: 'UPLOAD_FILE_REQUEST_SUCCESS',

  FILE_META_DATA_UPDATED: 'FILE_META_DATA_UPDATED',

  PROJECT_BUILD_NAME_UPDATED: 'PROJECT_BUILD_NAME_UPDATED'
};
let buildChangesSubscriptions = {}

const dispatchSuccess = (dispatch, result) => {
  if (result) {
    dispatch(result);
  }
  return Promise.resolve({
    ok: true
  });
}

const dispatchError = (dispatch, result, error) => {
  dispatch(result);
  return Promise.resolve({
    ok: false,
    error: error
  });
}

export const getUserProjects = () => (dispatch, getState) => {
  if (!shouldFetch(getState(), ['myProjects'])) {
    return dispatchSuccess(dispatch);
  }

  dispatch({ type: TYPES.LOAD_USER_PROJECTS_REQUEST });

  projectsAPI.getUserProjects()
    .then((projects) => {
      dispatch({ type: TYPES.LOAD_USER_PROJECTS_SUCCESS, payload: projects });
    }).catch(err => {
      dispatch({ type: TYPES.LOAD_USER_PROJECTS_FAILURE, error: err });
    });
}

const isCurrent = (projectId, getState) => {
  const state = getState();
  const currentId = state.currentProject.projectId;
  return projectId === currentId;
}


export const setCurrentProject = (project) => (dispatch) => {
  dispatch({ type: TYPES.SET_CURRENT_PROJECT, payload: { ...project } })
}

const loadProject = async (projectId, dispatch, getState) => {
  dispatch({ type: TYPES.LOAD_PROJECT_REQUEST, current: isCurrent(projectId, getState), payload: { id: projectId } });

  try {
    const project = await projectsAPI.getProject(projectId);
    const permissions = project.permissions

    if (permissions && permissions.share) {
      try {
        const shareTokens = await projectsAPI.getShareTokens(projectId)
        project.shareTokens = shareTokens.shareTokens
      } catch (e) {
        console.error(e)
      }
    }
    return dispatchSuccess(dispatch, { type: TYPES.LOAD_PROJECT_SUCCESS, current: isCurrent(projectId, getState), payload: { ...project } })
  }
  catch (error) {
    dispatchError(dispatch, { type: TYPES.LOAD_PROJECT_FAILURE, current: isCurrent(projectId, getState), error, payload: { id: projectId, ...error } }, error)
  }
}

export const getProjectDetails = (projectId, forceRefresh) => (dispatch, getState) => {
  if (!shouldFetch(getState(), ['projects', projectId]) && !forceRefresh) {
    return dispatchSuccess(dispatch);
  }

  return loadProject(projectId, dispatch, getState);
}

export const createProject = (projectId, name) => (dispatch, getState) => {
  dispatch({ type: TYPES.CREATE_PROJECT_REQUEST, payload: { id: projectId, name } });
  return projectsAPI.createProject(projectId, name)
    .then(
      () => getProjectDetails(projectId, true)(dispatch, getState),
      (err) => dispatchError(dispatch, { type: TYPES.CREATE_PROJECT_FAILURE, error: err }, err))
}

export const deleteProject = (projectId) => (dispatch) => {
  dispatch({ type: TYPES.DELETE_PROJECT_REQUEST, payload: { id: projectId } });
  return projectsAPI.deleteProject(projectId)
    .then(
      () => dispatchSuccess(dispatch, { type: TYPES.DELETE_PROJECT_REQUEST_SUCCESS, payload: { projectId } }),
      (err) => dispatchError(dispatch, { type: TYPES.DELETE_PROJECT_REQUEST_FAILURE, error: err }, err))
}

export const saveProjectBuildNumber = (projectId, maxBuilds) => (dispatch, getState) => {
  return projectsAPI.saveProjectBuildNumber(projectId, maxBuilds)
    .then(() => loadProject(projectId, dispatch, getState))
}

export const saveLiveLogSettings = (projectId, liveLogsSeconds, liveLogsPayloadSize) => (dispatch, getState) => {
  return projectsAPI.saveProjectLiveLogsSettings(projectId, liveLogsSeconds, liveLogsPayloadSize)
    .then(() => loadProject(projectId, dispatch, getState))
}

export const loadProjectBuilds = (projectId) => (dispatch, getState) => {
  if (!shouldFetch(getState(), ['builds', projectId])) {
    return dispatchSuccess(dispatch);
  }

  fetchProjectBuilds(projectId, dispatch)
}

const fetchProjectBuilds = (projectId, dispatch) => {
  dispatch({ type: TYPES.LOAD_PROJECT_BUILDS_REQUEST, payload: { id: projectId } });
  return projectsAPI.getProjectBuilds(projectId)
    .then((builds) => {
      dispatch({ type: TYPES.LOAD_PROJECT_BUILDS_REQUEST_SUCCESS, payload: { id: projectId, builds } });
    }, (error) => {
      dispatch({ type: TYPES.LOAD_PROJECT_BUILDS_REQUEST_FAILURE, error, payload: { id: projectId, ...error } });
    });
}

export const deleteProjectBuild = (projectId, name, version) => (dispatch) => {
  dispatch({ type: TYPES.DELETE_PROJECT_BUILD_REQUEST, payload: { id: projectId } });

  projectsAPI.deleteProjectBuild(projectId, name, version)
    .then(() => {
      dispatch({ type: TYPES.DELETE_PROJECT_BUILD_REQUEST_SUCCESS, payload: { id: projectId, name, version } });
    }, (error) => {
      dispatch({ type: TYPES.DELETE_PROJECT_BUILD_REQUEST_FAILURE, error, payload: { id: projectId, name, version, ...error } });
    });
}

export const createProjectBuildFile = (projectId, name, version, fileName, opts) => (dispatch) => {
  const path = name
    ? `projects/${projectId}/files/builds/${name}/${version}/${fileName}`
    : `projects/${projectId}/files/builds/${name}/${version}/${fileName}`

  const result = dispatchSuccess(dispatch, { type: TYPES.CREATE_PROJECT_FILE_REQUEST, payload: { id: projectId, path, name, version, ...opts } });
  return Promise.resolve(result);
};

export const uploadBuild = (projectId, buildName, version, fileList) => async (dispatch) => {
  const currentUser = auth().currentUser;

  for (const file of fileList) {
    const finalPath = joinPath(`projects/${projectId}/files/builds`, buildName, version, file.name);
    const homePath = joinPath(`home/${currentUser.uid}/projects/${projectId}/builds`, buildName, version, file.name);
    const payload = { projectId, path: finalPath, isBuild: true };
    dispatch({ type: TYPES.UPLOAD_FILE_REQUEST, payload });

    const metadata = { contentType: file.fileContent ? getMimeType(file.name, file.fileContent) : file.type || getMimeType(file.name) }
    if (file.metadata) {
      metadata.customMetadata = file.metadata
    }
    const uploadTask = storage.ref(homePath).put(file, metadata);
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      const progress = ((snapshot.bytesTransferred * 1.0) / snapshot.totalBytes);
      dispatch({ type: TYPES.UPLOAD_FILE_REQUEST_PROGRESS, payload: { ...payload, progress } });
    });
    try {
      await uploadTask
      await projectsAPI.moveFileToProject(finalPath)
      dispatch({ type: TYPES.UPLOAD_FILE_REQUEST_SUCCESS, payload })
    } catch (error) {
      dispatch({ type: TYPES.UPLOAD_FILE_REQUEST_FAILURE, error, payload })
    }
  }

  return dispatchSuccess(dispatch);
}

export const uploadFiles = (projectId, folder, fileList) => (dispatch) => {
  const currentUser = auth().currentUser;

  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    const finalPath = joinPath(`projects/${projectId}/files/`, folder, file.name);
    const homePath = joinPath(`home/${currentUser.uid}/projects/${projectId}`, folder, file.name);
    const payload = { projectId, path: finalPath };
    dispatch({ type: TYPES.UPLOAD_FILE_REQUEST, payload });

    const uploadTask = storage.ref(homePath).put(file, { contentType: file.fileContent ? getMimeType(file.name, file.fileContent) : file.type || getMimeType(file.name) });
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      const progress = ((snapshot.bytesTransferred * 1.0) / snapshot.totalBytes);
      dispatch({ type: TYPES.UPLOAD_FILE_REQUEST_PROGRESS, payload: { ...payload, progress } });
    });
    uploadTask.then(() => projectsAPI.moveFileToProject(finalPath))
      .then(() => dispatch({ type: TYPES.UPLOAD_FILE_REQUEST_SUCCESS, payload }))
      .catch(error => {
        dispatch({ type: TYPES.UPLOAD_FILE_REQUEST_FAILURE, error, payload })
      })

  }

  return dispatchSuccess(dispatch);
}

export const deleteProjectPath = (projectId, projectRelativePath) => (dispatch) => {
  const payload = { projectId: projectId, path: projectRelativePath };
  dispatch({ type: TYPES.DELETE_PROJECT_FILE_REQUEST, payload });

  return projectsAPI.deleteProjectFilePath(projectId, projectRelativePath)
    .then(() => dispatchSuccess(dispatch, { type: TYPES.DELETE_PROJECT_FILE_REQUEST_SUCCESS, payload }))
    .catch((error) => dispatchError(dispatch, { type: TYPES.DELETE_PROJECT_FILE_REQUEST_FAILURE, payload, error }, error));
}

export const deleteProjectBuildFile = (projectId, name, version, fileName) => (dispatch) => {
  const path = name
    ? `projects/${projectId}/files/builds/${name}/${version}/${fileName}`
    : `projects/${projectId}/files/builds/${name}/${version}/${fileName}`

  const result = dispatchSuccess(dispatch, { type: TYPES.DELETE_PROJECT_FILE_REQUEST, payload: { id: projectId, path, name, version } });
  return Promise.resolve(result);
};



export const loadProjectFiles = (projectId) => (dispatch, getState) => {
  if (!shouldFetch(getState(), ['files', projectId])) {
    return dispatchSuccess(dispatch);
  }

  dispatch({ type: TYPES.LOAD_PROJECT_FILES_REQUEST, payload: { id: projectId } });
  fetchProjectFiles(projectId, dispatch)
}

const fetchProjectFiles = (projectId, dispatch) => {
  projectsAPI.getProjectFiles(projectId)
    .then((files) => {
      dispatch({ type: TYPES.LOAD_PROJECT_FILE_REQUEST_SUCCESS, payload: { id: projectId, files } });
    }, (error) => {
      dispatch({ type: TYPES.LOAD_PROJECT_FILES_REQUEST_FAILURE, error, payload: { id: projectId, ...error } });
    });
}

export const loadFileContent = (path, projectId, forceRefresh) => (dispatch, getState) => {
  const state = getState();
  const [, _m] = extract(state, 'content', path);

  if ((_m.edited || _m.newFile) && !forceRefresh) {
    return Promise.resolve({ ok: true });
  }

  dispatch({ type: TYPES.LOAD_FILE_CONTENT_REQUEST, payload: { path } });

  return projectsAPI.getProjectFileContent(path, projectId)
    .then((content) => {
      dispatch({ type: TYPES.LOAD_FILE_CONTENT_REQUEST_SUCCESS, payload: { path, content } });
    }, (error) => {
      dispatch({ type: TYPES.LOAD_FILE_CONTENT_REQUEST_FAILURE, error, payload: { path, ...error } });
    });
}

export const saveFileMarkdownContent = (path, content) => (dispatch) => {
  const { projectId } = getProjectIdAndNameFromFilePath(path);
  dispatch({ type: TYPES.SAVE_FILE_CONTENT_REQUEST, payload: { path, projectId, content } });
  return projectsAPI.postFileMarkdownContent(path, content)
    .then(
      () => dispatchSuccess(dispatch,
        { type: TYPES.SAVE_FILE_CONTENT_REQUEST_SUCCESS, payload: { path, projectId, content } }),
      (error) => dispatchError(dispatch,
        { type: TYPES.SAVE_FILE_CONTENT_REQUEST_FAILURE, error, payload: { projectId, path, ...error } },
        error)
    );
}

export const getProjectMembers = (projectId) => (dispatch) => {
  dispatch({ type: TYPES.LOAD_PROJECT_MEMBERS_REQUEST, payload: { id: projectId } });

  return projectsAPI.getProjectMembers(projectId)
    .then((members) => dispatch({ type: TYPES.LOAD_PROJECT_MEMBERS_SUCCESS, payload: { id: projectId, ...members } }))
    .catch((err) => dispatch({ type: TYPES.LOAD_PROJECT_MEMBERS_FAILURE, error: err, payload: { id: projectId } }));
};

export const addMemberToProject = (projectId, continueUrl, email) => (dispatch) => {
  dispatch({ type: TYPES.ADD_PROJECT_MEMBER_REQUEST, payload: { id: projectId, email } });

  return projectsAPI.addMemberToProject(projectId, continueUrl, email)
    .then(() => dispatch({ type: TYPES.ADD_PROJECT_MEMBER_REQUEST_SUCCESS, payload: { id: projectId, email } }))
    .catch((err) => dispatch({ type: TYPES.ADD_PROJECT_MEMBER_FAILURE, error: err, payload: { id: projectId, email } }))
    .then(() => getProjectMembers(projectId)(dispatch))
    .then(() => getProjectInvites(projectId)(dispatch));
}

export const acceptProjectInvite = (projectId, inviteToken) => (dispatch, getState) => {

  dispatch({ type: TYPES.ACCEPT_PROJECT_INVITE_REQUEST, payload: { id: projectId } });

  return projectsAPI.acceptProjectInvite(projectId, inviteToken)
    .then(() => {
      loadProject(projectId, dispatch, getState);
      return dispatchSuccess(dispatch, { type: TYPES.ACCEPT_PROJECT_INVITE_REQUEST_SUCCESS, payload: { id: projectId } });
    })
    .catch((error) => dispatchError(dispatch, { type: TYPES.ACCEPT_PROJECT_INVITE_REQUEST_FAILURE, error, payload: { projectId } }, error))
}

export const removeMemberFromProject = (projectId, memberId) => (dispatch) => {
  dispatch({ type: TYPES.REMOVE_PROJECT_MEMBER_REQUEST, payload: { id: projectId, memberId } });

  return projectsAPI.removeMemberFromProject(projectId, memberId)
    .then(() => dispatch({ type: TYPES.REMOVE_PROJECT_MEMBER_REQUEST_SUCCESS, payload: { id: projectId, memberId } }))
    .catch((err) => dispatch({ type: TYPES.REMOVE_PROJECT_MEMBER_REQUEST_FAILURE, error: err, payload: { id: projectId, memberId } }))
    .then(() => getProjectMembers(projectId)(dispatch));
}

export const updateMemberPermissions = (projectId, memberId, permissions) => (dispatch) => {
  dispatch({ type: TYPES.UPDATE_PROJECT_MEMBER_REQUEST, payload: { id: projectId, memberId, permissions } });

  return projectsAPI.updateMemberPermissions(projectId, memberId, permissions)
    .then(() => dispatch({ type: TYPES.UPDATE_PROJECT_MEMBER_REQUEST_SUCCESS, payload: { id: projectId, memberId, permissions } }))
    .catch((err) => dispatch({ type: TYPES.UPDATE_PROJECT_MEMBER_REQUEST_FAILURE, error: err, payload: { id: projectId, memberId } }))
}

export const addDomainToProject = (projectId, domain) => (dispatch) => {
  dispatch({ type: TYPES.ADD_PROJECT_DOMAIN_REQUEST, payload: { id: projectId, domain } });

  return projectsAPI.addDomainToProject(projectId, domain)
    .then(() => dispatch({ type: TYPES.ADD_PROJECT_DOMAIN_REQUEST_SUCCESS, payload: { id: projectId, domain } }))
    .catch((err) => dispatch({ type: TYPES.ADD_PROJECT_DOMAIN_REQUEST_FAILURE, error: err, payload: { id: projectId, domain } }))
    .then(() => getProjectDomains(projectId)(dispatch));
}

export const removeDomainFromProject = (projectId, domainId) => (dispatch) => {
  dispatch({ type: TYPES.REMOVE_PROJECT_DOMAIN_REQUEST, payload: { id: projectId, domainId } });

  return projectsAPI.removeDomainFromProject(projectId, domainId)
    .then(() => dispatch({ type: TYPES.REMOVE_PROJECT_DOMAIN_REQUEST_SUCCESS, payload: { id: projectId, domainId } }))
    .catch((err) => dispatch({ type: TYPES.REMOVE_PROJECT_DOMAIN_REQUEST_FAILURE, error: err, payload: { id: projectId, domainId } }))
    .then(() => getProjectDomains(projectId)(dispatch));
}

export const getProjectDomains = (projectId) => (dispatch) => {
  dispatch({ type: TYPES.LOAD_PROJECT_DOMAIN_REQUEST, payload: { id: projectId } });
  return projectsAPI.getProjectDomains(projectId)
    .then((domains) => dispatch({ type: TYPES.LOAD_PROJECT_DOMAIN_SUCCESS, payload: { id: projectId, ...domains } }))
    .catch((err) => dispatch({ type: TYPES.LOAD_PROJECT_DOMAIN_FAILURE, error: err, payload: { id: projectId } }));
}

export const updateDomainPermissions = (projectId, domainId, permissions) => (dispatch) => {
  dispatch({ type: TYPES.UPDATE_PROJECT_DOMAIN_REQUEST, payload: { id: projectId, domainId, permissions } });
  return projectsAPI.updateDomainPermissions(projectId, domainId, permissions)
    .then(() => dispatch({ type: TYPES.UPDATE_PROJECT_DOMAIN_REQUEST_SUCCESS, payload: { id: projectId, domainId, permissions } }))
    .catch((err) => dispatch({ type: TYPES.UPDATE_PROJECT_DOMAIN_REQUEST_FAILURE, error: err, payload: { id: projectId, domainId } }))
}

export const getProjectInvites = (projectId) => (dispatch) => {
  dispatch({ type: TYPES.LOAD_PROJECT_INVITE_REQUEST, payload: { id: projectId } });
  return projectsAPI.getProjectInvites(projectId)
    .then((invites) => dispatch({ type: TYPES.LOAD_PROJECT_INVITE_SUCCESS, payload: { id: projectId, ...invites } }))
    .catch((err) => dispatch({ type: TYPES.LOAD_PROJECT_INVITE_FAILURE, error: err, payload: { id: projectId } }));
}

export const removeInviteFromProject = (projectId, inviteEmail) => (dispatch) => {
  dispatch({ type: TYPES.REMOVE_PROJECT_INVITE_REQUEST, payload: { id: projectId, inviteEmail } });

  return projectsAPI.removeInviteFromProject(projectId, inviteEmail)
    .then(() => dispatch({ type: TYPES.REMOVE_PROJECT_INVITE_REQUEST_SUCCESS, payload: { id: projectId, inviteEmail } }))
    .catch((err) => dispatch({ type: TYPES.REMOVE_PROJECT_INVITE_REQUEST_FAILURE, error: err, payload: { id: projectId, inviteEmail } }))
    .then(() => getProjectInvites(projectId)(dispatch));
}

export const refreshProject = (projectId, refresh = true) => (dispatch) => {
  dispatch({ type: TYPES.PROJECT_REFRESH_REQUEST, payload: { id: projectId, refresh } });
}

export const unSubscribeToBuildChanges = (projectId) => () => {
  if (buildChangesSubscriptions[projectId]) {
    buildChangesSubscriptions[projectId]()
  }
}

export const sendAnnouncement = (announcementData) => () => {
  return projectsAPI.sendAnnouncement(announcementData)
}

export const addRemoveWatchFromBuild = (projectId, buildName, isWatching) => (dispatch) => {
  return projectsAPI.addRemoveWatchFromBuild(projectId, buildName, isWatching).then(() => refreshProject(projectId)(dispatch))
}

export const changeFilesContentType = (projectId, file, contentType) => (dispatch) => {
  return projectsAPI.changeFilesContentType(file.path, contentType)
    .then(() => dispatch({ type: TYPES.FILE_META_DATA_UPDATED, payload: { contentType, path: file.path, projectId } }))
}

export const changeBuildName = (projectId, oldBuildName, newBuildName) => async (dispatch) => {
  try {
    showNotification({ message: `Changing ${oldBuildName} to ${newBuildName}` })(dispatch)
    await projectsAPI.changeBuildName(projectId, oldBuildName, newBuildName)
    dispatch({ type: TYPES.PROJECT_BUILD_NAME_UPDATED, payload: { projectId, oldBuildName, newBuildName } })
    showNotification({ message: 'Build Changed' })(dispatch)
  } catch (e) {
    showNotification({ message: 'Changing build name failed' })(dispatch)
  }
}

export const pinVersion = (projectId, buildName, version) => async (dispatch) => {
  try {
    showNotification({ message: `Pinning ${buildName} - ${version}` })(dispatch)
    await projectsAPI.pinVersion(projectId, buildName, version)
    await fetchProjectBuilds(projectId, dispatch)
    showNotification({ message: 'Version pinned' })(dispatch)
  } catch (e) {
    if (e.responseError && e.responseError.message) {
      showNotification({ message: `Pinning failed: ${e.responseError.message}` })(dispatch)
    } else {
      showNotification({ message: 'Pinning failed' })(dispatch)
    }
  }
}

export const unpinVersion = (projectId, buildName, version) => async (dispatch) => {
  try {
    showNotification({ message: `Removing pin` })(dispatch)
    await projectsAPI.unpinVersion(projectId, buildName, version)
    await fetchProjectBuilds(projectId, dispatch)
    showNotification({ message: 'Pin removed' })(dispatch)
  } catch (e) {
    showNotification({ message: 'Pin removal failed' })(dispatch)
  }
}