import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Marked from './Marked';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-markdown";
import "ace-builds/src-noconflict/theme-github";
import throttle from 'lodash.throttle';
import { basename, getProjectIdAndNameFromFilePath, joinPath } from 'common/utils';
import { stateUnknown } from 'store/meta';
import { loadFileContent, saveFileMarkdownContent } from 'store/project-actions';
import { Button } from '@rmwc/button';
import { IconButton } from '@rmwc/icon-button';
import { Typography } from '@rmwc/typography'
import { CircularProgress } from '@rmwc/circular-progress';
import { LinearProgress } from '@rmwc/linear-progress';
import { showNotification } from "store/ui-actions";
import './content.scss';
import { useScreenSizeBreakpoint } from "common/breakpoints";

const URI_PROTO_MATCH = /^([a-zA-Z0-9+.-]+:\/\/)/gm

const transformLinkUri = (path, isImage) => (uri) => {
  if (!uri) return undefined;

  // keep absolute uri as is
  if (uri.match(URI_PROTO_MATCH)) return uri;
  if (uri.startsWith('#')) return uri;

  const { projectId } = getProjectIdAndNameFromFilePath(path);

  // normalize uri
  if (uri.startsWith('/')) {
    uri = joinPath(`/${projectId}`, uri);
  }
  if (isImage && uri.startsWith(`/${projectId}/ws`)) {
    uri = `${new URL(document.URL).origin}/f/projects${uri.replace(`/${projectId}/ws`, `/${projectId}/files/ws`)}`
  }
  uri = new URL(uri, document.URL).pathname;
  return uri;
}

const MarkdownContent = ({ projectId, refresh, newFile, path, editable, toc, editMode, onEditModeChange }) => {
  const [contents, setContents] = useState()
  const editorRef = useRef(null);
  const containerRef = useRef(null);
  const toolbarRef = useRef(null);
  const dispatch = useDispatch();

  const { content, _meta } = useSelector((state) => state.content[path] || stateUnknown());

  const { saving } = _meta;
  const fitHeight = () => {
    const previewDom = containerRef.current;
    const editorDom = editorRef.current && editorRef.current.refEditor;
    if (previewDom && editorDom) {
      const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      const { y } = editorDom.getBoundingClientRect();
      const height = vh - y - toolbarRef.current.clientHeight - 8;

      editorDom.setAttribute('style', `height: ${ height }px`);
      previewDom.setAttribute('style', `height: ${ height }px`);
      editorRef.current.editor.resize();
    }
  }

  useEffect(() => {
    fitHeight();
    const listener = () => {
      fitHeight();
    }

    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [editMode])

  useEffect(() => setContents(content), [content])

  useEffect(() => {
    if ((path && !newFile) || refresh) {
      dispatch(loadFileContent(path, projectId));
    }
  }, [dispatch, path, newFile, refresh, projectId]);

  useEffect(() => {
    if (editMode) {
      editorRef.current.editor.gotoLine(1, 1, true);
      editorRef.current.editor.focus();
    }
  }, [editorRef, editMode]);


  const isMobile = useScreenSizeBreakpoint() === "mobile";

  const handleEditButtonClick = useCallback(() => {
    onEditModeChange && onEditModeChange(true);
  }, [onEditModeChange]);

  if (!path) return null;

  const linkTransform = transformLinkUri(path);
  const imageLinkTransform = transformLinkUri(path, true);

  const save = async () => {
    onEditModeChange && onEditModeChange(false);
    try {
      const { error } = await dispatch(saveFileMarkdownContent(path, contents));

      if (error) {
        dispatch(showNotification({ error }));
        throw new Error(error);
      } else {
        onEditModeChange && onEditModeChange(false);
      }
    } catch (e) {
      console.error(e);
      onEditModeChange && onEditModeChange(true);
    }
  }

  const setContentsThrottled = throttle((contents) => {
    setContents(contents);
  }, 250, { trailing: true });

  const cancelEdit = () => {
    onEditModeChange && onEditModeChange(false);
    setContents(content);
  }

  return <div className='markdown-container'>
    { !_meta.ready && _meta.loading && <LinearProgress progress={ 0 }/> }

    { saving &&
    <div className='markdown-editor-overlay'>
      <CircularProgress data-id='editor-progress' className='markdown-editor-save-progress' size="xlarge"/>
    </div>
    }

    { editMode &&
    <div>
      <div className='markdown-editor'>

        <div className='markdown-editor-left-pane'>
          <div className='markdown-editor-title'>
            <Typography className='markdown-editor-title-text' use='headline6'>{ basename(path) }</Typography>
          </div>
          <AceEditor mode='markdown' theme='github'
                     value={ contents } name='#markdown-editor-instance '
                     fontSize={ 14 }
                     ref={ editorRef }
                     onChange={ setContentsThrottled }
                     width='100%'

                     wrapEnabled
          />
        </div>
        { !isMobile &&
        <div className='markdown-editor-right-pane'>
          <div className='markdown-editor-title'>
            <Typography className='markdown-editor-title-text-right' use='headline6'>Preview</Typography>
          </div>
          <div ref={ containerRef }>
            <Marked transformLinkUri={ linkTransform } transformImageUri={ imageLinkTransform }
                    className='markdown-body markdown-body-preview' source={ contents }
                    disableDiagrams={ true }
            />
          </div>
        </div>
        }
      </div>
      <div className='markdown-toolbar-editor' ref={ toolbarRef }>
        <Button data-id='edit-file-save' label="Save" raised onClick={ save }/>
        <Button label="Cancel" onClick={ cancelEdit }/>
      </div>
    </div>
    }

    { !editMode &&
    <div>
      { editable && <IconButton data-id='markdown-edit-button' className='markdown-edit-toggle' icon='edit'
                                onClick={ handleEditButtonClick }/> }
      <Marked transformLinkUri={ linkTransform } toc={ toc } transformImageUri={ imageLinkTransform }
              className='markdown-body' source={ contents }/>
    </div>
    }
  </div>
}

export default MarkdownContent;
