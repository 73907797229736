import * as diagnosticsApi from "api/diagnostics-api";

import { createAsyncAction } from 'typesafe-actions';
import { selectSession,
  selectSearchContinuationToken,
  selectLatestDevicesContinuationToken } from "store/diagnostics-selectors";
import { getDeviceStatistics } from "api/diagnostics-api";

export const fetchSearchSessionsAsync = createAsyncAction(
  'STATISTICS/GET_LATEST_SESSIONS_REQUEST',
  'STATISTICS/GET_LATEST_SESSIONS_SUCCESS',
  'STATISTICS/GET_LATEST_SESSIONS_FAILURE',
)();

export const fetchSessionLogAsync = createAsyncAction(
  'STATISTICS/GET_SESSION_LOG_REQUEST',
  'STATISTICS/GET_SESSION_LOG_SUCCESS',
  'STATISTICS/GET_SESSION_LOG_FAILURE'
)();

export const fetchDeviceStatisticsAsync = createAsyncAction(
  'STATISTICS/GET_DEVICE_STATISTICS_REQUEST',
  'STATISTICS/GET_DEVICE_STATISTICS_SUCCESS',
  'STATISTICS/GET_DEVICE_STATISTICS_FAILURE'
)();

export const fetchDeviceInfoAsync = createAsyncAction(
  'STATISTICS/GET_DEVICE_INFO_REQUEST',
  'STATISTICS/GET_DEVICE_INFO_SUCCESS',
  'STATISTICS/GET_DEVICE_INFO_FAILURE'
)();

export const fetchListOfDeviceSessionsAsync = createAsyncAction(
  'STATISTICS/GET_DEVICE_SESSIONS_REQUEST',
  'STATISTICS/GET_DEVICE_SESSIONS_SUCCESS',
  'STATISTICS/GET_DEVICE_SESSIONS_FAILURE'
)();

export const fetchOlderSessionLiveDiagnosticsAsync = createAsyncAction(
  'STATISTICS/GET_OLDER_SESSION_LIVE_DIAGNOSTICS_REQUEST',
  'STATISTICS/GET_OLDER_SESSION_LIVE_DIAGNOSTICS_SUCCESS',
  'STATISTICS/GET_OLDER_SESSION_LIVE_DIAGNOSTICS_FAILURE'
)();

export const fetchFirstSessionLiveDiagnosticsAsync = createAsyncAction(
  'STATISTICS/GET_FIRST_SESSION_FOR_DIAGNOSTICS_REQUEST',
  'STATISTICS/GET_FIRST_SESSION_FOR_DIAGNOSTICS_SUCCESS',
  'STATISTICS/GET_FIRST_SESSION_FOR_DIAGNOSTICS_FAILURE'
)();

export const fetchRefreshSessionLiveDiagnosticsAsync = createAsyncAction(
  'STATISTICS/GET_REFRESH_SESSION_FOR_DIAGNOSTICS_REQUEST',
  'STATISTICS/GET_REFRESH_SESSION_FOR_DIAGNOSTICS_SUCCESS',
  'STATISTICS/GET_REFRESH_SESSION_FOR_DIAGNOSTICS_FAILURE'
)();

export const loadDeviceStatistics = (projectId, deviceId) => async (dispatch) => {
  dispatch(fetchDeviceStatisticsAsync.request({ projectId, deviceId}));

  try {
    const stats = await getDeviceStatistics(projectId, deviceId);
    dispatch(fetchDeviceStatisticsAsync.success({ projectId, deviceId, stats }));
  } catch (error) {
    console.error(error);
    dispatch(fetchDeviceStatisticsAsync.failure({ error, projectId, deviceId }));
  }
};

export const loadSessionLog = (projectId, deviceId, sessionId) => async (dispatch, getState) => {
  dispatch(fetchSessionLogAsync.request({ projectId, deviceId, sessionId }));

  try {
    const session = selectSession(getState(), projectId, deviceId, sessionId);
    const logDownloadUrl = session?.filePath;

    if (!logDownloadUrl) {
      throw new Error("could not retrieve log url");
    }

    const log = await diagnosticsApi.getSessionLog(projectId, logDownloadUrl);

    dispatch(fetchSessionLogAsync.success({ projectId, deviceId, sessionId, log }));
  } catch (error) {
    console.error(error);
    dispatch(fetchSessionLogAsync.failure({ error, projectId, deviceId, sessionId }));
  }
}

export const loadDeviceInfo = (projectId, deviceId, sessionId) => async (dispatch, getState) => {
  dispatch(fetchDeviceInfoAsync.request({ projectId, deviceId, sessionId }));

  try {
    const deviceInfoDownloadUrl = selectSession(getState(), projectId, deviceId, sessionId)?.deviceInfoPath;

    if (!deviceInfoDownloadUrl)
      throw new Error("could not retrieve device info url");

    const deviceInfo = await diagnosticsApi.getDeviceInfo(projectId, deviceInfoDownloadUrl);

    dispatch(fetchDeviceInfoAsync.success({ projectId, deviceId, sessionId, deviceInfo }));
  } catch (error) {
    dispatch(fetchDeviceInfoAsync.failure({ error, projectId, deviceId, sessionId }));
  }
}


export const searchSessions = (projectId, { fromStart = false, query = "", fromDate, toDate, type }) => async (dispatch, getState) => {
  const state = getState();
  const previousLatestDevicesContinuationToken = fromStart ? null : selectLatestDevicesContinuationToken(state, projectId);
  const previousSearchContinuationToken = fromStart ? null : selectSearchContinuationToken(state, projectId);

  dispatch(fetchSearchSessionsAsync.request({ projectId }));

  try {
    const { sessions,
      searchContinuationToken,
      latestDevicesContinuationToken } = await diagnosticsApi.getLatestSessions(projectId, {
      searchContinuationToken: previousSearchContinuationToken,
      latestDevicesContinuationToken: previousLatestDevicesContinuationToken,
      query,
      fromDate,
      toDate,
      type
    });

    dispatch(fetchSearchSessionsAsync.success({
      projectId, sessions, 
      searchContinuationToken,
      latestDevicesContinuationToken,
      fromStart, query, type }));
  } catch (error) {
    console.error(error);

    dispatch(fetchSearchSessionsAsync.failure({ projectId, error }));
  }
}

export const loadListOfDeviceSessions = (projectId, deviceId) => async (dispatch) => {
  dispatch(fetchListOfDeviceSessionsAsync.request({ projectId, deviceId }));

  try {
    const { sessions } = await diagnosticsApi.getDeviceSessions(projectId, deviceId);

    dispatch(fetchListOfDeviceSessionsAsync.success({ projectId, deviceId, sessions }));
  } catch (error) {
    console.error(error);

    dispatch(fetchListOfDeviceSessionsAsync.failure({ projectId, deviceId, error }));
  }
}

const getSessionLogAndDeviceInfo = async (projectId, deviceId, sessionId, getState) => {
  const sessionData = selectSession(getState(), projectId, deviceId, sessionId);
  const logDownloadUrl = sessionData?.filePath;
  const deviceInfoDownloadUrl = sessionData?.deviceInfoPath;
  let timestamp = sessionData?.reportDateTS;

  if (!logDownloadUrl) {
    throw new Error("could not retrieve log url");
  }

  if (!deviceInfoDownloadUrl) {
    throw new Error("could not retrieve device info url");
  }

  const [deviceInfoData, log] = await Promise.all([
    diagnosticsApi.getDeviceInfo(projectId, deviceInfoDownloadUrl),
    diagnosticsApi.getSessionLog(projectId, logDownloadUrl)
  ]);

  return {
    deviceInfoData,
    log,
    sessionData,
    timestamp
  };
};

export const fetchOlderSessionForLiveDiagnostics = (projectId, deviceId, sessionId) => async (dispatch, getState) => {
  dispatch(fetchOlderSessionLiveDiagnosticsAsync.request({ projectId, deviceId }));

  try {
    let { deviceInfoData, log, sessionData, timestamp } = await getSessionLogAndDeviceInfo(projectId, deviceId, sessionId, getState);

    if (!timestamp) {
      timestamp = log.length ? Number(log[0].ts) : Date.now();
    }

    dispatch(fetchOlderSessionLiveDiagnosticsAsync.success({
      projectId, deviceId, sessionId, log, deviceInfoData, sessionData, timestamp }));
  } catch (error) {
    console.error(error);
    dispatch(fetchOlderSessionLiveDiagnosticsAsync.failure({ error, projectId, deviceId }));
  }
};

export const refreshSessionLiveDiagnostics = (projectId, deviceId, sessionId) => async (dispatch, getState) => {
  dispatch(fetchRefreshSessionLiveDiagnosticsAsync.request({ projectId, deviceId }));

  try {
    let { deviceInfoData, log, sessionData, timestamp } = await getSessionLogAndDeviceInfo(projectId, deviceId, sessionId, getState);

    if (!timestamp) {
      timestamp = log.length ? Number(log[0].ts) : Date.now();
    }

    dispatch(fetchRefreshSessionLiveDiagnosticsAsync.success({
      projectId, deviceId, sessionId, log, deviceInfoData, sessionData, timestamp }));
  } catch (error) {
    console.error(error);
    dispatch(fetchRefreshSessionLiveDiagnosticsAsync.failure({ error, projectId, deviceId }));
  }
};

export const fetchFirstSessionLiveDiagnostics = (projectId, deviceId, sessionId) => async (dispatch, getState) => {
  dispatch(fetchFirstSessionLiveDiagnosticsAsync.request({ projectId, deviceId }));

  try {
    let { deviceInfoData, log, sessionData, timestamp } = await getSessionLogAndDeviceInfo(projectId, deviceId, sessionId, getState);

    if (!timestamp) {
      timestamp = log.length ? Number(log[0].ts) : Date.now();
    }

    dispatch(fetchFirstSessionLiveDiagnosticsAsync.success({
      projectId, deviceId, sessionId, log, deviceInfoData, sessionData, timestamp }));
  } catch (error) {
    console.error(error);
    dispatch(fetchFirstSessionLiveDiagnosticsAsync.failure({ error, projectId, deviceId }));
  }
};
