import * as React from "react";
import { useCallback } from "react";

import css from "./DeviceSessionMenu.module.scss";
import * as moment from "moment";
import { useSelector } from "react-redux";
import { selectDeviceSessions } from "store/diagnostics-selectors";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@rmwc/menu";

const DeviceSessionMenu = (props) => {
  const { projectId, deviceId, sessionId, isOpen = false, onDialogClose, onClick } = props;
  const history = useHistory();

  const deviceSessions = useSelector((state) => (selectDeviceSessions(state, projectId, deviceId)));

  const handleClick = useCallback((e) => {
    const session = deviceSessions[e.detail.index];

    if (session.sessionId !== sessionId) {
      onClick && onClick();

      history.push(`/${ projectId }/diagnostics/${ deviceId }/${ session.sessionId }`);
    }

    onDialogClose && onDialogClose();
  }, [deviceId, deviceSessions , sessionId, history, projectId, onDialogClose, onClick]);

  return (
    <Menu className={ css.menu }
          open={ isOpen }
          onSelect={ handleClick }
          onClose={ onDialogClose }
          focusOnOpen={false}
    >

      { deviceSessions?.map((session, index) =>
        <MenuItem className={ css.menuItem } key={ session.sessionId }
                  activated={ session.sessionId === sessionId }
        >{ moment(session.reportDate).format("MMMM D[,] HH:mm") }</MenuItem>
      ) }
    </Menu>
  );
};


export default DeviceSessionMenu;
