import React, { useEffect, useState } from "react";

import { Button } from '@rmwc/button';
import { List } from '@rmwc/list';

import Member from './Member';

import AddMemberDialog from './AddMemberDialog';
import AddDomainDialog from './AddDomainDialog';

import { SimpleDialog } from '@rmwc/dialog';
import { Card } from '@rmwc/card'
import { Typography } from '@rmwc/typography';

import { refreshProject, getProjectDetails, getProjectMembers, getProjectDomains, getProjectInvites, removeMemberFromProject, updateMemberPermissions, updateDomainPermissions, removeDomainFromProject, removeInviteFromProject } from '../../store/project-actions';
import { connect } from 'react-redux';

import * as errors from '../../common/errors';
import Error from '../common/Error';

import { showNotification } from '../../store/ui-actions';

import * as meta from '../../store/meta';


const Members = ({ projectId, refreshProject, members, domains, invites, userPermissions, getProjectMembers, getProjectDomains, getProjectInvites, removeMemberFromProject, removeDomainFromProject, removeInviteFromProject, ...props }) => {
  const [shareDialog, setShareDialog] = useState(false);
  const [domainDialog, setDomainDialog] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState();
  const [domainToRemove, setDomainToRemove] = useState();
  const [inviteToRemove, setInviteToRemove] = useState();

  useEffect(() => {
    getProjectMembers(projectId);
    getProjectDomains(projectId);
    getProjectInvites(projectId);
  }, [getProjectMembers, getProjectDomains, getProjectInvites, projectId]);

  const removeMemberClicked = (member) => {
    setMemberToRemove(member);
    setConfirmRemove(true);
  }

  const removeDomainClicked = (domain) => {
    setDomainToRemove(domain);
    setConfirmRemove(true);
  }

  const removeInviteClicked = (invite) => {
    setInviteToRemove(invite);
    setConfirmRemove(true);
  }

  const onUpdateMemberPermissions = (member, permissions) => {
    props.showNotification({ message: `Updating access for ${member.email}` })
    props.updateMemberPermissions(projectId, member.userId, permissions);
  }

  const onUpdateDomainPermissions = (domain, permissions) => {
    props.showNotification({ message: `Updating access for ${domain.id}` })
    props.updateDomainPermissions(projectId, domain.id, permissions);
  }

  const projectUrl = `${window.origin}/${projectId}/`;

  const items = members.map((member) =>
    <Member userPermissions={userPermissions} key={member.userId}
      member={member}
      onRemove={removeMemberClicked}
      onUpdateMemberPermissions={onUpdateMemberPermissions} />);

  const domainItems = domains.map((domain) =>
    <Member userPermissions={userPermissions} key={domain.id}
      member={domain}
      onRemove={removeDomainClicked}
      onUpdateMemberPermissions={onUpdateDomainPermissions} />)

  const inviteItems = invites.map((invite) => {
    invite.permissions = {}
    return <Member userPermissions={userPermissions} key={invite.email}
      member={invite}
      onRemove={removeInviteClicked} />
  })

  const confirmationPopup = (displayName, confirmationPromise) => (<SimpleDialog
    title="Confirm"
    body={`Are you sure you want to remove ${displayName}?`}
    open={confirmRemove}
    onClose={evt => {
      setConfirmRemove(false)

      if (evt.detail.action === 'accept') {
        setMemberToRemove(null)
        setDomainToRemove(null)
        props.showNotification({ message: `Removing ${displayName}` })

        confirmationPromise()
          .then(() => props.showNotification({ message: `${displayName} successfully removed.` }))
          .catch((err) => props.showNotification({ message: errors.getErrorMessage(err) }));
      }
    }}
  />)
  return (
    <Card className='settings-card' style={{ marginTop: '1rem' }}>
      <AddMemberDialog
        continueUrl={projectUrl}
        projectId={projectId}
        isOpen={shareDialog}
        onClose={() => setShareDialog(false)} />
      <AddDomainDialog
        continueUrl={projectUrl}
        projectId={projectId}
        isOpen={domainDialog}
        onClose={() => setDomainDialog(false)} />

      {memberToRemove && confirmationPopup(memberToRemove.email, () => removeMemberFromProject(projectId, memberToRemove.userId))}
      {domainToRemove && confirmationPopup(domainToRemove.id, () => removeDomainFromProject(projectId, domainToRemove.id))}
      {inviteToRemove && confirmationPopup(inviteToRemove.email, () => removeInviteFromProject(projectId, inviteToRemove.email))}

      <div style={{ margin: "1rem" }}>
        <Typography use='headline5'>Approved domains</Typography>
        <Button className='add-member-button' onClick={() => setDomainDialog(true)}> Add Domain</Button>
      </div>
      <List twoLine>
        {domainItems}
      </List>
      <div style={{ margin: "1rem" }}>
        <Typography use='headline5'>Members</Typography>
        <Button className='add-member-button' onClick={() => setShareDialog(true)}> Add Member</Button>
      </div>
      <List twoLine>
        {items}
      </List>
      {Boolean(inviteItems.length) && <div style={{ margin: "1rem" }}>
        <Typography use='headline5'>Pending invites</Typography>
      </div>}
      <List twoLine>
        {inviteItems}
      </List>
      {props._meta && props._meta.error && <Error error={props._meta} />}
    </Card>
  );
};

const mapStateToProps = (state, ownProps) => {
  const myUid = state.auth.uid;
  const projectId = ownProps.projectId;

  const [members, mState] = meta.extract(state.projects, projectId, 'members');
  const [domains] = meta.extract(state.projects, projectId, 'domains');
  const [invites] = meta.extract(state.projects, projectId, 'invites');
  const [project] = meta.extract(state.projects, projectId);

  if (members && mState.ready) {
    return {
      _meta: mState,
      loading: false,
      members: !members.list ? [] :members.list.map((m) => ({ ...m, me: myUid === m.userId })),
      domains: domains && domains.list ? domains.list.map((m) => ({ ...m, me: myUid === m.userId })) : [],
      invites: invites && invites.list ? invites.list.map((m) => ({ ...m, me: myUid === m.userId })) : [],
      userPermissions: project.permissions || {}
    }
  } else {
    return {
      loading: true,
      members: [],
      domains: [],
      invites: []
    }
  }
}

const mapDispatchToProps = { refreshProject, getProjectDetails, getProjectMembers, getProjectDomains, getProjectInvites, removeMemberFromProject, updateMemberPermissions, updateDomainPermissions, removeDomainFromProject, removeInviteFromProject, showNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Members);