import React from "react";
import { Switch, Route } from "react-router-dom";

import { DialogQueue } from '@rmwc/dialog'

import Layout from "./Layout/Layout";
import Project from "./Project/Project";
import FirebaseLogin from "./Login/FirebaseLogin";
import Home from "./Home/Home";
import Install from './Install/Install'
import Settings from "./Settings/Settings";
import ProjectRoute from './Project/ProjectRoute';
import Invited from './Invite/Invited.jsx';
import AcceptInvite from './Invite/AcceptInvite.jsx';
import TitleSetter from './Layout/TitleSetter.jsx';
import { queue } from './common/dialog-queue';

const App = () => {
  return (
    <>
    <Route path="/:projectId?/*" component={TitleSetter}/>
    <Switch>
      <Route path="/login" component={FirebaseLogin} />
      <Route path="/:projectId/invited" component={Invited}/>
      <Route path="/:projectId/acceptInvite" component={AcceptInvite}/>

      <Route path="/:projectId?/*">
        <Layout>
            <Switch>
              <ProjectRoute path="/:projectId/install/:name/:version/:file*" component={Install}/>
              <ProjectRoute path="/:projectId/settings/" component={Settings}/>
              <ProjectRoute path="/:projectId/" component={Project}/>
              <Route welcome component={Home}/>
            </Switch>
        </Layout>
      </Route>
    </Switch>
    <DialogQueue dialogs={queue.dialogs}/>
    </>
  );
};

export default App;
