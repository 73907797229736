import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Notification from './Notification';
import EmailVerification from './EmailVerification';

import AppBar from './AppBar';
import { meta, getErrorCodeAndMessage } from 'store/meta'
import Error from 'components/common/Error';
import { CircularProgress } from '@rmwc/circular-progress';
import cn from "classnames";
import { useQueryStringParams } from "common/hooks/useQueryStringParams";

const App = ({ children, authReady, auth, projectId, footerVisibility }) => {

  const { shared: sharedToken = '' } = useQueryStringParams()
  const withoutToken = window.location.pathname;
  const err = getErrorCodeAndMessage(auth);

  const [appReady, setAppReady] = useState(false);

  useEffect(() => {
    if (!authReady) {
      return;
    }

    setAppReady(true);
  }, [authReady]);
  //cases
  // 1. Firebase ready -> authReady
  // 2. Firebase ready -> login with shared token
  // 3. Firebase ready -> no user logged in
  // 4. Firebase ready -> user logged in
  // 5. F

  const renderChildren = authReady && appReady && !auth._meta.loading && !err;

  return (
    <div>
      <AppBar projectId={projectId}/>
      <EmailVerification/>
      {!renderChildren && <CircularProgress className='full-page-loader' size={128}/> }
      <div className='drawer-container'>
        <div className={ cn("main-panel") }>
          { renderChildren && children }
          <Error error={ err }>
            { sharedToken && err.errorCode === 404 &&
            <a href={ `/login?url=${ encodeURIComponent(withoutToken) }` }>Already have an account? Sign in
              instead</a> }
          </Error>
        </div>
        <Notification/>
      </div>
        { footerVisibility && <div className="footer"></div> }
    </div>
  );
}

const mapDispatchToProps = {}

const mapStateToProps = (state, ownProps) => {
  const authReady = meta(state.auth).ready;
  const { match : { params : {  projectId } } }=  ownProps;
  return {
    projectId,
    authReady,
    auth: state.auth,
    footerVisibility: state._ui.footerVisibility
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
