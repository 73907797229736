import * as React from "react";
import { useEffect, useCallback, useState } from "react";
import * as moment from "moment";
import { LinearProgress } from "@rmwc/linear-progress";
import { Grid, GridCell, GridRow } from "@rmwc/grid";
import { Button } from '@rmwc/button';
import { Typography } from "@rmwc/typography";
import { useDispatch, useSelector } from "react-redux";
import { searchSessions } from "store/diagnostics-actions";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  selectLatestSessions,
  selectLatestSessionsMeta,
  selectSearchContinuationToken,
  selectLatestDevicesContinuationToken} from "store/diagnostics-selectors";
import SessionSearch from "components/Project/Diagnostics/SessionList/SessionSearch";
import { useQueryStringParams } from "common/hooks/useQueryStringParams";
import {
  DiagnosticTable, DiagnosticTableHeader, DiagnosticTableBody, 
  DiagnosticTableRow, DiagnosticTableCell, DiagnosticTableMultiRow
} from 'components/Project/Diagnostics/SessionList/DataTable/LogDataTable';

import css from "./SessionList.module.scss";

const SessionList = (props) => {
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const history = useHistory();
  const { projectId } = match.params;

  const meta = useSelector((state) => selectLatestSessionsMeta(state, projectId) || {});
  const sessions = useSelector((state) => selectLatestSessions(state, projectId) || []);
  const searchContinuation = useSelector((state) => selectSearchContinuationToken(state, projectId));
  const latestDevicesContinuation = useSelector((state) => selectLatestDevicesContinuationToken(state, projectId));
  const { q: query = "", fromDate, toDate, type } = useQueryStringParams();

  useEffect(() => {
    setLoadingMoreData(false);
    dispatch(searchSessions(projectId, { fromStart: true, query, fromDate, toDate, type }));
  }, [projectId, dispatch, query, fromDate, toDate, type]);

  const loadMoreData = useCallback(() => {
    setLoadingMoreData(true);
    dispatch(searchSessions(projectId, { fromStart: false, query, fromDate, toDate, type}));
  }, [dispatch, projectId, query, fromDate, toDate, type]);

  const isLoading = !meta.ready && !loadingMoreData;
  const isLoadingMoreData = !meta.ready && loadingMoreData;
  const isLatestSession = !query && !fromDate && !toDate;

  const diagnosticTable = renderDiagnosticTable(projectId, sessions, history, isLatestSession, query);

  // Check for errors
  if (meta.error) {
    return (
      <div> { meta.error.errorMessage } </div>
    );
  }

  return (
    <Grid style={ { padding: 0 } } className={ css.diagnosticGrid }>

      <GridRow style={{paddingBottom: '24px'}}>
        <GridCell desktop={ 8 } phone={ 12 } align="middle">
          <Typography use="headline5">Latest reported devices</Typography>
        </GridCell>
      </GridRow>

      <GridRow className={ css.searchGrid }>
        <GridCell span={ 12 }>
          <SessionSearch projectId={ projectId }/>
        </GridCell>
      </GridRow>

      <GridRow style={{marginTop: '8px'}}>
        <GridCell span={ 12 }>
          { isLoading ? <LinearProgress size="xlarge"/> : diagnosticTable }
        </GridCell>
      </GridRow>

      {
        (searchContinuation || latestDevicesContinuation) && !isLoading ?
        <GridRow style={{marginTop: '10px'}}>
          <GridCell span={12}>
            { 
              isLoadingMoreData ? 
              <LinearProgress size="xlarge"/>
              :
              <Button
                style={{ width: '100%' }}
                label="Load more data"
                outlined
                onClick={loadMoreData}></Button>
            }
          </GridCell>
        </GridRow>
        :
        <></>
      }
    </Grid>
  );
};

export default SessionList;

function handleRowClick(index, projectId, sessions, history, isLatestSession) {
  const deviceId = sessions[index].id;
  
  if (isLatestSession) {
    history.push(`/${ projectId }/diagnostics/${ deviceId }`);
  } else {
    const sessionId = sessions[index].sessionId;
    history.push(`/${ projectId }/diagnostics/${ deviceId }/${ sessionId }`);
  }
}

function renderDiagnosticTable(projectId, sessions, history, isLatestSession, query) {
  if (!sessions) {
    return <LinearProgress size="xlarge"/>;
  }
  
  const body = sessions.map((data, index) => renderDiagnosticTableBody(data, index, projectId, sessions, history, isLatestSession, query));

  return (
    <DiagnosticTable className={ css.overrideDiagnosticTable }>
      <DiagnosticTableHeader>
        <DiagnosticTableRow>
          <DiagnosticTableCell> Device Name </DiagnosticTableCell>
          <DiagnosticTableCell> Model </DiagnosticTableCell>
          <DiagnosticTableCell> Updated At </DiagnosticTableCell>
          <DiagnosticTableCell> User ID </DiagnosticTableCell>
        </DiagnosticTableRow>
      </DiagnosticTableHeader>
      <DiagnosticTableBody>
        { body }

        { 
          sessions.length === 0 &&
          <DiagnosticTableRow>
            <DiagnosticTableCell >No sessions found.</DiagnosticTableCell>
          </DiagnosticTableRow>
        }
    </DiagnosticTableBody>
  </DiagnosticTable>
  );
}

function renderDiagnosticTableBody(data, index, projectId, sessions, history, isLatestSession, query) {
  const {
    type, reportDate, deviceName,
    deviceModel, userId, message
  } = data;

  const cells = [
    [deviceName],
    [deviceModel],
    [moment(reportDate).format("DD.MM [at] HH:mm")],
    [userId]
  ];

  const tableCells = cells.map(([value, className], index) => 
    <DiagnosticTableCell key={index}>
      { value }
    </DiagnosticTableCell>
  );

  if (type === 'message') {
    return (
      <DiagnosticTableMultiRow
        key={index}
        className={ css.clickableRow }
        onClick={() => handleRowClick(index, projectId, sessions, history, isLatestSession)}>
        <DiagnosticTableRow>
          { tableCells }
        </DiagnosticTableRow>
        <DiagnosticTableRow>
          <DiagnosticTableCell className={ css.fadedMessageCell }>
            { getHighlightedText(message, query) }
          </DiagnosticTableCell>
        </DiagnosticTableRow>
      </DiagnosticTableMultiRow>
    );
  }

  return (
    <DiagnosticTableRow
      key={index}
      className={ css.clickableRow }
      onClick={() => handleRowClick(index, projectId, sessions, history, isLatestSession)}>
      { tableCells }
    </DiagnosticTableRow>
  );
}

function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return <span> { parts.map((part, i) => 
      <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', color: 'black' } : {} }>
          { part }
      </span>)
  } </span>;
}
