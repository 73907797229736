export const selectSession = (state, projectId, deviceId, sessionId) => {
  const allSessions = [
    ...state.diagnostics.sessions[`${ projectId }`] || [],
    ...state.diagnostics.devicesSessions[`${ projectId }`]?.[`${ deviceId }`] || [],
  ];

  return allSessions.find((e) =>
    e.id === deviceId &&
    e.sessionId === sessionId);
};

export const selectQueryForProject = (state, projectId) => state.diagnostics.queriesForSessions[projectId];

export const selectSessionDeviceInfo = (state, projectId, deviceId, sessionId) =>
  state.diagnostics.deviceInfo[`${ projectId }-${ deviceId }-${ sessionId }`];

export const selectDeviceSessions = (state, projectId, deviceId) =>
  state.diagnostics.devicesSessions[`${ projectId }`] &&
  state.diagnostics.devicesSessions[`${ projectId }`][`${ deviceId }`] ?
    state.diagnostics.devicesSessions[`${ projectId }`][`${ deviceId }`] : [];

export const selectDeviceSessionsMeta = (state, projectId, deviceId) => 
  state.diagnostics.devicesSessionsMeta[`${ projectId }`] &&
  state.diagnostics.devicesSessionsMeta[`${ projectId }`][`${ deviceId }`] ?
    state.diagnostics.devicesSessionsMeta[`${ projectId }`][`${ deviceId }`] : null;

export const selectLatestSessions = (state, projectId) =>
  state.diagnostics.sessions[`${ projectId }`] || null;

export const selectLatestSessionsMeta = (state, projectId) =>
  state.diagnostics.sessionsMeta?.[`${ projectId }`] || null;

export const selectSearchContinuationToken = (state, projectId) =>
  state.diagnostics.searchContinuationTokens[`${ projectId }`];

export const selectLatestDevicesContinuationToken = (state, projectId) =>
  state.diagnostics.latestDevicesContinuationTokens[`${ projectId }`];

export const selectSessionLogs = (state, projectId, deviceId, sessionId) =>
  state.diagnostics.sessionLogs[`${ projectId }-${ deviceId }-${ sessionId }`];

export const selectSessionLogsMeta = (state, projectId, deviceId, sessionId) =>
  state.diagnostics.sessionLogsMeta[`${ projectId }-${ deviceId }-${ sessionId }`];

export const selectDeviceStatistics = (state, projectId, deviceId) =>
  state.diagnostics.deviceStatistics[`${ projectId }-${ deviceId }`];

export const selectDeviceStatisticsMeta = (state, projectId, deviceId) =>
  state.diagnostics.deviceStatisticsMeta[`${ projectId }-${ deviceId }`];

export const selectLiveLogsSessions = (state, projectId, deviceId) =>
  state.diagnostics.liveLogsSessions[`${ projectId }-${ deviceId }`];

export const selectOlderSessionLiveMeta = (state, projectId, deviceId) =>
  state.diagnostics.olderSessionLiveMeta[`${ projectId }-${ deviceId }`];

export const selectFirstSessionLiveMeta = (state, projectId, deviceId) =>
  state.diagnostics.firstSessionLiveMeta[`${ projectId }-${ deviceId }`];

export const selectRefreshSessionLiveMeta = (state, projectId, deviceId) =>
  state.diagnostics.refreshSessionLiveMeta[`${ projectId }-${ deviceId }`];
