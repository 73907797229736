import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Grid, GridCell } from "@rmwc/grid";
import { Chip } from '@rmwc/chip';
import css from "./Overview.module.scss";
import { connect } from 'react-redux';
import { extract } from 'store/meta';
import { Select } from '@rmwc/select';
import { loadFileContent } from "../../store/project-actions";
import * as meta from '../../store/meta';
import { parseCSV, isSameDate, formatMinutesToDaysHoursMinutesFormat } from "common/utils";
import TimelineChart from './TimelineChart';
import chipCss from 'components/Project/Diagnostics/Session/DataTable/LogDataTable.module.scss';
import { useHistory } from 'react-router-dom';

const Progress = (props) => {
    const { progressFiles, projectId, progressFilesContent, loadFileContent } = props;
    const [selectedProject, setSelectedProject] = useState('');
    const [content, setContent] = useState();
    const [usersWorkingOnIssues, setUsersWorkingOnIssues] = useState([]);
    const [selectedUser, setSelectedUser] = useState("All");
    const [cycleTimeData, setCycleTimeData] = useState([]);
    const [cycleTimeOverTimeData, setCycleTimeOverTimeData] = useState([]);
    const history = useHistory();

    const selectedProjectFromUrl = useMemo(() => {
        return new URLSearchParams(history.location.search).get('project');
    }, [history.location.search]);

    useEffect(() => {
        if (!selectedProject && progressFiles) {
            const selectedProject = Object.keys(progressFiles)[0];
            const selectedFileFullName = progressFiles[selectedProject].path;

            setSelectedProject(selectedProjectFromUrl || selectedProject);

            loadFileContent(selectedFileFullName, projectId);
        }
    }, [selectedProject, progressFiles, projectId, loadFileContent, selectedProjectFromUrl]);

    useEffect(() => {
        if (selectedProject) {
            const selectedFileFullName = progressFiles[selectedProject].path;
            const content = progressFilesContent[selectedFileFullName];

            if (content) {
                const fileContent = progressFilesContent[selectedFileFullName].content;
                const parsedContent = parseCSV(fileContent);

                setContent(parsedContent);

                const allUsers = parsedContent.map((v) => (v['whoWorkedOnThisName'] || '').split(',')).reduce((pV, cV) => [...pV, ...cV], []);
                const uniqueUsers = [...new Set(allUsers)];
                setUsersWorkingOnIssues(uniqueUsers.sort());
            }
        }
    }, [progressFilesContent, selectedProject, progressFiles]);

    useEffect(() => {
        if (content) {
            const filteredUsers = selectedUser !== 'All' ? content.filter(({ whoWorkedOnThisName }) => (whoWorkedOnThisName || '').split(',').includes(selectedUser)) : content;

            const cycleTimeData = filteredUsers.map((v) => ({ x: new Date(v['issueEndDate']), y: parseFloat(v['time']), data: v }));

            const uniqueDates = [...new Set(filteredUsers.map((v) => new Date(v['issueEndDate']).toDateString()))]
                .sort((a, b) => new Date(a) - new Date(b));

            const cycleTimeOverTimeData = uniqueDates.map((date) => {
            const numberOfTickets = filteredUsers.filter((ax) => isSameDate(date, ax['issueEndDate'])).length;

                return {
                    x: new Date(date),
                    y: numberOfTickets,
                    data: {
                        numberOfTickets,
                    },
                };
            });

            setCycleTimeData(cycleTimeData);
            setCycleTimeOverTimeData(cycleTimeOverTimeData);
        }
    }, [content, selectedUser]);

    const renderCycleTimeDataInfoElement = useCallback((averageValue, totalValue, min, max, medianValue) => {
        return <>
            <div>Average: <Chip className={ chipCss.logChip } label={formatMinutesToDaysHoursMinutesFormat(averageValue)} /></div>
            <div>Median: <Chip className={ chipCss.logChip } label={formatMinutesToDaysHoursMinutesFormat(medianValue)} /></div>
            <div>Issues: <Chip className={ chipCss.logChip } label={totalValue.toLocaleString()} /></div>
            <div>Min: <Chip className={ chipCss.logChip } label={formatMinutesToDaysHoursMinutesFormat(min)} /></div>
            <div>Max: <Chip className={ chipCss.logChip } label={formatMinutesToDaysHoursMinutesFormat(max)} /></div>
        </>
    }, []);

    const renderCycleOverTimeDataInfoElement = useCallback((averageValue, totalValue, min, max, medianValue) => {
        return <>
            <div>Average: <Chip className={ chipCss.logChip } label={`${averageValue ? averageValue.toFixed(1) : 0} tickets`} /></div>
            <div>Median: <Chip className={ chipCss.logChip } label={`${medianValue ? medianValue.toFixed(1) : 0} tickets`} /></div>
            <div>Days: <Chip className={ chipCss.logChip } label={totalValue.toLocaleString()} /></div>
        </>
    }, []);

    return (
        <Grid className={css.container}>
            <GridCell span={12}>
                <div>
                    <h1>Progress</h1>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                        <Select
                            label="Project"
                            value={selectedProject}
                            onChange={(project) => {
                                const selectedProject = project.detail.value;
                                const selectedFileFullName = progressFiles[selectedProject].path;

                                setSelectedProject(selectedProject);
                                loadFileContent(selectedFileFullName, projectId);

                                history.replace({ pathname: `/${projectId}/pantarhei`, search: `?project=${selectedProject}` });

                                setSelectedUser('All');
                            }}
                            options={Object.keys(progressFiles)}
                        />
                        <Select
                            label="User"
                            value={selectedUser}
                            onChange={(user) => setSelectedUser(user.detail.value)}
                            options={['All', ...usersWorkingOnIssues]}
                        />
                    </div>

                    {content && content.length !== 0 && <>
                        <TimelineChart
                            data={cycleTimeData}
                            svgHeight={350}
                            xAxisLabel='TICKET TRANSITION DATE'
                            yAxisLabel="ELAPSED TIME (DAYS)"
                            infoDataElement={renderCycleTimeDataInfoElement}
                            isTimelineChartBusiness={true} />

                        <TimelineChart
                            data={cycleTimeOverTimeData}
                            svgHeight={350}
                            xAxisLabel='DATE'
                            yAxisLabel="NUMBER OF TICKETS"
                            infoDataElement={renderCycleOverTimeDataInfoElement} />
                    </>}
                </div>
            </GridCell>
        </Grid>
    );
}

const mapStateToProps = (state, ownProps) => {
    const { projectId } = ownProps;
    const [project] = extract(state.projects, projectId);
    const [files, _m] = meta.extract(state.files, projectId, 'files');

    const csvMimeType = 'text/csv';
    const progressFiles = (files || [])
        .filter((file) => file.contentType === csvMimeType && file.dirname.toLocaleLowerCase() === '/ws/.pantarhei')
        .reduce((pV, cV) => ({ ...pV, [cV.basename]: cV }), {});

    const progressFullPathFileNames = Object.values(progressFiles).map(({ path }) => path);
    const progressFilesContent = Object.entries(state.content)
        .filter(([filename]) => progressFullPathFileNames.includes(filename))
        .reduce((pV, [filename, content]) => ({ ...pV, [filename]: content }), {});

    return {
      ...project,
      progressFiles,
      progressFilesContent,
      refresh: _m.refresh,
      loading: _m.loading,
    }
  }

  export default connect(mapStateToProps, { loadFileContent })(Progress);
