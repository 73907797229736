import { firestore } from 'components/common/Firebase';
import { useEffect } from "react";

export const useProjectChanges = (projectId, key, onChange) => {
    useEffect(() => {
        const screenKey = projectId + key
        subscribe(projectId, screenKey, onChange)

        return () => {
            unSubscribe(screenKey)
        }

    }, [projectId, key, onChange]);
}

const buildChangesSubscriptions = []
export const subscribe = (projectId, screenKey, onChange) => {

    function subscribeToCollection(subsList, collection) {
        if (!subsList[screenKey]) {
            let initPayload = false
            subsList[screenKey] = collection.onSnapshot(() => {
                if (initPayload) {
                    onChange(projectId)
                }
                initPayload = true
            })
        }
    }

    subscribeToCollection(buildChangesSubscriptions, firestore.collection('projects').doc(projectId))
}

export const unSubscribe = (key) => {

    function deleteSubscription(subList, key) {
        if (subList[key]) {
            subList[key]()
            delete subList[key]
        }
    }

    deleteSubscription(buildChangesSubscriptions, key)
}