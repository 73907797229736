import React, { useState, useEffect, useCallback } from 'react';
import cn from "classnames";

import DeviceInfoTable from 'components/Project/Diagnostics/Session/DataTable/DeviceInfoTable';
import LogRowDetails from 'components/Project/Diagnostics/Session/DataTable/LogRowDetails';
import LogDataTable from 'components/Project/Diagnostics/Session/DataTable/LogDataTable';

import { IconButton } from "@rmwc/icon-button";
import { Tooltip } from "@rmwc/tooltip";
import { Grid, GridRow, GridCell } from "@rmwc/grid";
import { LinearProgress } from "@rmwc/linear-progress";

import css from './SessionLogDataTable.module.scss';

const SessionLogDataTable = React.memo((props) => {
  const {
    data,
    loadingLiveLogs,
    loadingOlderLogs,
    projectId,
    deviceId,
    liveLogs,
    onScroll} = props;

  if (!data || !Array.isArray(data)) {
    return;
  }

  const [isDeviceInfoOpen, setIsDeviceInfoOpen] = useState(true);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [deviceInfoGraphData, setDeviceInfoGraphData] = useState(null);
  const [logRowDetailsData, setLogRowDetailsData] = useState(null);
  const [logRowDetailsIndex, setLogRowDetailsIndex] = useState(null);

  const rowLogClick = useCallback((log, index, sessionData) => {
    if (log === null) {
      setLogRowDetailsIndex(null);
      setLogRowDetailsData(null);
    } else {
      setLogRowDetailsIndex(index);
      setLogRowDetailsData(log);
    }

    if (sessionData) {
      setDeviceInfo(sessionData);
    }
  }, []);

  useEffect(() => {
    if (data.length) {
      setDeviceInfo(data[data.length - 1].deviceInfo);
    }
  
    if (data.length && !liveLogs) {
      setDeviceInfoGraphData(data[data.length - 1].deviceInfoGraphData);
    }
  }, [data, liveLogs]);

  return (
    <div className={ css.logsContainer }>
      {
        !isDeviceInfoOpen &&
        <div className={ css.deviceInfoToggle }>
          <Tooltip content={isDeviceInfoOpen ? `Close device info` : `Open device info` }>
            <IconButton
              onClick={ () => setIsDeviceInfoOpen(true) }
              icon="menu_open"
              className={ css.closeButton }/>
          </Tooltip>
        </div>
      }

      {
        /* Loader for older logs  */ 
        <GridRow>
          <GridCell span={12}>
            <LinearProgress closed={!loadingOlderLogs} size="xlarge"/>
          </GridCell>
        </GridRow>
      }

      <Grid className={ css.logsWrapper }>
        <GridRow className={ css.logSession }>

          { /* Grid cell for table */ }
          <GridCell span={ isDeviceInfoOpen ? 9 : 12 } className={ cn(css.logs) }>
            <LogDataTable
              highlightedIndex={logRowDetailsIndex}
              data={data}
              liveLogs={liveLogs}
              onLogRowClick={rowLogClick}
              onScroll={onScroll} />

            {
              <div className={ css.loader }>
                <LinearProgress closed={!loadingLiveLogs} size="xlarge"/>
              </div>
            }
          </GridCell>

          {
            /* Grid cell for device info */
            isDeviceInfoOpen &&
            <GridCell span={3} className={ css.deviceInfo }>
              <DeviceInfoTable
                projectId={projectId}
                deviceId={deviceId}
                deviceInfo={deviceInfo}
                deviceInfoGraphData={deviceInfoGraphData}
                onClose={() => setIsDeviceInfoOpen(false)} />
            </GridCell>
          }

        </GridRow>
      </Grid>

      <LogRowDetails line={logRowDetailsData} />
    </div>
  );
});

export default SessionLogDataTable;

