import { auth } from 'components/common/Firebase';
import { getErrorMessage} from 'common/errors';
import { TYPES, USER_LOGOUT, CLEAR_STATE } from 'store/auth-types';

export const currentUserChanged = (newUser) => (dispatch, getState) => {
  setTimeout(() => {
    const before = getState();
    if (newUser) {
      if (before.auth.uid && before.auth.uid !== newUser.uid) {
        dispatch({ type: USER_LOGOUT } );
      }
      dispatch({ type: 'AUTH_USER_CHANGED', payload: { ...newUser, authenticated: true }});
    } else {
      dispatch({ type: 'AUTH_USER_CHANGED', payload: { authenticated: false }} );
    }
  });
}

const getSignupErrorMessage = (error) => {
  if (error.code) {
    switch (error.message) {
      case 'auth/email-already-in-use':
        return 'This email is already used. Please log in.';
      case 'auth/network-request-failed':
        return 'Could not reach our servers. Please retry.'
      default:
        return error.message;
    }
  }
  return getErrorMessage(error);
}

export const verifyEmail = (url) => () => {
  auth().currentUser.sendEmailVerification({ url });
}

export const createEmailAccount = (usr) => (dispatch) => {
  dispatch( { type: TYPES.CREATE_EMAIL_ACCOUNT_REQUEST });
  return auth().createUserWithEmailAndPassword(usr.email, usr.password)
    .then(({user}) => {
      return user.updateProfile({displayName: usr.displayName}).then(() => user);
    })
    .then((user) => {
      dispatch({ type: TYPES.CREATE_EMAIL_ACCOUNT_REQUEST_SUCCESS, payload: { ...user }});
      return { ok: true };
    })
    .catch((error) => {
      dispatch({ type: TYPES.CREATE_EMAIL_ACCOUNT_REQUEST_FAILURE, error})
      return { ok: false, error, errorMessage: getSignupErrorMessage(error) }
    });
}

export const logout = () => (dispatch) => {
  dispatch(() => auth().signOut());
  dispatch({ type: USER_LOGOUT });
}

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_STATE });
}