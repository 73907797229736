import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { deleteProject, saveLiveLogSettings } from '../../store/project-actions';
import { showNotification } from '../../store/ui-actions';


import { Typography } from '@rmwc/typography';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { TextField } from '@rmwc/textfield'
import { Card } from '@rmwc/card'

const LiveLogs = ({ project, projectId, projectName, saveLiveLogSettings, ...props }) => {
    const seconds = project.liveLogsSeconds || 120; // 2 min
    const payloadSize = project.liveLogsPayloadSize || 128 * 1024; // 128 KB
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [savingLiveLogsSettings, setSavingLiveLogsSettings] = useState(false);
    const [secondsValid, setSecondsValid] = useState(true);
    const [sizePayloadValid, setSizePayloadValid] = useState(true);
    const [secondsValue, setSecondsValue] = useState(seconds);
    const [payloadSizeValue, setPayloadSizeValue] = useState(payloadSize);
  
    useEffect(() => {
      setSaveEnabled(secondsValue !== seconds || payloadSize !== payloadSizeValue);
    }, [secondsValue, seconds, payloadSize, payloadSizeValue]);
  
    const onSaveProject = () => {
      props.showNotification({ message: `Updating ${projectName}` })
      setSavingLiveLogsSettings(true)
      saveLiveLogSettings(projectId, secondsValue, payloadSizeValue)
        .then(() => { setSavingLiveLogsSettings(false); props.showNotification({ message: `${projectName} updated` }) })
        .catch(() => { setSavingLiveLogsSettings(false); props.showNotification({ message: `Could not update ${projectName}.` })})
    }
  
    const savingLoaderIcon = savingLiveLogsSettings ? <CircularProgress theme='onPrimary'/> : null;
  
    return <Card className='settings-card' style={{padding:'0.5rem 1.5rem 1.5rem 1.5rem', marginTop:'1rem'}}>
      <Typography use='body1'>
        Settings for devices how to send logs to the server. When one of the rules below is met, the SDK sends the collected logs.
      </Typography>
      <div className='settings-headline'>
        <Typography use='headline5'> Minimum number of seconds between log reports </Typography>
        <div>
          <TextField
            data-id='general-settings-build-nbr'
            label='Seconds' className='add-member-dialog-email-field'
            type='number'
            value={secondsValue}
            min='1'
            step='1'
            style={{width: '50%'}}
            onClick={(e) => e.target.select()}
            onChange={(e) => { setSecondsValid(e.currentTarget.value >= 1); setSecondsValue(e.currentTarget.value > 0 && parseInt(e.currentTarget.value)) }} />
        </div>
      </div>

      <div className='settings-headline'>
        <Typography use='headline5'> Maximum size of log in bytes to be sent </Typography>
        <div>
          <TextField
            data-id='general-settings-build-nbr'
            label='Bytes' className='add-member-dialog-email-field'
            type='number'
            value={payloadSizeValue}
            min=''
            step='1'
            style={{width: '50%'}}
            onClick={(e) => e.target.select()}
            onChange={(e) => { setSizePayloadValid(e.currentTarget.value >= 1); setPayloadSizeValue(e.currentTarget.value > 0 && parseInt(e.currentTarget.value)) }} />
        </div>
      </div>

      <div>
        <Button data-id='save-button' disabled={!saveEnabled || !secondsValid || !sizePayloadValid} onClick={() => onSaveProject()} icon={savingLoaderIcon} raised label='Save' style={{marginLeft: '1rem'}} />
      </div>
    </Card>
};

const mapDispatchToProps = { deleteProject, showNotification, saveLiveLogSettings }
const stateToProps = (state, ownProps) => {
  return { project: state.projects && state.projects[ownProps.projectId] }
}

export default connect(stateToProps, mapDispatchToProps)(LiveLogs);