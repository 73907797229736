import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@rmwc/typography';
import { Grid, GridCell } from "@rmwc/grid";
import { extract } from 'store/meta';
import { groupsBuilds } from 'components/Project/projects-utils';
import css from "./Overview.module.scss";
import ProjectHelp from 'components/Project/ProjectHelp';
import MarkdownContent from 'components/Content/MarkdownContent';
import BuildActions from './BuildActions';
import cn from "classnames";
import * as moment from 'moment';
import LinkWithToken from 'components/common/LinkWithToken';
import WithPermission from 'components/common/WithPermission';

const OverviewNoContent = ({ name }) => {
  return <ProjectHelp>
    <p>
      <Typography use='headline2'> { name } </Typography>
    </p>
  </ProjectHelp>
}

const BuildOverview = ({ build, projectId }) => {
  return <div className={ css.buildOverview }>
    <ul>
      <li><Typography use='headline4' tag='h4'><LinkWithToken data-testid='build-title' className={ css.buildLink }
                                                              to={ `/${ projectId }/builds/${ build.name }/` }
                                                              data-name={build.name}
      > { build.name }</LinkWithToken>
      </Typography></li>
      <li><Typography use='subtitle1'>{ moment(build.createdAt).format('LLL') }</Typography></li>
      <li><Typography use='subtitle'>Latest version: <code data-value={build.version}> { build.version }</code></Typography></li>
    </ul>
    <BuildActions build={ build } projectId={ projectId } raised/>
  </div>

};

const Overview = ({ projectId, name, builds, buildsInfo, files, filesInfo }) => {
  const hasContent = !buildsInfo.ready || !filesInfo.ready || builds.length > 0 || files.length > 0;
  const [editMode, setEditMode] = useState(false);

  if (!hasContent) return <OverviewNoContent/>;

  const readme = files.find((f) => f.name.match(/^readme(\..*)?$/gi))
  let readmeEl = null;
  if (readme) {
    readmeEl = (
      <WithPermission permission='editFiles' pass={ { editable: true } }>
        <MarkdownContent projectId={ projectId }
                         refresh={ filesInfo.refresh }
                         path={ readme.path }
                         editMode={ editMode }
                         onEditModeChange={ (value) => setEditMode(value) }
        />
      </WithPermission>
    );
  }

  return (
    <Grid className={css.container}>
      <GridCell span={ editMode ? 0 : 4 } className={ cn({ [css.hidden]: editMode }) }>
        { builds.map((build) =>
          <BuildOverview key={ build.name + build.version }
                         build={ build }
                         projectId={ projectId }
          />) }
      </GridCell>
      <GridCell span={ editMode || builds.length === 0 ? 12 : 8 }>
        { readmeEl }
      </GridCell>
    </Grid>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { projectId } = ownProps;
  const [project] = extract(state.projects, projectId);

  const [builds, bMeta] = extract(state.builds, projectId, 'builds');
  const [files, fMeta] = extract(state.files, projectId, 'files');

  return {
    projectId,
    ...project,
    buildsInfo: bMeta,
    filesInfo: fMeta,
    builds: groupsBuilds(builds || []),
    files: files || []
  }
}

export default connect(mapStateToProps)(Overview);