import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { stateUnknown } from "../../store/meta";
import { loadFileContent } from "../../store/project-actions";
import * as d3 from "d3";
import useComponentSize from "@rehooks/component-size";
import { CSVToArray, basename } from "common/utils";
import { cloneDeep } from "lodash";
import toMaterialStyle from "material-color-hash";
import { Typography } from "@rmwc/typography";
import "./content.scss";
import "components/Files/files.scss";
import { rightWeightedBinarySearch } from 'common/utils';

const GraphContent = ({ projectId, refresh, path, newFile, content, loadFileContent }) => {
  const [contents, setContents] = useState();
  const [legend, setLegend] = useState([]);
  const [hoveredPoints, setHoveredPoints] = useState({});

  const csvLineChartContainer = useRef(null);
  const { width: svgWidth } = useComponentSize(csvLineChartContainer);
  const svgHeight = 550;
  const margin = useRef({ top: 50, right: 60, bottom: 110, left: 60 });

  useEffect(() => {
    setContents(content);
  }, [content])

  useEffect(() => {
    if ((path && !newFile) || refresh) {
      loadFileContent(path, projectId);
    }
  }, [path, loadFileContent, newFile, refresh, projectId]);

  useEffect(() => {
    const timestampColumnNames = ["ts", "timestamp", "date"];

    const isValuePlottable = (value) => {
      return value !== null && value !== "" && !isNaN(+value);
    };

    const isTimestampNumber = (value) => {
      const numericRegex = /^\d+$/;

      return numericRegex.test(value);
    };

    const handleTimestampToDate = (value) => {
      if (isTimestampNumber(value)) {
        return parseFloat(value);
      }

      return new Date(value);
    };

    const getAxisTickValues = (axis) => {
      let tickValues = axis.ticks(8);
      const oddOrEven = tickValues.length % 2;
      if (tickValues.length > 8) {
        tickValues = tickValues.filter((_, idx) => idx % 2 === oddOrEven);
      }

      if (tickValues.length > 2) {
        const diffBetweenTicks = Number(tickValues[1]) - Number(tickValues[0]);
        const [domainMin, domainMax] = axis.domain();
        const diffToMin = Number(tickValues[0]) - Number(domainMin);
        if (diffToMin < diffBetweenTicks / 2) {
          tickValues.shift();
        }
        const diffToMax = Number(domainMax) - Number(tickValues[tickValues.length - 1]);
        if (diffToMax < diffBetweenTicks / 2) {
          tickValues.pop();
        }
      }

      return tickValues.concat(axis.domain());
    };

    const drawContainer = d3.select(csvLineChartContainer.current);
    // SVG width / height.
    const width = svgWidth - margin.current.left - margin.current.right;
    const height = svgHeight - margin.current.top - margin.current.bottom;

    // X Brush height and margins.
    const height2 = 50;
    const margin2 = { top: height + height2 + 30, right: margin.current.right, bottom: 30, left: margin.current.left };

    const svg = drawContainer
      .append("svg")
      .attr("width", svgWidth)
      .attr("height", svgHeight);

    const linesAndAxisContainer = svg
      .append("g")
      .attr("transform", `translate(${margin.current.left}, ${margin.current.top})`);

    if (contents) {
      const csvArray = cloneDeep(CSVToArray(contents));
      const indexOfTimestamp = csvArray[0].map((v) => v.toLowerCase()).findIndex((v) => timestampColumnNames.includes(v));

      if (indexOfTimestamp !== -1) {
        const headers = csvArray.shift();
        const restData = csvArray;
        const rows = {};

        headers.forEach((header, columnIndex) => {
          if (columnIndex === indexOfTimestamp) {
            return;
          }
          if (!rows[header]) {
            rows[header] = [];
          }
          for (let i = 0; i < restData.length; i++) {
            const cV = parseFloat(restData[i][columnIndex]);
            if (isValuePlottable(cV)) {
              rows[header].push({ x: handleTimestampToDate(restData[i][indexOfTimestamp]), y: cV });
            }
          }
        });

        const xValues = restData.map((v) => handleTimestampToDate(v[indexOfTimestamp]));
        const yValues = Object.values(rows).reduce((pV, cV) => [...pV, ...cV.map(({y}) => y)], [])
        const xDomain = d3.extent(xValues, d => d);
        const yDomain = d3.extent(d3.extent(yValues, d => d));
        const colorLegends = [];

        const x = d3.scaleTime()
          .domain(xDomain)
          .range([0, width]);

        const x2 = d3.scaleTime()
          .domain(xDomain)
          .range([0, width]);

        const y = d3.scaleLinear()
          .domain(yDomain)
          .range([height, 0]);

        const y2 = d3.scaleLinear()
          .domain(yDomain)
          .range([height2, 0]);

        const line = d3.line()
          .x((d) => x(d.x))
          .y((d) => y(d.y))
          .defined((d) => d.y !== null);

        const line2 = d3.line()
          .x((d) => x2(d.x))
          .y((d) => y2(d.y))
          .defined((d) => d.y !== null);

        svg.append("defs").append("clipPath")
          .attr("id", "clip")
          .append("rect")
            .attr("width", width)
            .attr("height", height);

        const xBrushChart = svg.append("g")
          .attr("class", "xBrushChart")
          .attr("transform", `translate(${margin2.left}, ${margin2.top})`);

        const xCrossHair = linesAndAxisContainer.append("g")
          .attr("class", "crosshair")
          .append("line")
            .attr("y1", 0)
            .attr("y2", height)
            .attr("x1", 0)
            .attr("x2", 0)
            .attr("stroke", "rgba(0,0,0,0.4)")
            .attr("stroke-width", 1)
            .attr("stroke-dasharray", 4);

        Object.entries(rows).forEach(([header, values]) => {
          if (values.length !== 0) {
            const { backgroundColor } = toMaterialStyle(header, 900);
            colorLegends.push({ backgroundColor, header });

            linesAndAxisContainer.append("path")
              .data([values])
              .attr("class", "csv-line")
              .attr("stroke", backgroundColor)
              .attr("d", line);

            xBrushChart.append("path")
              .data([values])
              .attr("class", "csv-line")
              .attr("stroke", backgroundColor)
              .attr("d", line2);
          }
        });

        // Set the colors for the legend.
        setLegend(colorLegends);

        // Add the X Axis
        const xFormat = (d) => `${d.toLocaleDateString()}`
        const xAxis = d3
          .axisBottom(x)
          .tickValues(getAxisTickValues(x))
          .tickFormat(xFormat);

        linesAndAxisContainer.append("g")
          .attr("transform", `translate(0, ${height})`)
          .call(xAxis)
          .attr("class", "axis axis--x")
          .selectAll("text")
            .attr("class", "mdc-typography--body2");

        // Add the Y Axis
        const yFormat = d3.format("~s");
        const yAxis = d3
          .axisLeft(y)
          .tickFormat(yFormat);

        linesAndAxisContainer.append("g")
          .call(yAxis)
          .attr("class", "axis axis--y")
          .selectAll("text")
            .attr("class", "mdc-typography--body2");

        // Add the brush X Axis.
        const xAxis2 = d3.axisBottom(x2);
        xBrushChart.append("g")
          .attr("class", "axis axis-brush--x")
          .attr("transform", `translate(0, ${height2})`)
          .call(xAxis2)
          .selectAll("text")
            .attr("class", "mdc-typography--body2");

        const handleMouseMove = () => {
          const nearestPoints = (xPosition, data, xScale) => {
            if (!Object.entries(data).length)
              return [];

            return Object.entries(data)
              .map(([title, points]) => {
                const xNeedle = xScale.invert(xPosition);
                const rightPoints = points.slice();
                const matchIndex = rightWeightedBinarySearch(+xNeedle, rightPoints, (points, index) => +points[index].x);

                return matchIndex === -1 ? null : ({ title, pointValue: points[matchIndex] });
              })
              .reduce((pV, cV) => ({ ...pV, [cV.title]: cV.pointValue }), {});
          };

          const [xPosition] = d3.mouse(d3.select(".zoom").node());

          const nextPointIndices = nearestPoints(xPosition, rows, x);
          setHoveredPoints(nextPointIndices);

          xCrossHair
            .attr("x1", xPosition)
            .attr("x2", xPosition);
        };

        const brushed = () => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom

          const s = d3.event.selection || x2.range();
          x.domain(s.map(x2.invert, x2));

          xAxis.tickValues(getAxisTickValues(x));

          linesAndAxisContainer.selectAll(".csv-line").attr("d", line);
          linesAndAxisContainer.select(".axis--x")
            .call(xAxis)
            .selectAll("text")
            .attr("class", "mdc-typography--body2");

          svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
            .scale(width / (s[1] - s[0]))
            .translate(-s[0], 0));
        }

        const zoomed = () => {
          if (d3.event.sourceEvent && d3.event.sourceEvent.type === "brush") return; // ignore zoom-by-brush

          const t = d3.event.transform;
          x.domain(t.rescaleX(x2).domain());

          xAxis.tickValues(getAxisTickValues(x));

          linesAndAxisContainer.selectAll(".csv-line").attr("d", line);
          linesAndAxisContainer.select(".axis--x")
            .call(xAxis)
            .selectAll("text")
            .attr("class", "mdc-typography--body2");

          xBrushChart.select(".brush")
            .call(brush.move, x.range().map(t.invertX, t));
        }

        const brush = d3.brushX()
          .extent([[0, 0], [width, height2]])
          .on("brush end", brushed);

        const zoom = d3.zoom()
          .scaleExtent([1, Infinity])
          .translateExtent([[0, 0], [width, height]])
          .extent([[0, 0], [width, height]])
          .on("zoom", zoomed);

        xBrushChart.append("g")
          .attr("class", "brush")
          .call(brush)
          .call(brush.move, x.range());

        svg.append("rect")
          .attr("class", "zoom")
          .attr("width", width)
          .attr("height", height)
          .attr("transform", `translate(${margin.current.left}, ${margin.current.top})`)
          .call(zoom);

        d3.select(".zoom")
          .on("mousemove", handleMouseMove);
      }
    }

    return () => {
      linesAndAxisContainer.selectAll("g").remove();
      svg.remove();
    };
  }, [contents, svgWidth]);

  const formatValue = (x) => x.toLocaleString();

  if (!path) {
    return null;
  }

  return <>
    <div className="file-browser-container">
      <div className="file-browser-toolbar">
        <div className="file-browser-breadcrumbs">
          <Typography>{basename(path)}</Typography>
        </div>
      </div>
    </div>
    <div className="csv-linechart" ref={csvLineChartContainer} />
    <div className="scv-linechart-color-legend" style={{ margin: `0 ${margin.current.right}px 8px ${margin.current.left}px` }}>
      {legend.map(({ backgroundColor, header }) => <div key={header} className="csv-legend-item">
        <div className="csv-legend-item-color" style={{ backgroundColor }}></div>
        <Typography use="body2" style={{ verticalAlign: "top" }}>{`${header}${hoveredPoints[header] ? `: ${formatValue(hoveredPoints[header].y)}` : ''}`}</Typography>
      </div>)}
    </div>
  </>;
};

const mapStateToProps = (state, ownProps) => {
  const { path } = ownProps;
  const info = state.content[path];
  if (info) {
    const ret = {
      ...info,
      ...ownProps
    }
    return ret;
  }

  return {
    ...stateUnknown()
  }
}

const mapDispatchToProps = { loadFileContent };

export default connect(mapStateToProps, mapDispatchToProps)(GraphContent);
