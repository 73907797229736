import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { DataTable, DataTableContent, DataTableHead, DataTableRow, DataTableHeadCell, DataTableCell, DataTableBody } from '@rmwc/data-table';
import { Checkbox } from '@rmwc/checkbox';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import { IconButton } from '@rmwc/icon-button';
import { Button } from '@rmwc/button';
import { LinearProgress } from '@rmwc/linear-progress';
import { queue } from '../common/dialog-queue';

import { extract } from '../../store/meta';
import { dirname as getDirname, getProjectRelativePath, basename } from '../../common/utils';
import { uploadFiles, deleteProjectPath, changeFilesContentType } from '../../store/project-actions';
import { getErrorMessage } from '../../common/errors';
import EditTextDialog from 'components/common/EditTextDialog';
import { CircularProgress } from '@rmwc/circular-progress';

import * as moment from 'moment';

import './files.scss';
import { getFriendlyFileSize, joinPath } from '../../common/utils';
import { getFilesInFolder } from '../Project/projects-utils';

import LinkWithToken from '../common/LinkWithToken';

import * as path from 'path'
import UploadBuildDialog from './UploadBuildDialog';

const HEADERS = [
  {
    title: '',
    name: 'selected',
    props: {
      hasFormControl: true
    }
  },
  {
    title: 'Name',
    name: 'basename',
    props: {
      alignStart: true
    }
  }, {
    title: 'Size',
    name: 'size',
    props: {
      isNumeric: true
    }
  },
  {
    title: 'Type',
    name: 'contentType'
  }, {
    title: 'Last modified',
    name: 'updatedAt'
  }]

const FileBrowser = ({ files, uploads, projectId, basedir, selectedFile, mState, uploadFiles, deleteProjectPath, changeFilesContentType }) => {
  const baseLink = `/${projectId}/browse/`;
  const baseViewLink = `/${projectId}/`;
  const editable = basedir.startsWith('/ws/');
  const [selectedFiles, setSelectedFiles] = useState({});
  const [fileContentTypeToEdit, setFileContentTypeToEdit] = useState();
  const [openUploadBuild, setOpenUploadBuild] = useState(false);
  const inputFileUpload = useRef(null);
  const history = useHistory();

  useEffect(() => {
    setSelectedFiles({});
  }, [basedir]);


  const selectedCount = Object.keys(selectedFiles).length;
  const toggleSelected = (f, e) => {
    e.preventDefault();
    const checked = !!e.currentTarget.checked;
    if (checked !== selectedFiles[f.path]) {
      const newSelection = { ...selectedFiles };
      if (checked) {
        newSelection[f.path] = checked;
      } else {
        delete newSelection[f.path];
      }
      setSelectedFiles(newSelection);
    }
  }

  const deleteSelectedFiles = () => {
    const paths = Object.keys(selectedFiles).filter((key) => selectedFiles[key]);
    const names = paths.map(key => basename(key)).join(', ');
    queue.confirm({
      title: 'Delete files',
      body: `Are you sure you want to delete ${names}. This action cannot be undone.`
    }).then(response => {
      if (response === true) {
        setSelectedFiles({});
        paths.map((f) => deleteProjectPath(projectId, f));
      }
    });
  }

  const onFileUploadChange = async (e) => {
    const fileList = inputFileUpload.current.files;
    if (fileList.length > 0) {
      function getFileContent(file) {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.onloadend = (e) => {
            resolve(reader.result)
          }
          reader.onerror = () => {
            // ignore error
            resolve(null)
          }
          reader.readAsText(file)
        })
      }

      for (const file of fileList) {
        const ext = path.extname(file.name)
        if (ext === '.json' || ext === '.yaml') {
          file.fileContent = await getFileContent(file)
        }
      }
      uploadFiles(projectId, basedir, fileList);
    }
  }

  const createNewFolder = () => {
    queue.prompt({
      title: 'Add folder',
      body: 'Folder name',
      acceptLabel: 'Add folder',
      cancelLabel: 'Cancel',
    }).then((response) => {
      if (response) {
        history.push(joinPath(baseLink, basedir, response, '/'));
      }
    })
  }

  const handleClick = (file) => {
    setFileContentTypeToEdit(file)
  }

  const paths = basedir.split('/');
  const pathLinks = paths.slice(1).map((p, index) => {
    let link = joinPath(baseLink, ...paths.slice(0, index + 1), '/');
    return {
      name: index === 0 ? projectId : paths[index],
      link: link
    }
  });

  const fileList = useMemo(() => getFilesInFolder(projectId, basedir, files), [projectId, basedir, files]);
  const getValue = (file, column) => {
    switch (column.name) {
      case 'basename':
        return <LinkWithToken to={file.link} replace={file.contentType !== 'Folder'}>{file.name} </LinkWithToken>;
      case 'size':
        return getFriendlyFileSize(file.size);
      case 'selected':
        return <Checkbox data-id={`checkbox-${file.name}`} checked={!!selectedFiles[file.path]} onChange={(e) => toggleSelected(file, e)} />
      case 'updatedAt':
        return file.updatedAt ? moment(file.updatedAt).format('LL') : null;
      case 'contentType':
        return <div>{file.basename && <IconButton onClick={() => handleClick(file)} icon='edit' />}<Typography use='text' style={{ position: 'absolute', top: '50%', transform: 'translate(0%, -50%)' }}>{file.contentType}</Typography></div>
      default:
        return file[column.name];
    }
  }
  const getUploadValue = (upload, column) => {
    switch (column.name) {
      case 'selected':
        if (upload.error) {
          return <IconButton icon='error' />
        } else {
          return <IconButton icon='file_upload' />
        }
      case 'basename':
        const name = basename(upload.path);
        if (upload.error) {
          return <><span>{name}</span> <br /> <span>{getErrorMessage(upload.error)} </span> </>
        }
        return name;
      case 'size':
        return <LinearProgress progress={upload.progress} style={{ 'textAlign': 'left' }} />;
      default:
        return null;
    }
  }

  const handleContentTypeChange = async (changedContentType) => {
    fileContentTypeToEdit.contentType = changedContentType
    await changeFilesContentType(projectId, fileContentTypeToEdit, changedContentType)
  }

  const buildUploading = Boolean(uploads.find(it => it.isBuild))
  const totalUploadProgress = uploads.length > 0 && uploads.reduce((prev, curr) => { prev.progress = prev.progress + curr.progress; return prev }).progress / uploads.length

  return <div className='file-browser-container'>
    <div className='file-browser-toolbar'>
      <div className='file-browser-breadcrumbs'>
        {pathLinks.map((p, index) =>
          <React.Fragment key={`link-${p.link}`}>
            {index > 0 && <Icon icon='keyboard_arrow_right' />}
            <LinkWithToken to={p.link}> {p.name} </LinkWithToken>
          </React.Fragment>)
        }
      </div>
      <div className='action-toolbar'>
        <input
          ref={inputFileUpload}
          style={{ display: 'none' }}
          className='file-upload'
          id="file-upload-input"
          multiple
          type="file"
          disabled={!editable}
          onChange={onFileUploadChange}
        />
        <div style={{ marginRight: '1rem' }}>
          <Button data-id='upload-build-button' disabled={buildUploading} icon={buildUploading ? (<CircularProgress />) : 'file_upload'} raised onClick={() => setOpenUploadBuild(true)}>Upload build</Button>
          {buildUploading && <LinearProgress data-id='build-upload-progress' progress={totalUploadProgress} />}
        </div>
        <label htmlFor='file-upload-input'>
          {editable && <Button icon='file_upload' raised tag='div'>Upload file</Button>}
          {!editable && <Button disabled icon='file_upload' raised>Upload file</Button>}
        </label>
        <IconButton disabled={!editable} icon='create_new_folder' onClick={createNewFolder} />
      </div>
    </div>
    {selectedCount > 0 &&
      <div className='file-browser-selection'>
        <IconButton icon='close' onClick={() => setSelectedFiles({})} />
        <span> {selectedCount} selected</span>
        <span>|</span>
        <Button data-id='delete-selected-file' outlined theme='onPrimary' onClick={deleteSelectedFiles}>Delete</Button>
      </div>}
    <div className='file-browser-file-list'>
      <DataTable stickyRows={1} className={selectedFile && 'extended'}>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              {HEADERS.map((h) => <DataTableHeadCell key={h.name} className={`cell-${h.name}`} {...h.props}>{h.title}</DataTableHeadCell>)}
            </DataTableRow>
          </DataTableHead>

          <DataTableBody>
            {uploads.filter(it => !it.isBuild).map((up) =>
              <DataTableRow key={up.path + up.projectId}>
                {HEADERS.map((h) =>
                  <DataTableCell {...h.props} className={`cell-${h.name}`} key={`${up.path + up.projectId}-${h.name}`}>
                    {getUploadValue(up, h)}
                  </DataTableCell>
                )}
              </DataTableRow>
            )}

            {fileList.map((file) =>
              <DataTableRow key={file.path}>
                {HEADERS.map((h) =>
                  <DataTableCell {...h.props} className={`cell-${h.name}`} key={`${file.path}-${h.name}`}>
                    {getValue(file, h)}
                  </DataTableCell>
                )}

              </DataTableRow>)
            }
          </DataTableBody>
        </DataTableContent>
      </DataTable>
      {selectedFile && <div className='file-info-container'>
        <div className='file-info-container-header'>
          <Typography use='headline5'>{selectedFile.name}</Typography>
          <IconButton icon='close' tag={LinkWithToken} replace to={joinPath(baseLink, basedir)} />
        </div>
        <dl>
          <dt>Name</dt>
          <dd>{selectedFile.name}</dd>

          <dt>Link</dt>
          <dd><LinkWithToken to={joinPath(baseViewLink, getProjectRelativePath(selectedFile.path))}>{joinPath(baseViewLink, getProjectRelativePath(selectedFile.path))} </LinkWithToken></dd>

          <dt>Size</dt>
          <dd>{getFriendlyFileSize(selectedFile.size)}</dd>

          <dt>Type</dt>
          <dd>{selectedFile.contentType}</dd>

          <dt>Created</dt>
          <dd>{moment(selectedFile.createdAt).format('LLL')}</dd>

          <dt>Updated</dt>
          <dd>{moment(selectedFile.updatedAt).format('LLL')}</dd>

          {Object.keys(selectedFile)
            .filter(key => key.startsWith('inuit-'))
            .map((key) => <React.Fragment key={key}>
              <dt>{key}</dt>
              <dd>{selectedFile[key]}</dd>
            </React.Fragment>)
          }
        </dl>
      </div>}
    </div>
    {<UploadBuildDialog
      isOpen={openUploadBuild}
      onClose={() => setOpenUploadBuild(false)}
      projectId={projectId}
    />}
    {fileContentTypeToEdit && <EditTextDialog
      title='Change Content Type'
      label='Content Type'
      isOpen={fileContentTypeToEdit}
      onDialogValueChanged={(contentType) => { handleContentTypeChange(contentType) }}
      defaultDialogValue={fileContentTypeToEdit.contentType}
      onClose={() => setFileContentTypeToEdit(null)} />}
  </div>
}

const mapStateToProps = (state, ownProps) => {
  const { projectId, base } = ownProps;
  let selectedFileName;
  if (!base.endsWith('/') && base) {
    selectedFileName = `projects/${projectId}/files/${base}`;
  }
  let basedir = joinPath('/', getDirname(base), '/');
  const [files, mState] = extract(state.files, projectId, 'files');

  const selectedFile = selectedFileName && files && files.find(f => f.path === selectedFileName);

  const uploads = state.uploads.filter((up) => up.projectId === projectId);

  return { files: files || [], mState, basedir, selectedFile, uploads };
}

export default connect(mapStateToProps, { uploadFiles, deleteProjectPath, changeFilesContentType })(FileBrowser);