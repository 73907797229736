import React from 'react';
import { connect } from 'react-redux';

import { Dialog, DialogTitle, DialogContent, DialogButton, DialogActions} from '@rmwc/dialog';

import {Button} from '@rmwc/button';

import { TextField } from '@rmwc/textfield';

import './AddMemberDialog.scss';
import {showNotification} from '../../store/ui-actions';
import { useState } from 'react';

import {Typography} from '@rmwc/typography';

import {getProjectDetails, addMemberToProject} from '../../store/project-actions';
import {copySearchParams} from '../../common/utils'

const projectsAPI = require('../../api/projects-api');

const AddMemberDialog = ({ showNotification, projectId, shareToken, getProjectDetails, addMemberToProject, continueUrl, isOpen, onClose } ) => {
    const [email, setEmail] = useState('');
    const [copyToClipboard, setCopyToClipboard] = useState(false);
    const link = shareToken && copySearchParams(continueUrl, `shared=${shareToken.token}`);
    const onKeyPress = (ev) => {
      if (ev.key === 'Enter') {
        addMember()
        onClose()
      }
    }

    const addMember = () => {
      showNotification( { message: `Sending invite to ${email}`});
      addMemberToProject(projectId, continueUrl, email)
        .then(() => {
          showNotification( { message: `Invite sent to ${email}`});
        }, () => {
          showNotification( { message: `Failed to send invite to ${email}`});
        });
    }

    const onDialogClose = (e) => {
      if (e.detail.action === 'sendInvite') {
        addMember()
      }
      onClose(e);
    }

    const onCreateShareToken = (e) => {
      setCopyToClipboard(true)
      projectsAPI.createShareToken(projectId)
        .then(() => showNotification( {message: 'Sharing with link enabled'}))
        .then(() => getProjectDetails(projectId, true))
        .catch(() => showNotification( {message: 'Could not enable link sharing'}))
    }

    const onRemoveShareToken = (e) => {
      projectsAPI.deleteShareToken(projectId, shareToken.token)
        .then(() => showNotification( {message: 'Link sharing disabled'}))
        .then(() => getProjectDetails(projectId, true))
      .catch(() => showNotification( {message: 'Could not disable link sharing'}))
    }

    const copyLink = () => {
    navigator.clipboard.writeText(link)
        .then(() => showNotification({ message: 'Link copied to clipboard'}));
    }

    if (copyToClipboard && shareToken != null) {
      setCopyToClipboard(false)
      copyLink()
    }

    return (
      <Dialog open={isOpen} onClose={onDialogClose}>

        <DialogTitle>Share with others
          <div className='dialog-right-top-header'>
            <Button data-id='get-sharable-link-button' label='Get sharable link' trailingIcon='link' dense onClick={onCreateShareToken}/>
          </div>
        </DialogTitle>

        <DialogContent className='add-member-dialog-content' >
          {shareToken && <div>
            Anyone with the link can access (no login required)<br/>
            <div className='link-sharing-options'>
              <Typography data-id='share-dialog-link' className='link-sharing-options-link' use='caption'>{link}</Typography>
              <Button className='link-sharing-options-button' icon='content_copy' label='Copy' onClick={copyLink}/>
              <Button className='link-sharing-options-button' icon='delete' label='Remove' onClick={onRemoveShareToken}/>
            </div>
          </div>}
          <div className='add-member-people-section'>
            <Typography use='subtitle'>People</Typography>
            <TextField
                label='Email' className='add-member-dialog-email-field'
                helpText='Enter email address'
                type='email'
                value={email}
                onKeyPress={onKeyPress}
                onChange={(e) => setEmail(e.currentTarget.value)} />
          </div>
        </DialogContent>

        <DialogActions>
          <DialogButton action='close'>Close</DialogButton>
        < DialogButton action='sendInvite' >Send Invite</DialogButton>
        </DialogActions>

      </Dialog>
    );
  }



const mapDispatchToProps = {showNotification, getProjectDetails, addMemberToProject}


export default connect(null, mapDispatchToProps)(AddMemberDialog);
